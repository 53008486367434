import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Box, Radio, Tooltip, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trashIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./restoreDialog.scss";
import api from "../../apiInterceptor";
import DeleteDialog from "modals/deleteDialog/deleteDialog";
import { showSuccessAlert, removeHighightedNode } from "utils/utils";
const fetchbkpinfoAPITestData = require("../../assets/apiTestData/fetchbkpinfo-test-data.json");
const restorebkpAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");
const delbkpAPITestData = require("../../assets/apiTestData/delbkp-test-data.json");

const RestoreDialog = ({ restoreUI }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [restoredVal, setRestoredVal] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [backupsInfo, setBackupsInfo] = useState(null);
  const [backups, setBackups] = useState([]);
  const [deletableBackup, setDeletableBackup] = useState(null);
  const showRestoreDialog = useSelector((state) => state.showRestoreDialog);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);

  const dispatch = useDispatch();
  const {
    updateShowRestoreDialog,
    updateIsLoading,
    updateAlertMessage,
    updateDeleteDialogStatus,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };
  useEffect(() => {
    getAllBackups();
  }, []);

  useEffect(() => {
    if (restoredVal) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [restoredVal]);

  const getAllBackups = async () => {
    const apiUrl = BASE_API_URL + "fetchbkpinfo";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedProjFg,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchbkpinfoAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setBackupsInfo(result);
        setBackups(Object.keys(result));
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleClose = () => {
    updateShowRestoreDialog(false);
  };

  const restoreBackup = async () => {
    const apiUrl = BASE_API_URL + "restorebkp";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedProjFg,
      backupId: restoredVal,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: restorebkpAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const importedPipeline = response.data.data.posts[0];
        handleClose();
        restoreUI(importedPipeline, true);
        removeHighightedNode();
        const msg = "Backup restored successfully!";
        showSuccessAlert(msg, updateSuccessAlertMessage);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleChange = (backupName) => {
    setRestoredVal(backupName);
  };

  const showDeleteConfirmationDialog = (backupName) => {
    updateDeleteDialogStatus(true);
    setDeletableBackup(backupName);
  };

  const deleteBackup = async () => {
    const apiUrl = BASE_API_URL + "delbkp";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedProjFg,
      backupId: deletableBackup,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: delbkpAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setBackupsInfo(result);
        setBackups(Object.keys(result));
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={showRestoreDialog}
        aria-labelledby="Restore"
        className="restore-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Restore"}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack className="table">
            <Stack className="table-header" direction="row">
              <Box className="header-item" sx={{ width: "5%" }}></Box>
              <Box className="header-item" sx={{ width: "25%" }}>
                Name
              </Box>
              <Box className="header-item" sx={{ width: "25%" }}>
                Description
              </Box>
              <Box className="header-item" sx={{ width: "25%" }}>
                Created On
              </Box>
              <Box className="header-item" sx={{ width: "20%" }}>
                Action
              </Box>
            </Stack>
            {backups.length > 0 ? (
              backups.map((backupName, index) => (
                <Stack key={index} className="table-row " direction="row">
                  <Box className="row-item" sx={{ width: "5%" }}>
                    <Radio
                      checked={restoredVal === backupName}
                      onChange={() => handleChange(backupName)}
                      value="a"
                      className="radio-button"
                      name="radio-buttons"
                      style={{ color: "#69C2D2" }}
                      slotProps={{ input: { "aria-label": "A" } }}
                    />
                  </Box>
                  <Box className="row-item" sx={{ width: "25%" }}>
                    <Tooltip title={backupName} placement="top">
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {backupName}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box className="row-item" sx={{ width: "25%" }}>
                    <Tooltip
                      title={backupsInfo[backupName].descr}
                      placement="top"
                    >
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {backupsInfo[backupName].descr}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box className="row-item" sx={{ width: "25%" }}>
                    <Tooltip
                      title={backupsInfo[backupName].datetime.split(".")[0]}
                      placement="top"
                    >
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {backupsInfo[backupName].datetime.split(".")[0]}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box
                    className="row-item"
                    textAlign="center"
                    sx={{ width: "20%" }}
                  >
                    <Tooltip title="Delete" placement="top">
                      <IconButton
                        aria-label="delete"
                        className="delete-icon"
                        onClick={() => showDeleteConfirmationDialog(backupName)}
                      >
                        <TrashIcon></TrashIcon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              ))
            ) : (
              <Box className="empty-text">Information Not available</Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={restoreBackup}
            disabled={isSaveDisabled}
            autoFocus
          >
            Restore
          </Button>
        </DialogActions>
      </Dialog>
      {isDeleteDialogOpen && (
        <DeleteDialog
          deletableItem={selectedProjectKey}
          deleteHandler={deleteBackup}
          deletionType={"backup"}
        />
      )}
    </div>
  );
};

export default RestoreDialog;
