import { React, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../modals/deleteDialog/deleteDialog";
import SummaryDialog from "../../modals/summaryDialog/summaryDialog";
import "./toolbar.scss";
import api from "../../apiInterceptor";
import ErrorDialog from "modals/errorDialog/errorDialog";
import { isEmpty } from "lodash";
import PartialExecutionDialog from "modals/partialExecutionDialog/partialExecutionDialog";
import CalculateStatsDialog from "modals/calculateStatsDialog/calculateStatsDialog";
import InfoDialog from "modals/showinfoDialog/showinfoDialog";
const dwnldDataAPITestData = require("../../assets/apiTestData/dwnld-data-test-data.json");
const getInfoResponse = require("../../assets/apiTestData/getinfo.json");
const singleNodeProgressStatsAPITestData = require("../../assets/apiTestData/single-node-progress-stats-test-data.json");

const Toolbar = ({
  toolbarPosition,
  currentNode,
  onNodesDelete,
  addDuplicatedNode,
  runConfiguration,
  getConnectedDatasourceId,
  collapseStages,
  nodes,
  edges,
  updateNodes,
  updateEdges,
  nodesBeforeCollapse,
  setNodesBeforeCollapse,
  flowInfo,
}) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const EDA_API_URL = localStorage.getItem("EDA_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let ENABLE_PARTIAL_EXECUTION = localStorage.getItem(
    "ENABLE_PARTIAL_EXECUTION"
  );
  let NODE_LEVEL_COLLAPSE_ENABLED = localStorage.getItem(
    "NODE_LEVEL_COLLAPSE_ENABLED"
  );
  const EDA_REDIRECT_URL = localStorage.getItem("EDA_REDIRECT_URL");
  let USER_MANAGEMENT_URL = localStorage.getItem("USER_MANAGEMENT_URL");

  ENABLE_PARTIAL_EXECUTION =
    ENABLE_PARTIAL_EXECUTION === "true" || ENABLE_PARTIAL_EXECUTION === true
      ? true
      : false;
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  NODE_LEVEL_COLLAPSE_ENABLED =
    NODE_LEVEL_COLLAPSE_ENABLED === "true" ||
    NODE_LEVEL_COLLAPSE_ENABLED === true
      ? true
      : false;
  const navigate = useNavigate();
  const [currentDs, setCurrentDs] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);

  const [infoMessage, setInfoMessage] = useState("");
  const [statsStatus, setStatsStatus] = useState("InProgress");
  const [statsErrorMessage, setStatsErrorMessage] = useState("");
  const isCompletedNode =
    currentNode?.className?.includes("completed") ?? false;
  const isErrorNode = currentNode?.className?.includes("error") ?? false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const isErrorDialogOpen = useSelector((state) => state.isErrorDialogOpen);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const currentProjectStatus = useSelector(
    (state) => state.currentProjectStatus
  );
  const isCalculateStatsDialogOpen = useSelector(
    (state) => state.isCalculateStatsDialogOpen
  );
  const modellingNodesData = useSelector((state) => state.modellingNodesData);
  const showPartialExecutionDialog = useSelector(
    (state) => state.showPartialExecutionDialog
  );
  const isInfoDialogOpen = useSelector((state) => state.isInfoDialogOpen);
  const isPolling = useSelector((state) => state.isPolling);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const validationErrorNodes = useSelector(
    (state) => state.validationErrorNodes
  );
  const isSummaryDialogOpen = useSelector((state) => state.isSummaryDialogOpen);
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const collapsedDs = useSelector((state) => state.collapsedDs);
  const pipeline = useSelector((state) => state.pipeline);
  const globalCollapseEnabled = useSelector(
    (state) => state.globalCollapseEnabled
  );
  let progressStatsTimeoutId = null;

  const dispatch = useDispatch();
  const {
    updateDeleteDialogStatus,
    updateIsLoading,
    updateIsPlaygroundLoading,
    updateSummaryDialogStatus,
    updateErrorDialogStatus,
    updateIsOpenInfoDialog,
    updateAlertMessage,
    updateShowPartialExecutionDialog,
    updateCalculateStatsDialogStatus,
    updateCollapsedDs,
    updateGlobalCollapse,
    updatePipeline,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (
      !isErrorNode &&
      currentProjectStatus === "completed" &&
      !currentNode.data.isStage
    ) {
      getStatsStatus();
    }
    return () => {
      if (progressStatsTimeoutId) {
        clearTimeout(progressStatsTimeoutId);
      }
    };
  }, []);

  const handleInfo = () => {
    updateIsOpenInfoDialog(true);
    getInfo();
  };

  const getStatsStatus = async () => {
    try {
      const apiUrl = EDA_API_URL + "progress_stats";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      let ds = null;
      let stageId = null;
      if (!currentNode.data.isStage) {
        ds = currentNode.id;
      } else {
        ds = getConnectedDatasourceId(currentNode);
        stageId = currentNode.id;
      }
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        dSource: ds,
        stageId: stageId,
        stats_type: "overall",
        productType: "oda",
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: singleNodeProgressStatsAPITestData,
          status: 200,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.status === 200) {
        // response.data.status can be  Completed/Failed/InProgress/NotConfigured
        setStatsStatus(response.data.status);
        if (response.data.status === "Failed") {
          setStatsErrorMessage(response.data.message);
        } else if (response.data.status === "InProgress") {
          progressStatsTimeoutId = setTimeout(getStatsStatus, 60000);
        }
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const showDeleteConfirmationDialog = () => {
    updateDeleteDialogStatus(true);
  };

  const showSummary = () => {
    updateSummaryDialogStatus(true);
  };
  const showErrorDialog = () => {
    updateErrorDialogStatus(true);
  };

  const runTillNow = () => {
    if (ENABLE_PARTIAL_EXECUTION) {
      if (!currentNode.data.isStage) {
        setCurrentDs(currentNode.id);
      } else {
        const datasource = getConnectedDatasourceId(currentNode);
        setCurrentDs(datasource);
        setCurrentStage(currentNode.id);
      }
      updateShowPartialExecutionDialog(true);
    } else {
      if (!currentNode.data.isStage) {
        runConfiguration(currentNode.id);
      } else {
        const datasource = getConnectedDatasourceId(currentNode);
        runConfiguration(datasource, currentNode.id);
      }
    }
  };

  const getFileName = () => {
    if (currentNode.data.isStage) {
      return nodeConfigurations[currentNode.id].name
        ? nodeConfigurations[currentNode.id].name
        : currentNode.data.label;
    } else {
      return nodeConfigurations[currentNode.id].fileName
        ? nodeConfigurations[currentNode.id].fileName
        : currentNode.name === "filter" &&
          nodeConfigurations[currentNode.id].filterCfg
        ? nodeConfigurations[currentNode.id].filterCfg[0].dataCfg.cfg.name
        : nodeConfigurations[currentNode.id].name;
    }
  };

  const getDownloadData = async (dataType, partNo = null) => {
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const apiUrl = BASE_API_URL + "dwnld-data";
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedFeatureGroup,
      docType: "data",
      dSource: null,
      stageId: null,
      dataType: dataType,
      nodeType: currentNode.data.nodeKind,
      timestamp: new Date().getTime(),
    };
    if (currentNode.data.isStage) {
      const dataSource = getConnectedDatasourceId(currentNode);
      payload["dSource"] = dataSource;
      payload["stageId"] = currentNode.id;
    } else {
      payload["dSource"] = currentNode.id;
    }
    const downloadUrl =
      apiUrl +
      `?projectKey=${selectedProjectKey}&projVersion=${selectedProjVersion}&projFg=${selectedFeatureGroup}&docType=data&dSource=${payload.dSource}&stageId=${payload.stageId}&dataType=${payload.dataType}&nodeType=${payload.nodeType}&timestamp=${payload.timestamp}`;
    if (partNo !== null) {
      payload["partNumber"] = partNo;
    }
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = dwnldDataAPITestData;
      } else {
        response = await api.get(apiUrl, {
          headers: headers,
          withCredentials: true,
          params: payload,
          responseType: "json",
        });
      }
      if (response.status === 200) {
        return { response: response, downloadUrl: downloadUrl, partNo: partNo };
      } else if (response.status === 404) {
        if (response.reason) {
          updateAlertMessage(response.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        return null;
      }
    } catch (error) {
      updateIsLoading(false);
      updateIsPlaygroundLoading(false);
      console.error("Error downloading the file:", error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleDownload = async (dataType = "full") => {
    try {
      if (isSummaryDialogOpen) {
        updateIsLoading(true);
      } else {
        updateIsPlaygroundLoading(true);
      }
      const downloadResponse = await getDownloadData(dataType);
      const response = downloadResponse.response;
      const downloadUrl = downloadResponse.downloadUrl;
      const partNo = downloadResponse.partNo;
      if (isSummaryDialogOpen) {
        updateIsLoading(false);
      } else {
        updateIsPlaygroundLoading(false);
      }
      if (response && response.data) {
        let fileType = "csv";
        if (
          response.headers["content-type"].includes("zip") &&
          partNo === null
        ) {
          fileType = "zip";
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = getFileName();
        link.setAttribute("download", `${fileName}.${fileType}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleExplore = () => {
    if (
      ["transaction", "equifax", "pipeline"].includes(currentNode.data.nodeType)
    ) {
      handlePipelineNodeNavigation();
    } else {
      handleModellingNodesNavigation();
    }
  };

  const handlePipelineNodeNavigation = () => {
    const projectKey = nodeConfigurations[currentNode.id].sourceProjectKey;
    const projVersion = nodeConfigurations[currentNode.id].sourceProjVersion;
    const projFg = nodeConfigurations[currentNode.id].sourceProjFg;
    navigate(`/playground/${projectKey}/${projVersion}/${projFg}`);
  };

  const handleModellingNodesNavigation = () => {
    const fromModelScoreNode = currentNode.data.nodeType === "modelScoring";
    const projectDetails = modellingNodesData[currentNode.id];
    if (!projectDetails || isEmpty(projectDetails)) {
      return;
    }
    const { ituringProjKey, ituringProjVersion, ootDSource } =
      projectDetails.mlInfo;
    const { accessToken, ituringUrl } = projectDetails;
    if (!accessToken || !ituringUrl || !ituringProjKey || !ituringProjVersion) {
      return;
    }
    // redirects user to Ituring UI
    const redirectUrl = `${ituringUrl}/#/user-management?token=${accessToken}&ituringProjKey=${ituringProjKey}&ituringProjVersion=${ituringProjVersion}&ootDSource=${ootDSource}&fromODA=${true}&fromModelScoreNode=${fromModelScoreNode}&userManagementUrl=${USER_MANAGEMENT_URL}`;
    window.open(redirectUrl);
  };

  const handleStageCollapsing = () => {
    if (pipeline[currentNode.id].stageCount) {
      const clonedCollapsedDs = structuredClone(collapsedDs);
      let allNodes = structuredClone(nodes);
      let allEdges = structuredClone(edges);
      if (clonedCollapsedDs.length === 0 && nodesBeforeCollapse.length === 0) {
        setNodesBeforeCollapse(allNodes);
      }
      const updatedNodesAndEdges = collapseStages(
        currentNode,
        allNodes,
        allEdges
      );
      allNodes = updatedNodesAndEdges.newNodes;
      allEdges = updatedNodesAndEdges.newEdges;
      const index = clonedCollapsedDs.indexOf(currentNode.id);
      if (index > -1) {
        resetPositionOfCollapsedNode(allNodes, currentNode.id);
        clonedCollapsedDs.splice(index, 1);
      } else {
        clonedCollapsedDs.push(currentNode.id);
      }
      if (clonedCollapsedDs.length === 0) {
        resetPositionOfAllDatasourceNodes(allNodes);
        setNodesBeforeCollapse([]);
        updateGlobalCollapse(false);
      }
      updateCollapsedDs(clonedCollapsedDs);
      updateNodes(allNodes);
      updateEdges(allEdges);
      let configurations = structuredClone(pipeline);
      configurations.clientMetaData.flow.edges = allEdges;
      configurations.clientMetaData.flow.nodes = allNodes;
      updatePipeline(configurations);
    }
  };

  const resetPositionOfCollapsedNode = (allNodes, nodeID) => {
    const clonedNode = allNodes.find((nd) => nd.id === nodeID);
    const nodeInfo = nodesBeforeCollapse.find((nd) => nd.id === nodeID);
    if (nodeInfo && clonedNode) {
      clonedNode.position = nodeInfo.position;
      clonedNode.positionAbsolute = nodeInfo.positionAbsolute;
    }
  };

  const resetPositionOfAllDatasourceNodes = (allNodes) => {
    for (let node of allNodes) {
      if (!node.data.isStage) {
        const nodeInfo = nodesBeforeCollapse.find((nd) => nd.id === node.id);
        if (nodeInfo) {
          node.position = nodeInfo.position;
          node.positionAbsolute = nodeInfo.positionAbsolute;
        }
      }
    }
  };

  const getInfo = async () => {
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const apiUrl = BASE_API_URL + "fetchdocstr";
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedFeatureGroup,
      dSource: null,
      stageId: null,
    };
    if (currentNode.data.isStage) {
      const dataSource = getConnectedDatasourceId(currentNode);
      payload["dSource"] = dataSource;
      payload["stageId"] = currentNode.id;
    } else {
      payload["dSource"] = currentNode.id;
    }
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = getInfoResponse;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.status === 200) {
        const posts = response.data.data["posts"];
        let message = "";
        if (posts.length > 0 && typeof posts[0] == "string") {
          message = posts.join(" ");
        } else if (posts.length > 0 && typeof posts[0] == "object") {
          message = posts[0].toString();
        }
        setInfoMessage(message);
      } else if (response.status === 404) {
        if (response.reason) {
          updateAlertMessage(response.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        return null;
      }
    } catch (error) {
      updateIsLoading(false);
      updateIsPlaygroundLoading(false);
      console.error("Error in showing information:", error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleStats = () => {
    if (statsStatus === "NotConfigured") {
      updateCalculateStatsDialogStatus(true);
    } else if (statsStatus === "Completed") {
      let ds = null;
      let stageId = null;
      if (!currentNode.data.isStage) {
        ds = currentNode.id;
      } else {
        ds = getConnectedDatasourceId(currentNode);
        stageId = currentNode.id;
      }
      const baseUrl = EDA_REDIRECT_URL.endsWith("/")
        ? EDA_REDIRECT_URL
        : `${EDA_REDIRECT_URL}/`;
      const url = `${baseUrl}?productType=oda&projectKey=${selectedProjectKey}&projVersion=${selectedProjVersion}&projFg=${selectedFeatureGroup}&dSource=${ds}&stageId=${stageId}`;
      window.open(url, "_blank");
    }
  };

  return (
    <div
      className="toolbar"
      style={{
        position: "absolute",
        top: toolbarPosition?.y,
        left: toolbarPosition?.x,
      }}
    >
      {toolbarPosition && currentNode ? (
        <Stack className="button-bar" direction="row">
          {!globalCollapseEnabled && collapsedDs.length === 0 ? (
            <Tooltip title="Delete node" placement="top">
              <button
                className="btn delete-btn"
                disabled={isPolling || userAccessRestricted}
                onClick={showDeleteConfirmationDialog}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode &&
          !globalCollapseEnabled &&
          collapsedDs.length === 0 ? (
            <Tooltip title="Copy node" placement="top">
              <button
                className="btn duplicate-btn"
                disabled={isPolling || userAccessRestricted}
                onClick={() => addDuplicatedNode(currentNode)}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode &&
          currentNode.data.nodeType !== "modelDevelopment" &&
          currentNode.data.nodeType !== "modelScoring" &&
          (isCompletedNode || currentNode?.data?.nodeKind === "add") ? (
            <Tooltip title="Show sample" placement="top">
              <button
                className="btn summary-btn"
                onClick={() => showSummary(currentNode)}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode &&
          currentProjectStatus === "completed" &&
          !currentNode.data.isStage ? (
            <Tooltip
              title={
                statsStatus === "NotConfigured"
                  ? "Calculate stats"
                  : statsStatus === "Completed"
                  ? "View stats"
                  : statsStatus === "Failed"
                  ? statsErrorMessage
                  : "Stats calculation in progress"
              }
              placement="top"
            >
              <button
                className={`btn view-stats-btn ${
                  statsStatus === "InProgress" ? "in-progress" : ""
                }`}
                disabled={isPolling || userAccessRestricted}
                onClick={() => handleStats()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode ? (
            <Tooltip title="Run till here" placement="top">
              <button
                className="btn run-till-now-btn"
                disabled={isPolling || userAccessRestricted}
                onClick={() => runTillNow()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode && !currentNode.data.isStage ? (
            <Tooltip title="Download" placement="top">
              <button
                className="btn download-btn"
                disabled={isPolling || userAccessRestricted}
                onClick={() => handleDownload()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {validationErrorNodes.includes(currentNode.id) ? (
            <Tooltip title="Show error" placement="top">
              <button
                className="btn show-error-btn"
                disabled={isPolling}
                onClick={showErrorDialog}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode &&
          !(
            ["transaction", "equifax", "pipeline"].includes(
              currentNode.data.nodeType
            ) &&
            (globalCollapseEnabled || collapsedDs.length > 0)
          ) &&
          [
            "transaction",
            "equifax",
            "pipeline",
            "modelDevelopment",
            "modelScoring",
          ].includes(currentNode.data.nodeType) ? (
            <Tooltip title="Explore" placement="top">
              <button
                className="btn explore-btn"
                disabled={
                  isPolling ||
                  ((currentNode.data.nodeType === "modelDevelopment" ||
                    currentNode.data.nodeType === "modelScoring") &&
                    !isCompletedNode)
                }
                onClick={() => handleExplore()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {NODE_LEVEL_COLLAPSE_ENABLED &&
          !currentNode.data.isStage &&
          pipeline[currentNode.id]?.stageCount ? (
            <Tooltip
              title={
                collapsedDs.includes(currentNode.id)
                  ? "Uncollapse feature engineering nodes"
                  : "Collapse feature engineering nodes"
              }
              placement="top"
            >
              <button
                className="btn collapse-stages-btn"
                disabled={isPolling}
                onClick={() => handleStageCollapsing()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
          {!isErrorNode ? (
            <Tooltip title="Info" placement="top">
              <button
                variant="contained"
                className="btn show-info-btn"
                onClick={() => handleInfo()}
              ></button>
            </Tooltip>
          ) : (
            <></>
          )}
        </Stack>
      ) : (
        <div></div>
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          deletableItem={currentNode}
          deleteHandler={onNodesDelete}
          deletionType={"node"}
        />
      )}
      {isErrorDialogOpen && <ErrorDialog currentNode={currentNode} />}
      {isSummaryDialogOpen && (
        <SummaryDialog
          currentNode={currentNode}
          handleDownload={handleDownload}
          getConnectedDatasourceId={getConnectedDatasourceId}
          getFileName={getFileName}
          getDownloadData={getDownloadData}
        />
      )}
      {showPartialExecutionDialog && (
        <PartialExecutionDialog
          currentDs={currentDs}
          currentStage={currentStage}
          runConfiguration={runConfiguration}
        />
      )}
      {isInfoDialogOpen && <InfoDialog infoMessage={infoMessage} />}
      {isCalculateStatsDialogOpen && (
        <CalculateStatsDialog
          getConnectedDatasourceId={getConnectedDatasourceId}
          currentNode={currentNode}
          getStatsStatus={getStatsStatus}
        />
      )}
    </div>
  );
};

export default Toolbar;
