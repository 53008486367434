import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const NetworkBandwidthGraph = ({ graphHeight, data = [] }) => {
  console.log(data);

  const [sentData, setSentData] = useState([]);
  const [receivedData, setReceivedData] = useState([]);

  useEffect(() => {
    setSentData(data.map((item) => item.sent));
    setReceivedData(data.map((item) => item.received));
  }, [data]);

  const options = {
    chart: {
      id: "area-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((_, index) => index + 1),
      axisBorder: {
        color: "#757575",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 3,
      axisBorder: {
        show: true,
        color: "#757575",
      },
      axisTicks: {
        show: true,
        color: "#757575",
      },
      labels: {
        style: {
          colors: "#757575",
        },
        formatter: (value) => {
          return Math.round(value).toString();
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
      },
      marker: {
        show: true,
      },
      custom: function ({ series, dataPointIndex, w }) {
        const tooltipContent = w.config.series
          .map((s, seriesIndex) => {
            const seriesName = s.name;
            const value = series[seriesIndex][dataPointIndex];
            const markerColor = w.config.stroke.colors[seriesIndex];

            return `
              <div style="display: flex; align-items: center; margin-bottom: 5px;margin-top:5px">
                <span style="width: 10px; height: 10px; background-color: ${markerColor}; border-radius: 50%; margin-right: 5px;"></span>
                <div style="font-size:12px;">${seriesName}:</div> <strong style="font-size:12px">${value} MB</strong>
              </div>
            `;
          })
          .join("");

        return `
          <div style="padding: 3px; background-color: #333 .3; color: #fff; border-radius: 5px;">
            ${tooltipContent}
          </div>
        `;
      },
      fixed: {
        enabled: true,
        position: "bottomLeft",
        offsetX: -75,
        offsetY: -50,
      },
    },
    markers: {
      colors: ["#70cb05", "#69c2d2"],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      colors: ["#70cb05", "#69c2d2"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    stroke: {
      curve: "smooth",
      colors: ["#70cb05", "#69c2d2"],
      width: 2,
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    height: graphHeight,
  };

  const series = [
    {
      name: "Sent Data (MB)",
      data: sentData,
    },
    {
      name: "Received Data (MB)",
      data: receivedData,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={graphHeight}
    />
  );
};

export default NetworkBandwidthGraph;
