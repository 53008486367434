import { Box, Stack } from "@mui/material";
import CpuUsageGraph from "graphs/cpuUsageGraph/cpuUsageGraph";
import MemoryUsageGraph from "graphs/memoryUsageGraph/memoryUsageGraph";
import React, { useEffect, useRef } from "react";
import "./sidebarGraph.scss";
import api from "apiInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "state";
import ClusteredGraphs from "components/clusteredGraphs/clusteredGraphs";
const fetchresusageAPITestData = require("../../assets/apiTestData/fetchresusage-test-data.json");

const SidebarGraph = () => {
  const PROCESSOR_USAGE_POLL_TIME = localStorage.getItem(
    "PROCESSOR_USAGE_POLL_TIME"
  );
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const isPolling = useSelector((state) => state.isPolling);
  const cpuUsageInfo = useSelector((state) => state.cpuUsageInfo);
  const memoryUsageInfo = useSelector((state) => state.memoryUsageInfo);
  const clusterResourceData = useSelector((state) => state.clusterResourceData);
  const cpuUsageArr = useRef([]);
  const memoryUsageArr = useRef([]);
  const clusterResourceDataRef = useRef([]);
  const pollInterval = useRef([]);
  const dispatch = useDispatch();
  const {
    updateCpuUsage,
    updateMemoryUsage,
    updateClusterResourceData,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  cpuUsageArr.current = cpuUsageInfo;
  memoryUsageArr.current = memoryUsageInfo;
  clusterResourceDataRef.current = clusterResourceData;

  useEffect(() => {
    performPolling(isPolling);
    pollInterval.current = setInterval(() => {
      performPolling(isPolling);
    }, PROCESSOR_USAGE_POLL_TIME);
    return () => {
      clearInterval(pollInterval.current);
    };
  }, [isPolling]);

  const updateCpuAndMemoryUsageLists = (procStats) => {
    const newCpuUsageArr = [...cpuUsageArr.current, procStats.CpuUsage];
    const newMemoryUsageArr = [...memoryUsageArr.current, procStats.MemUsage];
    if (newCpuUsageArr.length > 50) {
      newCpuUsageArr.shift();
    }
    if (newMemoryUsageArr.length > 50) {
      newMemoryUsageArr.shift();
    }
    cpuUsageArr.current = newCpuUsageArr;
    memoryUsageArr.current = newMemoryUsageArr;
    updateCpuUsage(newCpuUsageArr);
    updateMemoryUsage(newMemoryUsageArr);
  };

  const updateClusteredData = (data) => {
    try {
      const newClusterResourceData = structuredClone(clusterResourceData);
      const workersList = Object.keys(data);
      if (!newClusterResourceData.clusterEngine) {
        newClusterResourceData.clusterEngine = true;
        newClusterResourceData.order = workersList;
      }
      const shiftArrayIfNeeded = (array) => {
        if (array.length > 50) array.shift();
      };
      workersList.forEach((worker) => {
        const graphsData = data[worker];
        const oldWorkerInfo =
          clusterResourceDataRef?.current?.workersInfo?.[worker];
        let updatedWorkerInfo = null;
        if (oldWorkerInfo) {
          const cpu_usage_percent = [
            ...oldWorkerInfo.cpu_usage_percent,
            graphsData.cpu_usage_percent,
          ];
          const ram_usage_percent = [
            ...oldWorkerInfo.ram_usage_percent,
            graphsData.ram_usage_percent,
          ];
          const disk_usage = [
            ...oldWorkerInfo.disk_usage,
            graphsData.disk_usage,
          ];
          const network_bandwidth_mb = [
            ...oldWorkerInfo.network_bandwidth_mb,
            graphsData.network_bandwidth_mb,
          ];
          updatedWorkerInfo = {
            hostname: graphsData.hostname,
            cpu_usage_percent: cpu_usage_percent,
            ram_usage_percent: ram_usage_percent,
            disk_usage: disk_usage,
            network_bandwidth_mb: network_bandwidth_mb,
          };
        } else if (graphsData.status === "active") {
          updatedWorkerInfo = {
            hostname: graphsData.hostname,
            cpu_usage_percent: [graphsData.cpu_usage_percent],
            ram_usage_percent: [graphsData.ram_usage_percent],
            disk_usage: [graphsData.disk_usage],
            network_bandwidth_mb: [graphsData.network_bandwidth_mb],
          };
        }
        if (updatedWorkerInfo) {
          shiftArrayIfNeeded(updatedWorkerInfo.cpu_usage_percent);
          shiftArrayIfNeeded(updatedWorkerInfo.ram_usage_percent);
          shiftArrayIfNeeded(updatedWorkerInfo.disk_usage);
          shiftArrayIfNeeded(updatedWorkerInfo.network_bandwidth_mb);
        }
        newClusterResourceData.workersInfo[worker] = updatedWorkerInfo;
      });
      clusterResourceDataRef.current = newClusterResourceData;
      updateClusterResourceData(newClusterResourceData);
    } catch (error) {
      console.error(error);
    }
  };

  const performPolling = async (isPolling) => {
    if (isPolling) {
      return;
    }
    try {
      /**
       * Replace this data in fetchresusage-test-data.json to view the UI of a single engine
       * {
       *   "data": {
       *     "posts": [
       *       {
       *         "clusterEngine": false,
       *         "ProcessorUtilization": {
       *           "CpuUsage": 32.125,
       *           "MemUsage": 38.3
       *         }
       *       }
       *     ]
       *   },
       *   "status": 200
       * }
       */
      const apiUrl = BASE_API_URL + "fetchresusage";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchresusageAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.data.status === 200) {
        const clusterEngine = response.data.data.posts[0].clusterEngine;
        const processorStats = response.data.data.posts[0].ProcessorUtilization;
        if (clusterEngine) {
          updateClusteredData(processorStats);
        } else {
          updateCpuAndMemoryUsageLists(processorStats);
        }
        updateAlertMessage(null);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        clearInterval(pollInterval.current);
      }
    } catch (error) {
      console.error(error);
      clearInterval(pollInterval.current);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  return (
    <>
      {clusterResourceData.clusterEngine ? (
        <ClusteredGraphs />
      ) : (
        <Stack className="sidebar-graph-container">
          <Stack>
            <Box className="graph-header">Cpu Usage (%)</Box>
            <CpuUsageGraph data={cpuUsageInfo} graphHeight={250} />
          </Stack>
          <Stack className="memory-usage-container">
            <Box className="graph-header">Memory Usage (%)</Box>
            <MemoryUsageGraph data={memoryUsageInfo} graphHeight={250} />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default SidebarGraph;
