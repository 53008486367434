import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Button,
  Tooltip,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refreshIcon.svg";
import "./statsProgressInfoDialog.scss";
import api from "../../apiInterceptor";
import { isEmpty } from "lodash";
const progressStatsAPITestData = require("../../assets/apiTestData/progress-stats-test-data.json");

const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "28px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
  ":disabled": {
    background: "rgb(213 205 205 / 59%)",
  },
};

const StatsProgressInfoDialog = () => {
  const EDA_API_URL = localStorage.getItem("EDA_API_URL");
  const EDA_REDIRECT_URL = localStorage.getItem("EDA_REDIRECT_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [statsProgressData, setStatsProgressData] = useState([]);
  const [dsMap, setDsMap] = useState({});
  const pipeline = useSelector((state) => state.pipeline);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const showStatsProgressInfoDialog = useSelector(
    (state) => state.showStatsProgressInfoDialog
  );
  const dispatch = useDispatch();
  const { updateShowStatsProgressInfoDialog, updateAlertMessage } =
    bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (EDA_REDIRECT_URL && !isEmpty(pipeline)) getStatsStatus();
    getDsMap();
  }, []);

  const getDsMap = () => {
    const dsMap = pipeline?.clientMetaData?.dsMap;
    setDsMap(dsMap);
  };

  const getStatsStatus = async () => {
    try {
      const apiUrl = EDA_API_URL + "progress_stats";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        stageId: null,
        stats_type: "overall",
        productType: "oda",
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: progressStatsAPITestData,
          status: 200,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (response.status === 200) {
        setStatsProgressData(response.data.data);
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const refreshStats = () => {
    getStatsStatus();
  };

  const handleClose = () => {
    updateShowStatsProgressInfoDialog(false);
  };

  const viewStats = (row) => {
    let ds = row.dSource;
    let stageId = null;
    // handle below code to enable and handle it for stage node
    // if (!currentNode.data.isStage) {
    //   ds = currentNode.id;
    // } else {
    //   ds = getConnectedDatasourceId(currentNode);
    //   stageId = currentNode.id;
    // }
    if (!EDA_REDIRECT_URL) {
      const errorMessage =
        "EDA URL is not available. Please contact the administrator.";
      updateAlertMessage(errorMessage);
    } else {
      const baseUrl = EDA_REDIRECT_URL.endsWith("/")
        ? EDA_REDIRECT_URL
        : `${EDA_REDIRECT_URL}/`;
      const url = `${baseUrl}?productType=oda&projectKey=${selectedProjectKey}&projVersion=${selectedProjVersion}&projFg=${selectedFeatureGroup}&dSource=${ds}&stageId=${stageId}`;
      window.open(url, "_blank");
    }
  };

  return (
    <Dialog
      fullWidth
      open={showStatsProgressInfoDialog}
      aria-labelledby="Stats Progress Info"
      className="stats-progress-info-dialog"
      maxWidth="md"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="title">
        <div>Stats Progress Info</div>
        <div>
          <Tooltip title="Refresh stats" placement="top">
            <IconButton
              aria-label="refresh"
              className="refresh-icon"
              onClick={refreshStats}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ maxHeight: "400px", overflowY: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#EFF1F1",
                    background: "#4b5362",
                    borderBottom: "none",
                  }}
                >
                  Node
                </TableCell>
                <TableCell
                  sx={{
                    color: "#EFF1F1",
                    background: "#4b5362",
                    borderBottom: "none",
                  }}
                >
                  Progress
                </TableCell>
                <TableCell
                  sx={{
                    color: "#EFF1F1",
                    background: "#4b5362",
                    borderBottom: "none",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "#EFF1F1",
                    background: "#4b5362",
                    borderBottom: "none",
                  }}
                >
                  View Report
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statsProgressData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background: index % 2 === 0 ? "#181a1c" : "#0e0f10",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "#EFF1F1",
                      borderBottom: "none",
                      maxWidth: "150px",
                      wordWrap: "break-word",
                    }}
                  >
                    {dsMap[row.dSource]}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={(row.total_completed_col / row.total_col) * 100}
                      style={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "#818181",
                      }}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#69c2d2",
                        },
                      }}
                    />
                    <div
                      style={{
                        marginTop: 5,
                        color: "#EFF1F1",
                        borderBottom: "none",
                      }}
                    >
                      {`${row.total_completed_col}/${row.total_col}`}
                    </div>
                  </TableCell>
                  <TableCell sx={{ color: "#EFF1F1", borderBottom: "none" }}>
                    {row.status}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Button
                      variant="contained"
                      disabled={row.status !== "Completed"}
                      sx={buttonStyles}
                      onClick={(e) => viewStats(row)}
                      autoFocus
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default StatsProgressInfoDialog;
