import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./valueMappingDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import {
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useReactFlow } from "reactflow";
import {
  fetchFeatures,
  getConnectedDataSources,
  getConnectedNodes,
  handleFilterSaveDisable,
  prepareColumns,
  getConfigureFeaturesdata,
} from "utils/utils";
import {
  buttonStyles,
  labelStyles,
  textFieldStyles,
  toggleButtonStyles,
  chipTextFieldStyles,
} from "./valueMappingDialogStyle";
import Filter from "components/filter/filter";
import api from "apiInterceptor";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/value-mapping-test-data.json");

const ValueMappingDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "customModal";
  const { getNode } = useReactFlow();
  const formData = {
    colName: null,
    opColName: "",
    name: "",
    description: "",
    mapList: "",
    ctgry: "",
    mapCfg: {},
    restMap: "no",
    restMapCatName: "",
    category: ["default"],
  };
  const filteredOptions = useRef({});
  const selectedNode = modalProps.selectedNode;
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const columnsInfo = [];
  const [allColumns, setAllColumns] = useState([]);
  const [commonColumns, setCommonColumns] = useState([]);
  const [numColumns, setNumColumns] = useState([]);
  const [catColumns, setCatColumns] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const [mixedColumns, setMixedColumns] = useState([]);
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState({});
  const [featureCategorizationData, setFeatureCategorizationData] = useState(
    []
  );
  const [isRestMapCatNameVisible, setIsRestMapCatNameVisible] = useState(false);
  const [mapCfgArray, setMapCfgArray] = useState([]);
  const [showApplyFilter, setShowApplyFilter] = useState(false);

  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const filterCfg = useSelector((state) => state.filterCfg);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const pipeline = useSelector((state) => state.pipeline);
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateFilterCfg,
    updateIsLoading,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);
  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: "4px 10px",
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    updateFilterCfg({});
    updateFilterCfg([]);
    getNodeLevelInfo();
    if (modalForm === null) {
      initializeModalForm();
    }
    getFeatureCategorizationData();
  }, []);

  useEffect(() => {
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (showFilter) {
      disableSave = handleFilterSaveDisable(filterCfg) || disableSave;
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  }, [modalForm, showFilter, filterCfg, mapCfgArray]);

  const getFeatureCategorizationData = async () => {
    const data = await getConfigureFeaturesdata(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (data && Array.isArray(data)) {
      const namesArray = data.map((item) => item.name);
      setFeatureCategorizationData(namesArray);
    }
    if (!data) {
      updateSnackbarMsg("Something went wrong. Please try again later");
      return;
    }
  };

  const handleDataSourceAbsence = () => {
    if (connectedDataSources.length === 0) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setShowApplyFilter(result.applyFilter);
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      const nodeConfigs = structuredClone(nodeConfigurations[selectedNode.id]);
      handleFeatureCategorizationDropdown(nodeConfigs);
      setTimeout(() => updateModalForm(nodeConfigs), 1);
      if (nodeConfigs.filterPres) {
        setShowFilter(true);
        updateFilterCfg(nodeConfigs.filterCfg[0].dataCfg);
      }
    } else {
      setTimeout(() => updateModalForm(formData), 1);
    }
    setTimeout(() => {
      initializeMapCfg();
    }, 1);
  };

  const handleFeatureCategorizationDropdown = (nodeConfigs) => {
    if (!nodeConfigs.category || !Array.isArray(nodeConfigs.category)) {
      nodeConfigs["category"] = ["default"];
    }
  };

  const initializeMapCfg = () => {
    if (
      modalForm?.restMap === "yes" ||
      (nodeConfigurations[selectedNode.id] &&
        nodeConfigurations[selectedNode.id]?.restMap === "yes")
    ) {
      setIsRestMapCatNameVisible(true);
    }
    const mapCfgObject =
      nodeConfigurations[selectedNode.id]?.mapCfg || modalForm?.mapCfg || {};
    const newMapCfgArray = [];
    for (const [key, value] of Object.entries(mapCfgObject)) {
      // Condition to not add ctrg and mapList keys of modalForm in mapCfgArray
      if (
        key !== (modalForm?.ctgry || nodeConfigurations[selectedNode.id]?.ctgry)
      )
        newMapCfgArray.push({
          mapList: value,
          ctgry: key,
        });
    }
    setMapCfgArray(newMapCfgArray);
  };

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
    setNumColumns(allColumnValues.allColumnsInfo.numerical);
    setCatColumns(allColumnValues.allColumnsInfo.categorical);
    setDateColumns(allColumnValues.allColumnsInfo.date);
    setMixedColumns(allColumnValues.allColumnsInfo.mixed);
    setCommonColumns(allColumnValues.commonFeatures);
  };

  const handleSaveDisable = () => {
    // Check if input fields are empty.
    if (
      !modalForm.colName ||
      !modalForm.opColName ||
      !modalForm.ctgry ||
      !modalForm.mapList ||
      !modalForm.restMap ||
      !modalForm.category ||
      modalForm.colName === "" ||
      modalForm.opColName === "" ||
      modalForm.ctgry === "" ||
      modalForm.mapList === "" ||
      modalForm.restMap === "" ||
      modalForm.category === ""
    ) {
      return true;
    }
    if (!Array.isArray(modalForm.category) || modalForm.category.length === 0) {
      return true;
    }
    // Check if mapList has any empty string or is an empty array.
    if (Array.isArray(modalForm.mapList)) {
      let hasEmptyStringOrArray =
        modalForm?.mapList?.some((element) => element.length === 0) ||
        modalForm.mapList.length === 0;
      if (hasEmptyStringOrArray) {
        return true;
      }
    }
    // Check if mapCfgArray has any object with empty string or empty array.
    for (let obj of mapCfgArray) {
      let hasEmptyString = false;
      if (Array.isArray(obj.mapList)) {
        hasEmptyString =
          obj?.mapList?.some((element) => element.length === 0) ||
          obj.mapList.length === 0;
      }
      let disableSave =
        hasEmptyString || obj.ctgry === "" || obj.mapList === "";
      if (disableSave) {
        return true;
      }
    }
    // Check if restMap is yes and restMapCatName field is empty.
    if (
      modalForm.restMap === "yes" &&
      (!modalForm.restMapCatName || modalForm.restMapCatName === "")
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (e, isMappedArrayInput, index) => {
    const value = e.target.value;
    const name = e.target.name;
    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" || e.target.name === "opColName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }
    if (isMappedArrayInput) {
      const newMapCfgArray = structuredClone(mapCfgArray);
      newMapCfgArray[index][name] = value;
      setMapCfgArray(newMapCfgArray);
    } else {
      const newFormData = structuredClone(modalForm);
      newFormData[name] = value;
      updateModalForm(newFormData);
    }
  };

  const handleWordCountChange = (evt, isMappedArrayInput, index) => {
    const value = evt.target.value.split(",");
    const name = evt.target.name;
    if (isMappedArrayInput) {
      const newMapCfgArray = structuredClone(mapCfgArray);
      newMapCfgArray[index][name] = value;
      setMapCfgArray(newMapCfgArray);
    } else {
      const newFormData = structuredClone(modalForm);
      newFormData[name] = value;
      updateModalForm(newFormData);
    }
  };

  const handleParentInputChange = (evt) => {
    let newValue = evt.target.value;
    if (newValue === "yes") {
      setIsRestMapCatNameVisible(true);
    } else {
      setIsRestMapCatNameVisible(false);
    }
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = newValue;
    updateModalForm(newFormData);
  };

  const addMap = () => {
    const newMapCfgArray = structuredClone(mapCfgArray);
    newMapCfgArray.push({
      mapList: "",
      ctgry: "",
    });
    setMapCfgArray(newMapCfgArray);
  };

  const removeMappingFromArray = (index) => {
    const newMapCfgArray = structuredClone(mapCfgArray);
    newMapCfgArray.splice(index, 1);
    setMapCfgArray(newMapCfgArray);
  };

  const createMapCfgObject = () => {
    const mapCfgObject = {};
    mapCfgArray.forEach((obj) => {
      // Condition to push ctrg and mapList keys of modalForm in mapCfgObject
      mapCfgObject[obj.ctgry] = obj.mapList;
    });
    mapCfgObject[modalForm?.ctgry] = modalForm?.mapList;
    return mapCfgObject;
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    if (showFilter) {
      newFormData["filterPres"] = true;
      newFormData["filterCfg"] = [{}];
      newFormData.filterCfg[0]["dataCfg"] = structuredClone(filterCfg);
    } else {
      newFormData["filterPres"] = false;
    }
    newFormData["mapCfg"] = createMapCfgObject();
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    if (allColumns.includes(newFormData.opColName)) {
      updateSnackbarMsg(
        "Column name already exists. Please enter a unique output column name"
      );
      return;
    }
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources[0];
    if (!selectedNode.data.isStage) {
      newNodeConfigurations[selectedNode.id]["sourceInfo"] = {};
      for (let connectedNode of connectedNodes) {
        if (connectedNode.stageId) {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "output";
        } else {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "input";
        }
      }
    }
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const handleFilterToggle = (e) => {
    setShowFilter(e.target.checked);
    const newFormData = structuredClone(modalForm);
    newFormData["filterPres"] = e.target.checked;
    updateModalForm(newFormData);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const handleSelectChange = (e, value, inputId) => {
    if (typeof value === "object") {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    const newFormData = structuredClone(modalForm);
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getColumns = (columns) => {
    let groupedOptions = [];
    const num = columns.filter((col) => numColumns.includes(col));
    const cat = columns.filter((col) => catColumns.includes(col));
    const dat = columns.filter((col) => dateColumns.includes(col));
    const mixed = columns.filter((col) => mixedColumns.includes(col));

    num.forEach((col) => {
      let obj = { item: col, title: "Numerical" };
      groupedOptions.push(obj);
    });
    cat.forEach((col) => {
      let obj = { item: col, title: "Categorical" };
      groupedOptions.push(obj);
    });
    dat.forEach((col) => {
      let obj = { item: col, title: "Date" };
      groupedOptions.push(obj);
    });
    mixed.forEach((col) => {
      let obj = { item: col, title: "Mixed" };
      groupedOptions.push(obj);
    });
    return groupedOptions;
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0].value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return option.value === value || option.item === value || value === "";
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const getOptionLabel = (option) => {
    if (option.item) {
      return option.item;
    } else {
      return option;
    }
  };

  const handleChipsChange = (e, value, inputId) => {
    let newFormData = structuredClone(modalForm);
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
      for (let index = 0; index < value.length - 1; index++) {
        if (value[index] === value[value.length - 1]) return;
      }
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else if (value?.name) {
        value = value?.name;
      } else {
        value = value?.value;
      }
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const updateFilteredOptions = (filteredOpt, inputId) => {
    filteredOptions.current[inputId] = filteredOpt;
    const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
    if (clonedSelectAllCheckboxes[inputId]) {
      clonedSelectAllCheckboxes[inputId] = false;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
  };

  const filterMultiSelectOptions = (options, inputValue, inputId) => {
    if (options.length) {
      const filteredOpts = options.filter((option) => {
        if (option.item) {
          return option.item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (option.value) {
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return option.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      updateFilteredOptions(filteredOpts, inputId);
      return filteredOpts;
    }
    return [];
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="value-mapping-dialog">
      {modalForm && (
        <div>
          <Dialog
            open={selectedNode !== null}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            fullWidth
            PaperProps={{
              style: {
                background: "#212B35",
                border: "1px solid #818181",
                boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                color: "#EFF1F1",
                maxHeight: "calc(100% - 122px)",
              },
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <div>Value Mapping</div>
              <div>
                {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
                  <IconButton>
                    <CustomTooltip
                      title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                    >
                      <QuestionMarkIcon
                        width={25}
                        height={25}
                      ></QuestionMarkIcon>
                    </CustomTooltip>
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton onClick={() => modalProps.closeModal(modalType)}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "20px !important" }}>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt, false, null)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="description" className="text-field" size="small">
                <TextField
                  type="text"
                  name="description"
                  size="small"
                  label="Description"
                  value={modalForm ? modalForm.description : ""}
                  onChange={(evt) => handleChange(evt, false, null)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <Autocomplete
                key={"colName"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={getColumns(allColumns)}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, allColumns);
                }}
                value={modalForm && modalForm.colName ? modalForm.colName : ""}
                onChange={(e, value) => {
                  handleSelectChange(e, value, "colName");
                }}
                renderTags={renderTags}
                groupBy={(option) => option.title}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      modalForm && modalForm.colName ? modalForm.colName : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="Column name for mapping"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
              <FormControl id="opColName" className="text-field" size="small">
                <TextField
                  type="text"
                  name="opColName"
                  size="small"
                  label="Output column name after mapping"
                  value={modalForm ? modalForm.opColName : ""}
                  onChange={(evt) => handleChange(evt, false, null)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="mapList" className="text-field" size="small">
                <TextField
                  type="text"
                  name="mapList"
                  size="small"
                  label="Comma seperated values"
                  value={modalForm ? modalForm.mapList : ""}
                  onChange={(evt) => handleWordCountChange(evt)}
                  autoComplete="off"
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
              <FormControl id="ctgry" className="text-field" size="small">
                <TextField
                  type="text"
                  name="ctgry"
                  size="small"
                  label="Category to be mapped"
                  value={modalForm ? modalForm.ctgry : ""}
                  onChange={(evt) => handleChange(evt, false, null)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <Box marginTop={"15px"}>
                <Box className="add-more-map-button-container">
                  <Button
                    sx={buttonStyles}
                    className="add-more-map-button"
                    onClick={addMap}
                  >
                    Add maps
                  </Button>
                </Box>
                {mapCfgArray.map((input, index) => (
                  <Box className="map-cfg-container" key={index}>
                    <Box>
                      <FormControl
                        id={"mapList" + index}
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="mapList"
                          size="small"
                          label="Comma seperated values"
                          value={input ? input.mapList : ""}
                          onChange={(evt) =>
                            handleWordCountChange(evt, true, index)
                          }
                          autoComplete="off"
                          variant="outlined"
                          sx={textFieldStyles}
                          required
                        />
                      </FormControl>
                      <FormControl
                        id={"ctgry" + index}
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="ctgry"
                          size="small"
                          label="Category to be mapped"
                          value={input ? input.ctgry : ""}
                          onChange={(evt) => handleChange(evt, true, index)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                          required
                        />
                      </FormControl>
                    </Box>
                    <IconButton
                      aria-label="close"
                      className="close-icon"
                      onClick={() => removeMappingFromArray(index)}
                    >
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <FormControl id="restMap" sx={{ marginTop: "15px !important" }}>
                <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
                  Map rest of the values to other category
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="restMap"
                  value={modalForm ? modalForm.restMap : ""}
                  onChange={(e) => handleParentInputChange(e)}
                >
                  {[
                    {
                      name: "Yes",
                      value: "yes",
                    },
                    {
                      name: "No",
                      value: "no",
                    },
                  ].map((radio, radioIndex) => (
                    <FormControlLabel
                      key={radioIndex}
                      value={radio.value}
                      control={<Radio style={{ color: "#69C2D2" }} />}
                      label={radio.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {isRestMapCatNameVisible ? (
                <FormControl
                  id="restMapCatName"
                  className="text-field"
                  size="small"
                >
                  <TextField
                    type="text"
                    name="restMapCatName"
                    size="small"
                    label="Category name for other values"
                    value={modalForm ? modalForm.restMapCatName : ""}
                    onChange={(evt) => handleChange(evt, false, null)}
                    variant="outlined"
                    sx={textFieldStyles}
                    autoComplete="off"
                    required
                  />
                </FormControl>
              ) : (
                <div></div>
              )}
              <FormControl
                id="category"
                sx={{ marginTop: "15px !important", width: "100% !important" }}
              >
                <Autocomplete
                  key="category"
                  multiple
                  autoHighlight
                  size="small"
                  id="category"
                  options={featureCategorizationData}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  disableCloseOnSelect
                  className="chips-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    const options = featureCategorizationData;
                    return isOptionEqualToValue(option, value, options);
                  }}
                  value={modalForm ? modalForm["category"] : []}
                  onChange={(e, value) => {
                    handleChipsChange(e, value, "category");
                  }}
                  renderTags={renderTags}
                  groupBy={(option) => option.title}
                  renderGroup={(params) => {
                    return (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select category"
                      placeholder="Select category"
                      autoComplete="off"
                      sx={chipTextFieldStyles}
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterMultiSelectOptions(options, inputValue, "category")
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip title={option} placement="right">
                        <Typography noWrap>{option}</Typography>
                      </Tooltip>
                    </li>
                  )}
                />
              </FormControl>
              {showApplyFilter ? (
                <div>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ marginTop: "1rem" }}
                  >
                    <Switch
                      onChange={(e) => handleFilterToggle(e)}
                      aria-labelledby="row-toggle-button-label"
                      name="filter-toggle"
                      sx={toggleButtonStyles}
                      checked={modalForm && modalForm.filterPres}
                    />
                    <Typography>Apply Filter</Typography>
                  </Stack>
                  <Stack>
                    {showFilter ? (
                      <Filter
                        connectedDataSources={connectedDataSources}
                        allColumns={allColumns}
                        numColumns={numColumns}
                        catColumns={catColumns}
                        dateColumns={dateColumns}
                        mixedColumns={mixedColumns}
                        dsMap={pipeline.clientMetaData.dsMap}
                        isMultiFilter={false}
                        index={0}
                      ></Filter>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </div>
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions>
              <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  autoFocus
                  sx={buttonStyles}
                  disabled={isSaveDisabled || userAccessRestricted}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  onClick={() => modalProps.closeModal(modalType)}
                  autoFocus
                  sx={buttonStyles}
                >
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarMsg}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={action}
          />
        </div>
      )}
    </div>
  );
};

export default ValueMappingDialog;
