import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./customModal.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import DownloadIcon from "../../assets/icons/downloadIcon.svg";
import { useReactFlow } from "reactflow";
import api from "../../apiInterceptor";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Filter from "components/filter/filter";
import {
  buttonStyles,
  toggleButtonStyles,
  menuStyles,
  menuItemStyles,
} from "./customModalStyle.jsx";
import {
  fetchFeatures,
  getConnectedDataSources,
  getConnectedNodes,
  handleFilterSaveDisable,
  handleGeneralSaveButtonDisabling,
  initializeFilterInfo,
  prepareColumns,
  restoreDialogUI,
  handleDependentInputs,
  getConfigureFeaturesdata,
  downloadFile,
  getModelSourceOptions,
} from "utils/utils";
import CustomForm from "components/customForm/customForm";
import UploadWithTable from "components/uploadWithTable/uploadWithTable";
import { Snackbar, Menu, MenuItem } from "@mui/material";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const uploadDataAPITestData = require("../../assets/apiTestData/success-test-data.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const CustomModal = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "customModal";
  const { getNode } = useReactFlow();
  let formData = {};
  let tableData = {};
  const selectedNode = modalProps.selectedNode;
  const nodeType = selectedNode?.name;
  const isCompletedNode =
    selectedNode?.className?.includes("completed") ?? false;
  let leftArray = [];
  let rightArray = [];
  let currentModalUI = structuredClone(modalProps.uiConfig[nodeType]);
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const columnsInfo = [];
  const [allColumns, setAllColumns] = useState([]);
  const [leftDataColumns, setLeftDataColumns] = useState([]);
  const [rightDataColumns, setRightDataColumns] = useState([]);
  const [commonColumns, setCommonColumns] = useState([]);
  const [numColumns, setNumColumns] = useState([]);
  const [catColumns, setCatColumns] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const [mixedColumns, setMixedColumns] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save");
  const [modelSourceOptions, setModelSourceOptions] = useState([]);
  const [featureCategorizationData, setFeatureCategorizationData] = useState(
    []
  );
  const [showApplyFilter, setShowApplyFilter] = useState(false);
  const [maxColsAllowed, setMaxColsAllowed] = useState(null);
  const [wordCountMismatch, setWordCountMismatch] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [localUploadOptions, setLocalUploadOptions] = useState([]);
  const [remoteUploadOptions, setRemoteUploadOptions] = useState([]);
  const [dbTypeOptions, setDBTypeOptions] = useState([]);
  const [mathOptionsData, setMathOptionsData] = useState({});
  const [statOptionsData, setStatOptionsData] = useState({});
  const [aggOptionsData, setAggOptionsData] = useState({});
  const [dateOptionsData, setDateOptionsData] = useState({});
  const [featureCountMismatch, setFeatureCountMismatch] = useState(false);
  const openDownloadMenu = Boolean(downloadAnchorEl);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const table = useSelector((state) => state.table);
  const modalForm = useSelector((state) => state.modalForm);
  const currentForm = useSelector((state) => state.currentFormType);
  const modalUI = useSelector((state) => state.modalUI);
  let selectedFile = useRef(null);
  const pipeline = useSelector((state) => state.pipeline);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const filterCfg = useSelector((state) => state.filterCfg);
  const statementNames = useSelector((state) => state.statementNames);
  const pivotStatCfg = useSelector((state) => state.pivotStatCfg);
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const dispatch = useDispatch();
  const {
    updateTable,
    updateModalForm,
    updatecurrentFormType,
    updateModalUI,
    updateNodeConfigurations,
    updateIsLoading,
    updateFilterCfg,
    updateStatementNames,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    updateFilterCfg([]);
    updateStatementNames([{}]);
    if (
      selectedNode.data.nodeType !== "modelDevelopment" &&
      selectedNode.data.nodeType !== "modelScoring"
    ) {
      getNodeLevelInfo();
    }
    if (selectedNode.data.nodeType === "modelScoring") {
      setModelScoringNodeData();
    }
    handleSaveButtonText();
    if (
      currentModalUI?.type === "uploadWithTable" ||
      currentModalUI?.type === "fileUpload"
    ) {
      initializeModalFormForUploadWithTable();
    } else if (currentModalUI?.type === "form" && modalForm === null) {
      initializeModalForm();
    } else if (currentModalUI?.type === "table") {
      initializeTableData();
    }
    if (modalUI === null) {
      initializeModalUI();
    }
    getFeatureCategorizationData();
  }, []);

  useEffect(() => {
    if (modalUI) {
      handleSaveButtonDisabling();
    }
  }, [modalUI, modalForm, showFilter, filterCfg, pivotStatCfg]);

  const handleDataSourceAbsence = () => {
    if (
      connectedDataSources.length === 0 &&
      selectedNode.data.nodeKind !== "add" &&
      selectedNode.data.nodeKind !== "stack-multi-dur" &&
      selectedNode.data.nodeKind !== "script"
    ) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const handleSaveButtonDisabling = () => {
    let disableSave = false;
    let inputs = [];
    if (nodeType === "pivot") {
      inputs = modalUI.input.filter(
        (inputField) =>
          !inputField.hidden &&
          inputField.identifier &&
          inputField.identifier !== "includeMissingImp" &&
          inputField.identifier !== "numVal" &&
          inputField.identifier !== "catVal"
      );
    } else if (nodeType === "pad") {
      inputs = modalUI.input.filter(
        (inputField) =>
          !inputField.hidden &&
          inputField.identifier &&
          inputField.identifier !== "padSize"
      );
    } else {
      inputs = modalUI.input.filter(
        (inputField) => !inputField.hidden && inputField.identifier
      );
    }
    disableSave = handleGeneralSaveButtonDisabling(
      inputs,
      modalForm,
      selectedNode
    );
    if (
      !disableSave &&
      (selectedNode.data.nodeType === "rename" ||
        selectedNode.data.nodeType === "missingImputation" ||
        selectedNode.data.nodeType === "replaceValues")
    ) {
      disableSave = handleWordCountSaveDisable();
    } else if (selectedNode.data.nodeType === "join") {
      disableSave = handleJoinSaveDisable();
    } else if (!disableSave && selectedNode.data.nodeType === "database") {
      disableSave = handleDatabaseSaveDisable(disableSave);
    } else if (!disableSave && selectedNode.data.nodeType === "pivot") {
      disableSave = handlePivotSaveDisable();
    } else if (!disableSave && selectedNode.data.nodeType === "pad") {
      disableSave = handlePaddingSaveDisable();
    } else if (!disableSave && selectedNode.data.nodeType === "interaction") {
      disableSave = handleInteractionSaveDisable();
    } else if (
      !disableSave &&
      selectedNode.data.nodeType === "mathematicalFunctions"
    ) {
      disableSave = handleMathematicalSaveDisable();
    } else if (!disableSave && selectedNode.data.nodeType === "precision") {
      disableSave = handlePrecisionSaveDisable();
    } else if (!disableSave && selectedNode.data.nodeType === "ratio") {
      disableSave = handleRatioSaveDisable();
    } else if (selectedNode.data.nodeType === "modelScoring") {
      disableSave = handleModelScoringSaveDisable();
    }
    if (!disableSave && showFilter) {
      disableSave = handleFilterSaveDisable(filterCfg);
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  };

  const handleSaveButtonText = () => {
    if (
      selectedNode.data.nodeType === "modelDevelopment" ||
      selectedNode.data.nodeType === "modelScoring"
    ) {
      setSaveButtonText("Configure");
    }
  };

  const getFeatureCategorizationData = async () => {
    const data = await getConfigureFeaturesdata(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (data && Array.isArray(data)) {
      const namesArray = data.map((item) => item.name);
      setFeatureCategorizationData(namesArray);
    }
    if (!data) {
      updateSnackbarMsg("Something went wrong. Please try again later");
      return;
    }
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setShowApplyFilter(result.applyFilter);
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
        if (result.maxCols) {
          setMaxColsAllowed(result.maxCols);
        }
        setNodeOptions(result);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const setNodeOptions = (result) => {
    if (selectedNode.data.nodeType === "fileUpload") {
      if (
        result.local &&
        Array.isArray(result.local.options) &&
        result.local.options.length > 0
      ) {
        setLocalUploadOptions(result.local.options);
      }
      if (
        result.remote &&
        Array.isArray(result.remote.options) &&
        result.remote.options.length > 0
      ) {
        setRemoteUploadOptions(result.remote.options);
      }
    }
    if (
      selectedNode.data.nodeType === "database" ||
      selectedNode.data.nodeType === "output"
    ) {
      if (Array.isArray(result.options) && result.options.length > 0) {
        setDBTypeOptions(result.options);
      }
    }
    if (selectedNode.data.nodeType === "statisticalTransformation") {
      setStatOptionsData(result);
    }
    if (selectedNode.data.nodeType === "mathematicalFunctions") {
      setMathOptionsData(result);
    }
    if (selectedNode.data.nodeType === "aggregation") {
      setAggOptionsData(result);
    }
    if (selectedNode.data.nodeType === "dateOperations") {
      setDateOptionsData(result);
    }
  };

  const setModelScoringNodeData = () => {
    const options = getModelSourceOptions(
      pipeline?.mlNodes,
      nodesFlow,
      nodeConfigurations
    );
    setModelSourceOptions(options);
  };

  const initializeTableData = () => {
    if (table === null) {
      tableData = currentModalUI?.table;
      setTimeout(() => updateTable(tableData), 1);
    }
  };

  const initializeModalUI = () => {
    const allInputs = restoreDialogUI(
      currentModalUI,
      selectedNode,
      nodeConfigurations
    );
    currentModalUI.input = structuredClone(allInputs);
    setTimeout(() => {
      updateModalUI(currentModalUI);
    }, 1);
  };

  const prepareLeftAndRightDataColumns = (connectedNode, features) => {
    const currentFormData = nodeConfigurations[selectedNode.id];
    if (
      currentFormData?.leftData &&
      (currentFormData?.leftData === connectedNode.dSource ||
        currentFormData?.leftData === connectedNode.stageId) &&
      connectedDataSources.length >= 2 &&
      leftArray.length === 0
    ) {
      ["derivedFeatures", "rowFeatures"].forEach((obj) => {
        const featureObj = features[obj];
        if (featureObj) {
          leftArray.push(...featureObj.categorical);
          leftArray.push(...featureObj.date);
          leftArray.push(...featureObj.mixed);
          leftArray.push(...featureObj.numerical);
        }
      });
      setLeftDataColumns(leftArray);
    }
    const rightDataSource =
      connectedDataSources[0] === currentFormData?.leftData
        ? connectedDataSources[1]
        : connectedDataSources[0];
    if (
      currentFormData?.leftData &&
      rightDataSource === connectedNode.dSource &&
      connectedDataSources.length === 2 &&
      rightArray.length === 0
    ) {
      ["derivedFeatures", "rowFeatures"].forEach((obj) => {
        const featureObj = features[obj];
        if (featureObj) {
          rightArray.push(...featureObj.categorical);
          rightArray.push(...featureObj.date);
          rightArray.push(...featureObj.mixed);
          rightArray.push(...featureObj.numerical);
        }
      });
      setRightDataColumns(rightArray);
    }
  };

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
      if (selectedNode.data.nodeType === "join") {
        prepareLeftAndRightDataColumns(connectedNode, features);
      }
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
    setNumColumns(allColumnValues.allColumnsInfo.numerical);
    setCatColumns(allColumnValues.allColumnsInfo.categorical);
    setDateColumns(allColumnValues.allColumnsInfo.date);
    setMixedColumns(allColumnValues.allColumnsInfo.mixed);
    setCommonColumns(allColumnValues.commonFeatures);
  };

  const initializeModalForm = () => {
    const nodeConfigs = structuredClone(nodeConfigurations[selectedNode.id]);
    if (nodeConfigs && !nodeConfigs.modalFormEmpty) {
      if (selectedNode.data.nodeType === "split" && !nodeConfigs.splitType) {
        const splitType = pipeline[selectedNode.id]["dataCfg"]["subtype"];
        nodeConfigs["splitType"] = splitType;
        handleSplitNodeHiddenInputs(splitType, nodeConfigs);
      }
      if (
        [
          "database",
          "multiStack",
          "pivot",
          "explode",
          "trend",
          "shift",
          "everTarget",
          "coinTarget",
          "dpdExtract",
          "pySpark",
          "python",
          "interaction",
          "ratio",
          "aggregation",
          "dateOperations",
          "statisticalTransformation",
          "mathematicalFunctions",
          "rename",
          "uidAddition",
          "fixedValueCreation",
          "extractSubstrings",
          "collapsing",
        ].includes(selectedNode.data.nodeType)
      ) {
        handleFeatureCategorizationDropdown(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "database") {
        handleDatabaseNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "ratio") {
        handleRatioNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "interaction") {
        handleInteractionNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "aggregation") {
        handleAggregateNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "mathematicalFunctions") {
        handleMathematicalNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "precision") {
        handlePrecisionNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "statisticalTransformation") {
        handleStatsNodeInputs(nodeConfigs);
      }
      if (selectedNode.data.nodeType === "modelScoring") {
        handleEmptyingFieldsInScoringNode(nodeConfigs);
      }
      setTimeout(() => updateModalForm(nodeConfigs), 1);
      if (nodeConfigs.filterPres) {
        setShowFilter(true);
        const initializedFilterInfo = initializeFilterInfo(
          nodeConfigurations,
          filterCfg,
          statementNames,
          selectedNode
        );
        updateStatementNames(initializedFilterInfo.newStatementNames);
        updateFilterCfg(initializedFilterInfo.newFilterCfg);
      }
    } else {
      initializeModalFormWithEmptyFields();
    }
  };

  const initializeModalFormWithEmptyFields = () => {
    currentModalUI?.input.forEach((input) => {
      if (input.type === "inputWithSelect") {
        input.input.forEach((innerInput) => {
          formData[innerInput.identifier] = "";
        });
      } else if (
        (selectedNode.data.nodeType === "statisticalTransformation" &&
          input.identifier === "multCol") ||
        (selectedNode.data.nodeType === "observationWindow" &&
          input.identifier === "perWinLink")
      ) {
        formData[input.identifier] = "no";
        setTimeout(() => showHiddenDependentInputs(input.mappedWith, "no"), 1);
      } else if (isDefaultValueNoInput(input)) {
        formData[input.identifier] = "no";
      } else if (
        selectedNode.data.nodeType === "observationWindow" &&
        input.identifier === "durFlag_2"
      ) {
        formData[input.identifier] = "no_2";
      } else if (
        selectedNode.data.nodeType === "aggregation" &&
        input.identifier === "backPropagation"
      ) {
        formData[input.identifier] = "yes";
      } else if (
        (selectedNode.data.nodeType === "ratio" ||
          selectedNode.data.nodeType === "interaction") &&
        input.identifier === "subType"
      ) {
        formData[input.identifier] = "onetoone";
      } else if (
        selectedNode.data.nodeType === "pad" &&
        input.identifier === "padSide"
      ) {
        formData[input.identifier] = "left";
      } else if (
        selectedNode.data.nodeType === "multiStack" &&
        input.identifier === "sourceType"
      ) {
        formData[input.identifier] = "db";
        setTimeout(() => showHiddenDependentInputs(input.mappedWith, "db"), 1);
      } else if (
        input.identifier === "category" &&
        [
          "database",
          "multiStack",
          "pivot",
          "explode",
          "trend",
          "shift",
          "everTarget",
          "coinTarget",
          "dpdExtract",
          "pySpark",
          "python",
          "interaction",
          "ratio",
          "aggregation",
          "dateOperations",
          "statisticalTransformation",
          "mathematicalFunctions",
          "rename",
          "uidAddition",
          "fixedValueCreation",
          "extractSubstrings",
          "collapsing",
        ].includes(selectedNode.data.nodeType)
      ) {
        formData[input.identifier] = ["default"];
      } else {
        formData[input.identifier] = "";
      }
    });
    delete formData["modalFormEmpty"];
    setTimeout(() => updateModalForm(formData), 1);
  };

  const isDefaultValueNoInput = (input) => {
    if (selectedNode.data.nodeType === "split" && input.identifier === "cond") {
      return true;
    }
    if (
      selectedNode.data.nodeType === "dataTypeConversion" &&
      (input.identifier === "numToCatPres" ||
        input.identifier === "catToNumPres" ||
        input.identifier === "catToDatePres" ||
        input.identifier === "dateToDatePres" ||
        input.identifier === "toCustPres")
    ) {
      return true;
    }
    if (
      selectedNode.data.nodeType === "precision" &&
      (input.identifier === "ceilFlag" ||
        input.identifier === "floorFlag" ||
        input.identifier === "roundFlag")
    ) {
      return true;
    }
    return false;
  };

  const handleFeatureCategorizationDropdown = (nodeConfigs) => {
    if (!nodeConfigs.category || !Array.isArray(nodeConfigs.category)) {
      nodeConfigs["category"] = ["default"];
    }
  };

  const handleRatioNodeInputs = (nodeConfigs) => {
    if (nodeConfigs.numerator && typeof nodeConfigs.numerator === "string") {
      nodeConfigs["numerator"] = [nodeConfigs.numerator];
    }
    if (
      nodeConfigs.denominator &&
      typeof nodeConfigs.denominator === "string"
    ) {
      nodeConfigs["denominator"] = [nodeConfigs.denominator];
    }
    if (
      nodeConfigs.constDenominator &&
      typeof nodeConfigs.constDenominator === "number"
    ) {
      nodeConfigs["constDenominator"] = nodeConfigs.constDenominator.toString();
    }
    if (!nodeConfigs.subType) {
      nodeConfigs["subType"] = "onetoone";
    }
    if (nodeConfigs.type === "Constant") {
      nodeConfigs["denominator"] = [];
    }
  };

  const handleInteractionNodeInputs = (nodeConfigs) => {
    if (
      nodeConfigs.firstFeature &&
      typeof nodeConfigs.firstFeature === "string"
    ) {
      nodeConfigs["firstFeature"] = [nodeConfigs.firstFeature];
    }
    if (nodeConfigs.secFeature && typeof nodeConfigs.secFeature === "string") {
      nodeConfigs["secFeature"] = [nodeConfigs.secFeature];
    }
    if (!nodeConfigs.subType) {
      nodeConfigs["subType"] = "onetoone";
    }
  };

  const handleAggregateNodeInputs = (nodeConfigs) => {
    if (!nodeConfigs.backPropagation) {
      nodeConfigs["backPropagation"] = "yes";
    }
  };

  const handlePrecisionNodeInputs = (nodeConfigs) => {
    if (nodeConfigs.precisionVal && Array.isArray(nodeConfigs.precisionVal)) {
      nodeConfigs["precisionVal"] = nodeConfigs.precisionVal.join(",");
    }
  };

  const handleMathematicalNodeInputs = (nodeConfigs) => {
    if (
      nodeConfigs.firstFeature &&
      typeof nodeConfigs.firstFeature === "string"
    ) {
      nodeConfigs["firstFeature"] = [nodeConfigs.firstFeature];
    }
    if (nodeConfigs.secFeature && typeof nodeConfigs.secFeature === "string") {
      nodeConfigs["secFeature"] = [nodeConfigs.secFeature];
    }
    if (nodeConfigs.secCustom && Array.isArray(nodeConfigs.secCustom)) {
      nodeConfigs["secCustom"] = nodeConfigs.secCustom.join(",");
    }
  };

  const handleStatsNodeInputs = (nodeConfigs) => {
    if (nodeConfigs.numCol && typeof nodeConfigs.numCol === "string") {
      nodeConfigs["numCol"] = [nodeConfigs.numCol];
    }
  };

  const handleSplitNodeHiddenInputs = (splitType, nodeConfigs) => {
    const inputArray = currentModalUI?.input;
    const input = inputArray.find((input) => input.identifier === "splitType");
    if (input) {
      setTimeout(
        () =>
          showHiddenDependentInputs(input.mappedWith, splitType, nodeConfigs),
        1
      );
    }
  };

  const handleEmptyingFieldsInScoringNode = (nodeConfigs) => {
    const allDataSources = Object.keys(pipeline.clientMetaData.dsMap);
    if (
      pipeline?.mlNodes.includes(connectedDataSources[0]) &&
      nodeConfigs.modelSource !== connectedDataSources[0]
    ) {
      nodeConfigs["modelSource"] = "";
    }
    if (
      pipeline?.mlNodes.includes(connectedDataSources[0]) &&
      !allDataSources.includes(nodeConfigs.dSource)
    ) {
      nodeConfigs["dSource"] = "";
    }
  };

  const showHiddenDependentInputs = (
    mappedInput,
    value,
    nodeConfigs = null
  ) => {
    if (!nodeConfigs) {
      nodeConfigs = formData;
    }
    if (mappedInput && mappedInput.length > 0 && currentModalUI) {
      const inputs = handleDependentInputs(
        currentModalUI,
        mappedInput,
        value,
        nodeConfigs
      );
      setTimeout(() => updateModalUI({ ...currentModalUI, input: inputs }), 1);
    }
  };
  const initializeModalFormForUploadWithTable = () => {
    if (modalForm === null) {
      if (nodeConfigurations[selectedNode.id]) {
        currentModalUI?.input.forEach((input) => {
          formData[input.identifier] = "";
          if (input.identifier === "pathType") {
            formData[input.identifier] = "url";
          }
        });
        const nodeConfigs = structuredClone(
          nodeConfigurations[selectedNode.id]
        );
        handleFeatureCategorizationDropdown(nodeConfigs);
        setTimeout(() => updateModalForm(nodeConfigs), 1);
      } else {
        currentModalUI?.input.forEach((input) => {
          formData[input.identifier] = "";
          if (input.identifier === "hostType") {
            formData[input.identifier] = "local";
          } else if (input.identifier === "category") {
            formData[input.identifier] = ["default"];
          }
        });
        setTimeout(() => updateModalForm(formData), 1);
      }
      if (
        currentModalUI?.type === "uploadWithTable" &&
        nodeConfigurations[selectedNode.id] &&
        (nodeConfigurations[selectedNode.id].file ||
          nodeConfigurations[selectedNode.id].url)
      ) {
        updatecurrentFormType("table");
      } else {
        updatecurrentFormType("fileUpload");
      }
    }
    if (table === null) {
      tableData = currentModalUI?.["tableInfo"].table;
      setTimeout(() => updateTable(tableData), 1);
    }
  };

  const handleDatabaseSaveDisable = (disableSave) => {
    if (modalForm.rowSelInfo === "identifier") {
      const identifierList = modalForm.idList.split(",");
      return identifierList.some((identifier) => !identifier);
    }
    return disableSave;
  };

  const handleDatabaseNodeInputs = (nodeConfigs) => {
    if (
      nodeConfigs.rowSelInfo === "identifier" &&
      Array.isArray(nodeConfigs.idList) &&
      nodeConfigs.idList.length > 0
    ) {
      nodeConfigs["idList"] = nodeConfigs.idList.join(",");
    } else {
      nodeConfigs["idList"] = "";
    }
  };

  const handleJoinSaveDisable = () => {
    if (
      connectedDataSources?.length === 1 ||
      !modalForm ||
      !modalForm?.name ||
      modalForm?.name === "" ||
      !modalForm?.leftData ||
      modalForm?.leftData === "" ||
      !modalForm?.joinCol ||
      modalForm?.joinCol === "" ||
      !Array.isArray(modalForm?.joinCol) ||
      modalForm?.joinCol?.length === 0 ||
      !modalForm?.joinType ||
      modalForm?.joinType === "" ||
      !modalForm?.leftDataColKind ||
      modalForm?.leftDataColKind === ""
    ) {
      return true;
    }
    if (
      modalForm?.leftDataColKind === "partial" &&
      (!modalForm?.leftDataCols ||
        modalForm?.leftDataCols === "" ||
        !Array.isArray(modalForm?.leftDataCols) ||
        modalForm?.leftDataCols?.length === 0)
    ) {
      return true;
    }
    if (
      connectedDataSources?.length === 2 &&
      (!modalForm?.rightDataColKind ||
        modalForm?.rightDataColKind === "" ||
        (modalForm?.rightDataColKind === "partial" &&
          (!modalForm?.rightDataCols ||
            modalForm?.rightDataCols === "" ||
            !Array.isArray(modalForm?.rightDataCols) ||
            modalForm?.rightDataCols?.length === 0)))
    ) {
      return true;
    }
    return false;
  };

  const handlePivotSaveDisable = () => {
    for (let statCfg of pivotStatCfg) {
      if (statCfg.statFuncs.length === 0 || statCfg.cols.length === 0) {
        return true;
      }
    }
    if (modalForm.includeMissingImp) {
      if (
        (modalForm.numVal === undefined || modalForm.numVal === "") &&
        (modalForm.catVal === undefined || modalForm.catVal === "")
      ) {
        return true;
      }
    }
    return false;
  };

  const handleInteractionSaveDisable = () => {
    const areFeaturesArrays =
      Array.isArray(modalForm.firstFeature) &&
      Array.isArray(modalForm.secFeature);
    if (!areFeaturesArrays) {
      return true;
    }
    if (modalForm.subType === "onetoone") {
      const equalLengths =
        modalForm.firstFeature.length === modalForm.secFeature.length;
      if (equalLengths) setFeatureCountMismatch(false);
      else setFeatureCountMismatch(true);
      return !equalLengths;
    }
    return false;
  };

  const handleMathematicalSaveDisable = () => {
    if (modalForm.operationType === "column") {
      if (modalForm.firstFeature.length === modalForm.secFeature.length) {
        setFeatureCountMismatch(false);
        return false;
      } else setFeatureCountMismatch(true);
    } else {
      const numArray = modalForm.secCustom.split(",");
      const numArrayCheck = numArray.some(
        (num) => !num || num === "0" || num === "-0" || !isValidNumber(num)
      );
      if (!numArrayCheck && modalForm.firstFeature.length === numArray.length) {
        setFeatureCountMismatch(false);
        return false;
      } else setFeatureCountMismatch(true);
    }
    return true;
  };

  const handlePrecisionSaveDisable = () => {
    if (modalForm.roundFlag === "yes") {
      const roundArray = modalForm?.precisionVal?.split(",");
      const roundArrayCheck = roundArray?.some(
        (num) => !num || /^0+$/.test(num) || !Number.isInteger(Number(num))
      );
      if (!roundArrayCheck && roundArray.length === modalForm.round.length)
        return false;
      return true;
    } else return false;
  };

  const handleRatioSaveDisable = () => {
    try {
      if (!Array.isArray(modalForm.numerator)) {
        return true;
      }
      if (modalForm.type === "Column") {
        if (!Array.isArray(modalForm.denominator)) {
          return true;
        }
        if (modalForm.subType === "onetoone") {
          if (modalForm.numerator.length === modalForm.denominator.length) {
            setFeatureCountMismatch(false);
          } else {
            setFeatureCountMismatch(true);
          }
          return modalForm.numerator.length !== modalForm.denominator.length;
        }
        return false;
      } else {
        const denoArray = modalForm.constDenominator.split(",");
        const equalLengths = modalForm.numerator.length === denoArray.length;
        if (modalForm.subType === "onetoone" && !equalLengths) {
          setFeatureCountMismatch(true);
          return true;
        } else setFeatureCountMismatch(false);
        return denoArray.some(
          (deno) => !deno || deno === "0" || !isValidNumber(deno)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isValidNumber = (str) => {
    const regex = /^-?(?:\d+|\d*\.\d+)$/;
    return regex.test(str);
  };

  const handlePaddingSaveDisable = () => {
    if (modalForm.padSize === undefined || modalForm.padSize === "") {
      return true;
    }
    return false;
  };

  const handleModelScoringSaveDisable = () => {
    if (!modalForm.name || modalForm.name === "") {
      return true;
    }
    if (
      (modalForm.dSource === undefined || modalForm.dSource === "") &&
      pipeline?.mlNodes.includes(connectedDataSources[0])
    ) {
      return true;
    }
    if (
      (modalForm.modelSource === undefined || modalForm.modelSource === "") &&
      !pipeline?.mlNodes.includes(connectedDataSources[0])
    ) {
      return true;
    }
    if (
      modalForm.modelSource === "None" &&
      (!modalForm.autoMlProjectKey ||
        modalForm.autoMlProjectKey === "" ||
        !modalForm.autoMlProjVersion ||
        modalForm.autoMlProjVersion === "")
    ) {
      return true;
    }
    return false;
  };

  const handleWordCountSaveDisable = () => {
    if (
      selectedNode.data.nodeType === "rename" ||
      selectedNode.data.nodeType === "missingImputation"
    ) {
      // condition to disable button if target column names has less or more values than source column names in rename node or missing imputation node
      // for missing imputation node name of identifiers are same as rename node identifiers beacuse we are not using them in back-end and it makes code reusable
      if (
        !modalForm?.baseCols ||
        !modalForm?.newCols ||
        modalForm?.baseCols?.length !== modalForm?.newCols?.length
      ) {
        setWordCountMismatch(true);
        return true;
      } else {
        const hasEmptyString = modalForm?.newCols?.some(
          (element) => element.length === 0
        );
        if (hasEmptyString) {
          return true;
        }
      }
    } else if (selectedNode.data.nodeType === "replaceValues") {
      // condition to write equal comma seprated values in ipList and opList in Replace Values node
      if (
        !modalForm?.ipList ||
        !modalForm?.opList ||
        modalForm?.ipList?.length !== modalForm?.opList?.length
      ) {
        setWordCountMismatch(true);
        return true;
      } else {
        let hasEmptyString =
          modalForm?.ipList?.some((element) => element.length === 0) ||
          modalForm?.opList?.some((element) => element.length === 0);
        if (hasEmptyString) {
          setWordCountMismatch(false);
          return true;
        }
      }
    }
    setWordCountMismatch(false);
    return false;
  };

  const updateFileDetails = async (newSelectedFile) => {
    selectedFile.current = newSelectedFile;
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const createDictionary = (array1, array2) => {
    //function to create object/dictionary with elemnets of array1 as keys mapped with elements of array2 as values
    const dictionary = {};
    for (let i = 0; i < array1.length; i++) {
      dictionary[array1[i]] = array2[i];
    }
    return dictionary;
  };

  const getJoinFormData = (newFormData) => {
    const incomingDataSources = structuredClone(connectedDataSources);
    incomingDataSources.splice(
      incomingDataSources.indexOf(newFormData["leftData"]),
      1
    );
    newFormData["rightData"] = incomingDataSources;
    newFormData["jKind"] = connectedDataSources.length > 2 ? "yes" : "no";
    if (connectedDataSources.length > 2) {
      newFormData["rightDataColKind"] = "full";
    }
    if (
      !newFormData["leftDataCols"] ||
      newFormData["leftDataCols"] === "" ||
      !Array.isArray(newFormData["leftDataCols"])
    ) {
      newFormData["leftDataCols"] = [];
    }
    if (
      !newFormData["rightDataCols"] ||
      newFormData["rightDataCols"] === "" ||
      !Array.isArray(newFormData["rightDataCols"])
    ) {
      newFormData["rightDataCols"] = [];
    }
    return newFormData;
  };

  const getDBFormData = (newFormData) => {
    newFormData["sourceType"] = "db";
    if (newFormData["rowSelInfo"] === "identifier") {
      newFormData["idList"] = convertStringToList(newFormData["idList"]);
    }
    newFormData["fileName"] = newFormData["name"];
    if (newFormData.dbType === "sqlserver" && !newFormData["authentication"]) {
      newFormData["authentication"] = null;
    }
    return newFormData;
  };

  const convertStringToList = (str) => {
    return str ? str.split(",") : [];
  };

  const getFileUploadFormData = (newFormData) => {
    newFormData["sourceType"] = "file";
    newFormData["dataPath"] =
      newFormData.hostType === "local"
        ? newFormData.file.name
        : newFormData.dataPath;
    return newFormData;
  };

  const getDataTypeConversionFormData = (newFormData) => {
    newFormData["numToCat"] = Array.isArray(newFormData["numToCat"])
      ? newFormData["numToCat"]
      : [];
    newFormData["catToNum"] = Array.isArray(newFormData["catToNum"])
      ? newFormData["catToNum"]
      : [];
    newFormData["catToDate"] = Array.isArray(newFormData["catToDate"])
      ? newFormData["catToDate"]
      : [];
    newFormData["toCust"] = Array.isArray(newFormData["toCust"])
      ? newFormData["toCust"]
      : [];
    return newFormData;
  };

  const getObservationWindowFormData = (newFormData) => {
    // Custom conditions for keys are written for observationWindow because inputs are dependent on both yes and no for different radios.
    if (newFormData["durFlag_2"] === "yes_2") {
      newFormData["durFlag"] = "yes";
      newFormData["obWinFeat"] = newFormData["obWinFeat_2"];
      newFormData["offsetUnit"] = newFormData["offsetUnit_2"];
      newFormData["format"] = newFormData["format_2"];
      newFormData["duration"] = newFormData["duration_2"];
    } else {
      newFormData["durFlag"] = "no";
    }
    return newFormData;
  };

  const getStageNodeFormData = (newFormData) => {
    if (showFilter) {
      newFormData["filterPres"] = true;
      newFormData["filterCfg"] = [{}];
      newFormData.filterCfg[0]["dataCfg"] = structuredClone(filterCfg[0]);
    } else {
      newFormData["filterPres"] = false;
    }
    return newFormData;
  };

  const getUpdatedFormData = () => {
    let newFormData = structuredClone(modalForm);
    if (selectedNode.data.nodeType === "join") {
      newFormData = getJoinFormData(newFormData);
    } else if (selectedNode.data.nodeType === "fileUpload") {
      newFormData = getFileUploadFormData(newFormData);
    } else if (selectedNode.data.nodeType === "database") {
      newFormData = getDBFormData(newFormData);
    } else if (selectedNode.data.nodeType === "missingImputation") {
      newFormData["imputeInfo"] = createDictionary(
        newFormData?.baseCols,
        newFormData?.newCols
      );
    } else if (selectedNode.data.nodeType === "dataTypeConversion") {
      newFormData = getDataTypeConversionFormData(newFormData);
    } else if (selectedNode.data.nodeType === "trend") {
      newFormData["trendReqd"] = 1;
      newFormData["shiftReqd"] = 0;
      newFormData["pivotReqd"] = Number(newFormData["pivotReqd"]);
    } else if (selectedNode.data.nodeType === "shift") {
      newFormData["trendReqd"] = 0;
      newFormData["shiftReqd"] = 1;
      newFormData["pivotReqd"] = Number(newFormData["pivotReqd"]);
    } else if (selectedNode.data.nodeType === "observationWindow") {
      newFormData = getObservationWindowFormData(newFormData);
    } else if (selectedNode.data.nodeType === "everTarget") {
      newFormData["type"] = "ever";
    } else if (selectedNode.data.nodeType === "coinTarget") {
      newFormData["type"] = "coin";
    } else if (selectedNode.data.nodeType === "pivot") {
      newFormData["statCfg"] = pivotStatCfg;
      newFormData["condition"] = "Exclude";
    } else if (
      selectedNode.data.nodeType === "ratio" &&
      newFormData["type"] === "Constant"
    ) {
      newFormData["denominator"] = getDenominatorList(
        newFormData["constDenominator"]
      );
    } else if (selectedNode.data.nodeType === "stack") {
      newFormData["stackDataSource"] = "existingDataSource";
    } else if (
      selectedNode.data.nodeType === "python" ||
      selectedNode.data.nodeType === "pySpark"
    ) {
      newFormData["name"] = newFormData["fileName"];
    } else if (selectedNode.data.nodeType === "multiStack") {
      newFormData["stackDataSource"] = "dataSourceWithDuration";
    } else if (selectedNode.data.nodeType === "modelScoring") {
      newFormData["nodeId"] = selectedNode.id;
      // If modelSource is empty then assign prev model-dev node which is connected to it
      if (!newFormData["modelSource"]) {
        newFormData["modelSource"] = connectedDataSources[0];
      }
      // If dSource is empty then assign prev connected datasource node which is connected to it
      if (
        !newFormData["dSource"] ||
        !pipeline?.mlNodes.includes(connectedDataSources[0])
      ) {
        newFormData["dSource"] = connectedDataSources[0];
      }
    } else if (selectedNode.data.nodeType === "precision") {
      if (!modalForm.precisionVal) {
        newFormData["precisionVal"] = [];
      } else {
        newFormData["precisionVal"] = modalForm?.precisionVal
          ?.split(",")
          .map(Number);
      }
    } else if (selectedNode.data.nodeType === "mathematicalFunctions") {
      newFormData["secCustom"] = modalForm?.secCustom?.split(",").map(Number);
    }
    if (selectedNode.data.isStage) {
      newFormData = getStageNodeFormData(newFormData);
    }
    return newFormData;
  };

  const getDenominatorList = (str) => {
    const newString = structuredClone(str);
    return newString.split(",").map(Number);
  };

  const getUpdatedNodeConfigurations = (newFormData) => {
    let newNodeConfigurations = structuredClone(nodeConfigurations);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    if (
      selectedNode.data.nodeType === "join" ||
      selectedNode.data.nodeType === "stack"
    ) {
      newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources;
    } else {
      newNodeConfigurations[selectedNode.id]["inputDs"] =
        connectedDataSources[0];
    }
    if (!selectedNode.data.isStage) {
      newNodeConfigurations[selectedNode.id]["sourceInfo"] = {};
      for (let connectedNode of connectedNodes) {
        if (connectedNode.stageId) {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "output";
        } else {
          newNodeConfigurations[selectedNode.id].sourceInfo[
            connectedNode.dSource
          ] = "input";
        }
      }
    }
    return newNodeConfigurations;
  };

  const handleDialogClosing = (dialogType) => {
    if (
      dialogType !== "uploadWithTable" ||
      (dialogType === "uploadWithTable" && !modalForm.file && !modalForm.url)
    ) {
      modalProps.closeModal(modalType);
    }
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkColumnNameDuplicacy = (formData) => {
    if (nodeType === "rename") {
      for (let col of formData.newCols) {
        if (allColumns.includes(col)) {
          return true;
        }
      }
    } else if (nodeType === "statisticalTransformation") {
      if (formData.multCol === "yes") {
        if (allColumns.includes(formData.opColName)) {
          return true;
        }
      }
    }
    return false;
  };

  const checkSortOrderEquality = (formData) => {
    if (nodeType === "sorting") {
      if (formData.sortOrder.length !== formData.orderByCol.length) {
        return true;
      }
    } else if (nodeType === "fill" || nodeType === "performanceWindow") {
      if (formData.sortOrder.length !== formData.sortCol.length) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (e, dialogType) => {
    e.preventDefault();
    const newFormData = getUpdatedFormData();
    if (selectedNode.data.nodeType === "pivot") {
      if (newFormData.statCfg.length === 0) {
        updateSnackbarMsg("Incomplete configurations");
        return;
      }
    }
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    const duplicatedColumn = checkColumnNameDuplicacy(newFormData);
    if (duplicatedColumn) {
      updateSnackbarMsg(
        "Column name already exists. Please enter a unique column name"
      );
      return;
    }
    const unequalSortOrder = checkSortOrderEquality(newFormData);
    if (unequalSortOrder) {
      updateSnackbarMsg("Column names to order by and sort order should align");
      return;
    }

    updateModalForm(newFormData);
    const newNodeConfigurations = getUpdatedNodeConfigurations(newFormData);
    updateNodeConfigurations(structuredClone(newNodeConfigurations));
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    handleDialogClosing(dialogType);
  };

  const showDBTable = () => {
    updatecurrentFormType("table");
  };

  const showDBFileUpload = () => {
    updatecurrentFormType("fileUpload");
  };

  const uploadFileAndShowTable = (e, dialogType) => {
    if (
      (modalForm.hostType === "local" && selectedFile.current) ||
      modalForm.hostType === "remote" ||
      selectedNode.data.nodeType === "database" ||
      selectedNode.data.nodeType === "multiStack" ||
      ((selectedNode.data.nodeType === "python" ||
        selectedNode.data.nodeType === "pySpark") &&
        selectedFile.current)
    ) {
      saveUploadedFile(e, dialogType);
    } else {
      handleSubmit(e, dialogType);
    }
    if (dialogType === "uploadWithTable") {
      showDBTable();
    }
  };

  const checkHostNameValidity = (value) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!(ipRegex.test(value) || urlRegex.test(value))) {
      return false;
    } else {
      return true;
    }
  };

  const saveUploadedFile = async (e, dialogType) => {
    const apiUrl = BASE_API_URL + "upload-data";
    const headers = {};
    let payload = new FormData();
    let newModalForm = structuredClone(modalForm);
    if (selectedNode.data.nodeType === "database") {
      if (modalForm.dbType === "bigquery") {
        payload.append("files", selectedFile.current);
        payload.append("fileName", modalForm.name);
      }
      if (
        newModalForm.dbType === "sqlserver" &&
        !newModalForm["authentication"]
      ) {
        newModalForm["authentication"] = null;
      }
      payload.append("sourceType", "db");
      newModalForm["sourceType"] = "db";
      newModalForm["dsName"] = newModalForm.name;
    } else if (selectedNode.data.nodeType === "multiStack") {
      payload.append("sourceType", newModalForm.sourceType);
      newModalForm["dsName"] = newModalForm.name;
    } else if (
      selectedNode.data.nodeType === "python" ||
      selectedNode.data.nodeType === "pySpark"
    ) {
      newModalForm["sourceType"] = "script";
      newModalForm["dsName"] = newModalForm.fileName;
      newModalForm["name"] = newModalForm["fileName"];
      payload.append("files", selectedFile.current);
      payload.append("sourceType", newModalForm.sourceType);
      payload.append("fileName", modalForm.fileName);
    } else {
      if (modalForm.hostType === "local") {
        payload.append("files", selectedFile.current);
        payload.append("fileName", modalForm.fileName);
      } else if (
        modalForm.hostType === "remote" &&
        modalForm.storageType === "sftp"
      ) {
        const isValidHostName = checkHostNameValidity(modalForm.host);
        if (!isValidHostName) {
          updateSnackbarMsg(
            "Invalid host name. It should be a valid IP or Url"
          );
          return;
        }
      }
      payload.append("sourceType", "file");
      newModalForm["dsName"] = newModalForm.fileName;
      newModalForm = getFileUploadFormData(newModalForm);
    }
    payload.append("dSource", selectedNode.id);
    payload.append("projectKey", selectedProjectKey);
    payload.append("projVersion", selectedProjVersion);
    payload.append("projFg", selectedFeatureGroup);
    payload.append("dataCfg", JSON.stringify(newModalForm));
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: selectedFile.current,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleSubmit(e, dialogType);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleFilterToggle = (e) => {
    setShowFilter(e.target.checked);
    const newFormData = structuredClone(modalForm);
    newFormData["filterPres"] = e.target.checked;
    updateModalForm(newFormData);
  };

  const clickSubmit = (e, dialogType) => {
    if (
      dialogType === "uploadWithTable" ||
      dialogType === "fileUpload" ||
      selectedNode.data.nodeType === "database" ||
      selectedNode.data.nodeType === "multiStack" ||
      selectedNode.data.nodeType === "python" ||
      selectedNode.data.nodeType === "pySpark"
    ) {
      uploadFileAndShowTable(e, dialogType);
    } else {
      handleSubmit(e, dialogType);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const handleMenuClick = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDownloadAnchorEl(null);
  };

  const handleTemplateDownload = async (type) => {
    try {
      const apiUrl = BASE_API_URL + "dwnld-data";
      const timestamp = new Date().getTime();
      const nodeId = selectedNode.id;
      const nodeConfig = nodeConfigurations[nodeId];
      const nodeType = selectedNode.data.nodeType;
      let fileName = nodeConfig?.fileName;
      const dSource = nodeId;
      let subType = "";
      if (nodeType === "python") {
        subType = "python";
      } else if (nodeType === "pySpark") {
        subType = "pyspark";
      }
      const queryParams = fileName
        ? `fileName=${fileName}`
        : `subType=${subType}`;
      const exportUrl = `${apiUrl}?projectKey=${selectedProjectKey}&projVersion=${selectedProjVersion}&projFg=${selectedFeatureGroup}&docType=${type}&dSource=${dSource}&${queryParams}&timestamp=${timestamp}`;
      let fileType = "log";
      if (type !== "log") {
        if (fileName) {
          fileType =
            nodeConfig?.fileType === "python" ? "py" : nodeConfig?.fileType;
        } else {
          fileName =
            nodeType === "python"
              ? "python_transformation_sample"
              : "pyspark_transformation_sample";
          fileType = "py";
        }
      }
      downloadFile(
        exportUrl,
        fileName,
        fileType,
        updateIsLoading,
        updateAlertMessage
      );
    } catch (error) {
      console.error("Error downloading the file:", error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        open={selectedNode !== null}
        onClose={() => {}}
        aria-labelledby="responsive-dialog-title"
        maxWidth={modalUI?.size}
        fullWidth
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="back-button-container">
            {modalUI?.type === "uploadWithTable" && currentForm === "table" ? (
              <div className="back-button" onClick={showDBFileUpload}></div>
            ) : (
              ""
            )}
            <div>{modalUI?.header}</div>
          </div>
          <div>
            {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
              <IconButton>
                <CustomTooltip
                  title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                >
                  <QuestionMarkIcon width={25} height={25}></QuestionMarkIcon>
                </CustomTooltip>
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={() => modalProps.closeModal(modalType)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px !important" }}>
          {selectedNode.data.nodeKind === "script" ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              className="download-menu-container"
            >
              <IconButton aria-label="download-menu" onClick={handleMenuClick}>
                <img
                  src={DownloadIcon}
                  className="download-menu-icon"
                  alt="download-menu-icon"
                ></img>
              </IconButton>
              <Menu
                anchorEl={downloadAnchorEl}
                open={openDownloadMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "download-buttons",
                }}
                className="download-buttons-menu"
                sx={menuStyles}
              >
                <MenuItem
                  sx={menuItemStyles}
                  onClick={(e) => handleTemplateDownload("script")}
                >
                  Code template
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  disabled={!isCompletedNode}
                  onClick={(e) => handleTemplateDownload("log")}
                >
                  Logs
                </MenuItem>
              </Menu>
            </Stack>
          ) : (
            <></>
          )}
          {modalUI?.type === "form" ? (
            <CustomForm
              selectedNode={selectedNode}
              connectedDataSources={connectedDataSources}
              connectedNodes={connectedNodes}
              allColumns={allColumns}
              numColumns={numColumns}
              catColumns={catColumns}
              dateColumns={dateColumns}
              mixedColumns={mixedColumns}
              commonColumns={commonColumns}
              leftColumns={leftDataColumns}
              rightColumns={rightDataColumns}
              isSaveDisabled={isSaveDisabled}
              wordCountMismatch={wordCountMismatch}
              modelSourceOptions={modelSourceOptions}
              updateFileDetails={updateFileDetails}
              maxColsAllowed={maxColsAllowed}
              dbTypeOptions={dbTypeOptions}
              statOptionsData={statOptionsData}
              mathOptionsData={mathOptionsData}
              dateOptionsData={dateOptionsData}
              aggOptionsData={aggOptionsData}
              featureCountMismatch={featureCountMismatch}
              featureCategorizationData={featureCategorizationData}
            />
          ) : modalUI?.type === "fileUpload" ||
            modalUI?.type === "uploadWithTable" ? (
            <UploadWithTable
              localUploadOptions={localUploadOptions}
              remoteUploadOptions={remoteUploadOptions}
              updateFileDetails={updateFileDetails}
              featureCategorizationData={featureCategorizationData}
            />
          ) : modalUI?.type === "table" ? (
            <TableContainer className="modal-table">
              <Table size={table?.size}>
                <TableHead>
                  <TableRow>
                    {table?.columns.map((column, columnIndex) => (
                      <TableCell className="table-cell" key={columnIndex}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table?.rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {table?.columns.map((col, colIndex) => (
                        <TableCell className="table-cell" key={colIndex}>
                          {table?.rows[rowIndex][col]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}
          {showApplyFilter ? (
            <div>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ marginTop: "1rem" }}
              >
                <Switch
                  onChange={(e) => handleFilterToggle(e)}
                  aria-labelledby="row-toggle-button-label"
                  name="filter-toggle"
                  sx={toggleButtonStyles}
                  checked={modalForm && modalForm.filterPres}
                />
                <Typography>Apply Filter</Typography>
              </Stack>
              <Stack>
                {showFilter ? (
                  <Filter
                    connectedDataSources={connectedDataSources}
                    allColumns={allColumns}
                    numColumns={numColumns}
                    catColumns={catColumns}
                    dateColumns={dateColumns}
                    mixedColumns={mixedColumns}
                    dsMap={pipeline.clientMetaData.dsMap}
                    isMultiFilter={false}
                    index={0}
                  ></Filter>
                ) : (
                  <></>
                )}
              </Stack>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
            <Button
              variant="contained"
              onClick={(e) => clickSubmit(e, modalUI.type)}
              autoFocus
              sx={buttonStyles}
              disabled={isSaveDisabled || userAccessRestricted}
            >
              {saveButtonText}
            </Button>
            <Button
              variant="contained"
              onClick={() => modalProps.closeModal(modalType)}
              autoFocus
              sx={buttonStyles}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default CustomModal;
