import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Typography,
  Tooltip,
  Pagination,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useNavigate, useParams } from "react-router-dom";
import { getConfigureFeaturesdata, removeHighightedNode } from "utils/utils";
import "./featureTracingTable.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as FilterButtonIcon } from "../../assets/icons/filterButtonIcon.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/backIconWhite.svg";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import api from "../../apiInterceptor";
import {
  paginationStyles,
  textFieldStyles,
  tableStyles,
  autocompleteStyles,
  formControlStyles,
  selectStyles,
  menuItemStyles,
} from "./featureTracingTableStyle.jsx";
import { ReactComponent as TraceIcon } from "../../assets/icons/traceIcon.svg";
import { ReactComponent as TraceExploreIcon } from "../../assets/icons/traceExploreIcon.svg";
const featdictAPITestData = require("../../assets/apiTestData/feat-dict-test-data.json");

const FeatureTracingTable = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const navigate = useNavigate();
  const param = useParams();
  const alertMessage = useSelector((state) => state.alertMessage);
  const projectKey = param.projectKey;
  const [tableData, setTableData] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchFeatureName, setSearchFeatureName] = useState("");
  const [searchNodeName, setSearchNodeName] = useState("");
  const [category, setCategory] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showFeatureNameFilter, setShowFeatureNameFilter] = useState(false);
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [showNodeNameFilter, setShowNodeNameFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const dispatch = useDispatch();
  const { updateAlertMessage, updateIsLoading, updateShowPollingLoader } =
    bindActionCreators(actionCreators, dispatch);
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = Math.min(page * rowsPerPage, filteredTableData.length);
  const totalRows = filteredTableData.length;

  const handleSearchFeatureNameChange = (event) => {
    setSearchFeatureName(event.target.value);
  };

  const handleSearchNodeNameChange = (event) => {
    setSearchNodeName(event.target.value);
  };

  const handleCategoryChange = (newValue) => {
    setSelectedCategories(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const updatedTableData = tableData.filter((data) => {
      const featureMatches = data.feature
        .toLowerCase()
        .includes(searchFeatureName.toLowerCase());
      const nodeNameMatches = data.nodeName
        .toLowerCase()
        .includes(searchNodeName.toLowerCase());
      const categoryMatches =
        selectedCategories.length === 0 ||
        selectedCategories.some((category) =>
          data?.category?.includes(category.name)
        );
      return featureMatches && nodeNameMatches && categoryMatches;
    });
    setPage(1);
    setFilteredTableData(updatedTableData);
  }, [searchFeatureName, searchNodeName, selectedCategories, tableData]);

  useEffect(() => {
    getData();
    removeHighightedNode();
  }, []);

  const getData = async () => {
    // Using updateShowPollingLoader to show the loader until the UI is rendered completely
    updateShowPollingLoader(true);
    await getFeatureTracingTableData();
    await getFeaturesdata();
    updateShowPollingLoader(false);
  };

  const getFeaturesdata = async () => {
    const data = await getConfigureFeaturesdata(
      projectKey,
      null,
      null,
      updateIsLoading,
      updateAlertMessage
    );
    if (!data) {
      updateAlertMessage("Something went wrong. Please try again later");
      return;
    }
    if (Array.isArray(data)) {
      setCategoriesList(data);
    }
  };

  const getFeatureTracingTableData = async () => {
    try {
      const apiUrl = BASE_API_URL + "feat-dict";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projectKey,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: featdictAPITestData,
          status: 200,
        };
      } else {
        response = await api.get(apiUrl, {
          headers: headers,
          withCredentials: true,
          params: payload,
          responseType: "json",
        });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const result = response.data.data.posts;
        setTableData(result);
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        goBack();
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
      goBack();
    }
  };

  const traceFeature = (featureData) => {
    localStorage.setItem("tracedFeatureInfo", JSON.stringify(featureData));
    navigate(`/feature-trace/${projectKey}`);
  };

  const handleExplore = (featureData) => {
    localStorage.setItem(
      "highlightNodeId",
      featureData.stageId ? featureData.stageId : featureData.dSource
    );
    navigate(
      `/playground/${featureData.projectKey}/${featureData.projVersion}/${featureData.projFg}`
    );
  };

  const goBack = () => {
    navigate("/?viewType=catalog");
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.name;
    } else {
      return option;
    }
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFeatureNameFilterVisibility = () => {
    setShowFeatureNameFilter(!showFeatureNameFilter);
    setSearchFeatureName("");
  };

  const handleCategoryFilterVisibility = () => {
    setShowCategoryFilter(!showCategoryFilter);
    setSelectedCategories([]);
  };

  const handleNodeNameFilterVisibility = () => {
    setShowNodeNameFilter(!showNodeNameFilter);
    setSearchNodeName("");
  };

  return (
    <div
      style={{
        height: alertMessage ? "calc(100% - 135px)" : "calc(100% - 85px)",
      }}
      className="feature-tracing-table"
    >
      <Stack className="buttons-conatiner">
        <div className="back-btn" onClick={() => goBack()}>
          <IconButton aria-label="back-button">
            <BackIcon />
          </IconButton>
        </div>
        <Stack direction="row" alignItems="center" gap="28px"></Stack>
      </Stack>
      <TableContainer sx={tableStyles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={tableStyles.tableHeader}>
              <TableCell sx={{ width: "19%", minWidth: "180px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <div>Feature Name</div>
                  <Tooltip title="Filter" placement="top">
                    <div className="filter-icon-button">
                      <IconButton
                        onClick={handleFeatureNameFilterVisibility}
                        className="icon"
                      >
                        <FilterButtonIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Stack>
                {showFeatureNameFilter && (
                  <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    onChange={handleSearchFeatureNameChange}
                    variant="outlined"
                    sx={textFieldStyles}
                    className="search-box"
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ width: "9%" }}>
                <div>Data Type</div>
              </TableCell>
              <TableCell sx={{ width: "13%", minWidth: "180px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>Category</div>
                  <Tooltip title="Filter" placement="top">
                    <div className="filter-icon-button">
                      <IconButton
                        onClick={handleCategoryFilterVisibility}
                        className="icon"
                      >
                        <FilterButtonIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Stack>
                {showCategoryFilter && (
                  <Autocomplete
                    multiple
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={categoriesList}
                    disableCloseOnSelect
                    filterSelectedOptions
                    getOptionLabel={(option) => getOptionLabel(option)}
                    autoComplete
                    includeInputInList
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    className="feature-table-chips-dropdown"
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name || value === ""
                    }
                    value={selectedCategories}
                    onChange={(e, value) => {
                      handleCategoryChange(value);
                    }}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select"
                        autoComplete="off"
                        sx={autocompleteStyles}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option.name)}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option.name)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                )}
              </TableCell>
              <TableCell sx={{ width: "13%", minWidth: "180px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>Node Name</div>
                  <Tooltip title="Filter" placement="top">
                    <div className="filter-icon-button">
                      <IconButton
                        onClick={handleNodeNameFilterVisibility}
                        className="icon"
                      >
                        <FilterButtonIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Stack>
                {showNodeNameFilter && (
                  <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    onChange={handleSearchNodeNameChange}
                    variant="outlined"
                    sx={textFieldStyles}
                    className="search-box"
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell sx={{ width: "8%" }}>
                <div>Node Type</div>
              </TableCell>
              <TableCell sx={{ width: "28%" }}>
                <div>Description</div>
              </TableCell>
              <TableCell sx={{ width: "10%" }}>
                <div>Actions</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTableData
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((data, index) => (
                <TableRow key={index} sx={tableStyles.tableRow}>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip title={data.feature} placement="top-start">
                        <div className="col">{data.feature}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip title={data.type} placement="top-start">
                        <div className="col">{data.type}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip
                        title={data?.category?.join(", ")}
                        placement="top-start"
                      >
                        <div className="col">{data?.category?.join(", ")}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip title={data.nodeName} placement="top-start">
                        <div className="col">{data.nodeName}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip title={data.nodeType} placement="top-start">
                        <div className="col">{data.nodeType}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="text-container">
                      <Tooltip
                        title={data.description
                          .split("\n")
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {item}
                              <br />
                            </React.Fragment>
                          ))}
                        placement="top-start"
                      >
                        <div className="col description-col">
                          {data.description}
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell sx={tableStyles.tableCell}>
                    <div className="buttons-container">
                      <Tooltip title="Trace" placement="top">
                        <div className="icon-button">
                          <IconButton
                            onClick={() => traceFeature(data)}
                            aria-label="trace"
                            className="icon"
                          >
                            <TraceIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                      <Tooltip title="Explore" placement="top">
                        <div className="icon-button">
                          <IconButton
                            onClick={() => handleExplore(data)}
                            aria-label="explore"
                            className="icon"
                          >
                            <TraceExploreIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "12px",
          gap: "20px",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Rows Per Page :-
          <FormControl
            style={{ marginLeft: "20px" }}
            sx={formControlStyles}
            size="small"
          >
            <Select
              size="small"
              value={rowsPerPage}
              onChange={handleRowsPerPage}
              variant="outlined"
              sx={selectStyles}
              className="input-box"
              MenuProps={{ sx: selectStyles }}
            >
              <MenuItem sx={menuItemStyles} value={10}>
                10
              </MenuItem>
              <MenuItem sx={menuItemStyles} value={25}>
                25
              </MenuItem>
              <MenuItem sx={menuItemStyles} value={50}>
                50
              </MenuItem>
              <MenuItem sx={menuItemStyles} value={100}>
                100
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography variant="body2">
            {`${startIndex} - ${endIndex} of ${totalRows}`}
          </Typography>
          <Pagination
            count={Math.ceil(totalRows / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={paginationStyles}
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureTracingTable;
