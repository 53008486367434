import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Button,
  InputAdornment,
  Stack,
  MenuItem,
  Typography,
  Select,
  FormControl,
  TablePagination,
  Autocomplete,
  Checkbox,
  Box,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import "./calculateStatsDialog.scss";
import api from "../../apiInterceptor";
import { showSuccessAlert, fetchFeatures, prepareColumns } from "utils/utils";
import {
  formControlStyles,
  selectStyles,
  buttonStyles,
  menuItemStyles,
  textFieldStyles,
} from "./calculateStatsDialogStyle";
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");
const calculateStatsAPITestData = require("../../assets/apiTestData/calculateStats-test-data.json");
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");

const dateOptions = [
  "yyyy:MM:dd",
  "dd:MM:yyyy",
  "dd-MM-yyyy",
  "yyyy-MM-dd",
  "dd/MM/yyyy",
  "yyyyMMdd",
  "yyyy/MM/dd",
  "MM/dd/yyyy",
  "yyyy-MM-dd HH:mm:ss",
  "M/d/yyyy",
  "ddMMMyyyy:HH:mm:ss.S",
  "dd-MMM-yy",
  "ddMMMyyyy",
];

const CalculateStatsDialog = ({
  currentNode,
  getConnectedDatasourceId,
  getStatsStatus,
}) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const EDA_API_URL = localStorage.getItem("EDA_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const isCalculateStatsDialogOpen = useSelector(
    (state) => state.isCalculateStatsDialogOpen
  );
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const columnsInfo = [];
  const dispatch = useDispatch();
  const {
    updateIsLoading,
    updateCalculateStatsDialogStatus,
    updateAlertMessage,
    updateSnackbarMsg,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState("all");
  const [bulkSelectedRole, setBulkSelectedRole] = useState("none");
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  const [tableData, setTableData] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [paginatedTableData, setPaginatedTableData] = useState([]);
  const rowsPerPage = 100;
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getNodeLevelInfo();
  }, []);

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: currentNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        handleClose();
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeColumns = async (nodeColsInfo) => {
    let ds = null;
    let stageId = null;
    if (!currentNode.data.isStage) {
      ds = currentNode.id;
    } else {
      ds = getConnectedDatasourceId(currentNode);
      stageId = currentNode.id;
    }
    const features = await fetchFeatures(
      ds,
      stageId,
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (!features) {
      updateSnackbarMsg("Couldn't fetch features. Please save and try again");
      return;
    }
    columnsInfo.push(features);
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    prepareTableData(allColumnValues.allColumnsInfo);
  };

  const prepareTableData = (allCols) => {
    const data = [];
    for (let feature of allCols.date) {
      data.push({
        featureName: feature,
        featureType: "date",
        role: "Date",
      });
    }
    for (let feature of allCols.categorical) {
      data.push({
        featureName: feature,
        featureType: "categorical",
        role: "Reject",
      });
    }
    for (let feature of allCols.mixed) {
      data.push({
        featureName: feature,
        featureType: "mixed",
        role: "Reject",
      });
    }
    for (let feature of allCols.numerical) {
      data.push({
        featureName: feature,
        featureType: "numerical",
        role: "Reject",
      });
    }
    setTableData(data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectValue(value);
  };

  const handleClose = () => {
    updateCalculateStatsDialogStatus(false);
    updateSnackbarMsg(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const handleFeatureRoleChange = (role, feature) => {
    const newTableData = tableData.map((item) => {
      if (item.role === "UID" && role === "UID" && feature.role !== "UID") {
        return { ...item, role: "Input" };
      }
      if (item.role === "Date" && role === "Date" && feature.role !== "Date") {
        return { ...item, featureType: "categorical", role: "Reject" };
      }
      if (item.featureName === feature.featureName) {
        let featureType = feature.featureType;
        if (feature.role === "Date" && role !== "Date") {
          featureType = "categorical";
        }
        if (feature.role !== "Date" && role === "Date") {
          featureType = "date";
        }
        return { ...item, featureType: featureType, role: role };
      }
      return item;
    });
    setTableData(newTableData);
  };

  const handleFeatureTypeChange = (value, feature) => {
    const newTableData = tableData.map((item) => {
      if (item.featureName === feature.featureName) {
        let role = feature.role;
        if (value === "date") {
          role = "Date";
        }
        return { ...item, featureType: value, role: role };
      }
      return item;
    });
    setTableData(newTableData);
  };

  useEffect(() => {
    const filteredTableData = tableData.filter(
      (item) =>
        item.featureName.toLowerCase().includes(searchValue.toLowerCase()) &&
        (item.featureType.toLowerCase().includes(selectValue) ||
          selectValue === "all")
    );

    const sortedData = filteredTableData.sort((a, b) => {
      if (orderBy === "featureName") {
        return order === "asc"
          ? a.featureName.localeCompare(b.featureName)
          : b.featureName.localeCompare(a.featureName);
      } else if (orderBy === "featureType") {
        return order === "asc"
          ? a.featureType.localeCompare(b.featureType)
          : b.featureType.localeCompare(a.featureType);
      } else {
        return 0;
      }
    });
    setSortedTableData(sortedData);

    const paginatedData = sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setPaginatedTableData(paginatedData);
  }, [tableData, searchValue, selectValue, orderBy, order, page, rowsPerPage]);

  const handleDateFormatChange = (value, featureName) => {
    setDateFormat(value);
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (featureName) => selected.indexOf(featureName) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = paginatedTableData.map((row) => row.featureName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (bulkSelectedRole !== "none") {
      updateSelectedCheckBoxesRoles();
    }
  }, [bulkSelectedRole]);

  const updateSelectedCheckBoxesRoles = () => {
    const newTableData = tableData.map((item) => {
      if (selected.includes(item.featureName)) {
        return { ...item, role: bulkSelectedRole };
      }
      return item;
    });
    setTableData(newTableData);
    setBulkSelectedRole("none");
  };

  const prepareStatsData = () => {
    const numCols = [];
    const catCols = [];
    const dropList = [];
    let dateColumn = "";
    let Uid = "";
    tableData.forEach((feature) => {
      if (feature.role === "UID") {
        Uid = feature.featureName;
      } else if (feature.role === "Date") {
        dateColumn = feature.featureName;
      } else if (feature.role === "Input") {
        if (feature.featureType === "numerical") {
          numCols.push(feature.featureName);
        } else {
          catCols.push(feature.featureName);
        }
      } else {
        dropList.push(feature.featureName);
      }
    });
    let ds = null;
    let stageId = null;
    if (!currentNode.data.isStage) {
      ds = currentNode.id;
    } else {
      ds = getConnectedDatasourceId(currentNode);
      stageId = currentNode.id;
    }
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: selectedFeatureGroup,
      dSource: ds,
      stageId: stageId,
      stats_type: "overall",
      productType: "oda",
      numCols: numCols,
      catCols: catCols,
      dropList: dropList,
      dateColumn: dateColumn,
      dateFormat: dateFormat,
      Uid: Uid,
    };
    return payload;
  };

  const isStatsValid = () => {
    let hasInputFeature = false;
    let dateFeatureCount = 0;
    tableData.forEach((feature) => {
      if (feature.role === "Input") {
        hasInputFeature = true;
      } else if (feature.role === "Date") {
        dateFeatureCount++;
      }
    });
    if (!hasInputFeature) {
      updateSnackbarMsg("Please select a Input feature.");
      return false;
    }
    if (dateFeatureCount > 1) {
      updateSnackbarMsg("Please select only one date feature.");
      return false;
    }
    if (dateFeatureCount === 1 && !dateFormat) {
      updateSnackbarMsg("Please select a date format.");
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (isStatsValid()) {
      const payload = prepareStatsData();
      calculateStats(payload);
    }
  };

  const calculateStats = async (payload) => {
    try {
      const apiUrl = EDA_API_URL + "calculate_stats";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: calculateStatsAPITestData,
          status: 200,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        if (response.data.error) {
          updateAlertMessage(response.data.message);
        } else {
          showSuccessAlert(response.data.message, updateSuccessAlertMessage);
          getStatsStatus();
        }
        handleClose();
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isCalculateStatsDialogOpen}
        aria-labelledby="calc-stats"
        className="summary-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="summary-title">
          <span>Calculate Stats</span>
          <div className="icons-container">
            <IconButton
              aria-label="close"
              className="close-icon"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ padding: "10px 15px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Box sx={{ marginRight: "10px" }}>
                Update role for selected features to
              </Box>
              <FormControl sx={formControlStyles} size="small">
                <Select
                  placeholder="Select one..."
                  name="method"
                  size="small"
                  value={bulkSelectedRole}
                  onChange={(evt) => setBulkSelectedRole(evt.target.value)}
                  required
                  sx={selectStyles}
                  inputProps={{
                    sx: {
                      ...selectStyles,
                      "&::placeholder": {
                        color: "#D9D9D9",
                        opacity: 1,
                      },
                    },
                  }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  <MenuItem value="none" disabled sx={menuItemStyles}>
                    Select one...
                  </MenuItem>
                  <MenuItem value="Input" sx={menuItemStyles}>
                    Input
                  </MenuItem>
                  <MenuItem value="Reject" sx={menuItemStyles}>
                    Reject
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Button
              variant="contained"
              sx={buttonStyles}
              disabled={userAccessRestricted}
              autoFocus
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
          <TableContainer
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              borderRadius: "8px",
              background: "rgba(128 ,164 ,200 , 0.08)",
              height: "fit-content",
              marginTop: "6px",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    sx={{
                      backgroundColor: "rgb(74 ,85 ,97)",
                      zIndex: 100,
                      borderBottom: "none",
                      padding: "0px",
                    }}
                  >
                    <Checkbox
                      checked={
                        paginatedTableData.length > 0 &&
                        selected.length === paginatedTableData.length
                      }
                      onClick={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all" }}
                      sx={{
                        color: "#d9d9d9",
                        "&.Mui-checked": {
                          color: "#69C2D2",
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "#69C2D2",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgb(74 ,85 ,97)",
                      zIndex: 100,
                      borderBottom: "none",
                      padding: "5px 0 5px 10px",
                      gap: "10px",
                      fontSize: "16px",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "featureName"}
                      direction={orderBy === "featureName" ? order : "asc"}
                      onClick={() => handleRequestSort("featureName")}
                      sx={{
                        color: "#D9D9D9",
                        width: "100%",
                        "& .MuiTableSortLabel-icon": {
                          color: "#D9D9D9",
                        },
                        "&.Mui-active": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                        "&:hover": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                      }}
                    >
                      Feature Name
                    </TableSortLabel>
                    <Stack marginTop="4px">
                      <TextField
                        fullWidth
                        placeholder="Search..."
                        variant="outlined"
                        value={searchValue}
                        onChange={handleSearch}
                        sx={textFieldStyles}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClearSearch}
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  color: "#D9D9D9",
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgb(74 ,85 ,97)",
                      zIndex: 100,
                      borderBottom: "none",
                      padding: "5px 0 5px 10px",
                      gap: "10px",
                      fontSize: "16px",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "featureType"}
                      direction={orderBy === "featureType" ? order : "asc"}
                      onClick={() => handleRequestSort("featureType")}
                      sx={{
                        color: "#D9D9D9",
                        width: "100%",
                        "& .MuiTableSortLabel-icon": {
                          color: "#D9D9D9",
                        },
                        "&.Mui-active": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                        "&:hover": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                      }}
                    >
                      Feature Type
                    </TableSortLabel>
                    <Stack marginTop="4px">
                      <FormControl sx={formControlStyles} size="small">
                        <Select
                          placeholder="Select option"
                          name="method"
                          size="small"
                          value={
                            [
                              "all",
                              "numerical",
                              "categorical",
                              "mixed",
                              "date",
                            ].includes(selectValue)
                              ? selectValue
                              : "all"
                          }
                          onChange={(evt) => handleSelectChange(evt)}
                          required
                          sx={selectStyles}
                          inputProps={{ sx: selectStyles }}
                          MenuProps={{ sx: selectStyles }}
                          autoWidth={true}
                        >
                          <MenuItem value="all" sx={menuItemStyles}>
                            All
                          </MenuItem>
                          <MenuItem value="numerical" sx={menuItemStyles}>
                            Numerical
                          </MenuItem>
                          <MenuItem value="categorical" sx={menuItemStyles}>
                            Categorical
                          </MenuItem>
                          <MenuItem value="mixed" sx={menuItemStyles}>
                            Mixed
                          </MenuItem>
                          <MenuItem value="date" sx={menuItemStyles}>
                            Date
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgb(74 ,85 ,97)",
                      zIndex: 100,
                      borderBottom: "none",
                      minWidth: "150px",
                      padding: "4px 10px",
                      fontSize: "16px",
                    }}
                  >
                    <Stack>Role</Stack>
                    <Stack height="32px"></Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTableData.map((row, index) => {
                  const isItemSelected = isSelected(row.featureName);
                  return (
                    <TableRow
                      sx={{
                        borderBottom: "none",
                        height: "40px",
                      }}
                      key={index}
                    >
                      <TableCell
                        padding="checkbox"
                        sx={{
                          borderBottom: "none",
                          padding: "0px",
                          width: "5%",
                        }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleCheckboxClick(event, row.featureName)
                          }
                          sx={{
                            color: "#d9d9d9",
                            "&.Mui-checked": {
                              color: "#69C2D2",
                            },
                            "&.MuiCheckbox-indeterminate": {
                              color: "#69C2D2",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#D9D9D9",
                          borderBottom: "none",
                          padding: "4px 10px",
                          fontSize: "16px",
                          width: "30%",
                          wordBreak: "break-all",
                        }}
                      >
                        {row.featureName}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#D9D9D9",
                          borderBottom: "none",
                          padding: "4px 10px",
                          width: "20%",
                        }}
                      >
                        <FormControl sx={formControlStyles} size="small">
                          <Select
                            placeholder="Select option"
                            name="method"
                            size="small"
                            value={row.featureType}
                            onChange={(e) =>
                              handleFeatureTypeChange(e.target.value, row)
                            }
                            required
                            sx={selectStyles}
                            inputProps={{ sx: selectStyles }}
                            MenuProps={{ sx: selectStyles }}
                            autoWidth={true}
                          >
                            <MenuItem value="numerical" sx={menuItemStyles}>
                              Numerical
                            </MenuItem>
                            <MenuItem value="categorical" sx={menuItemStyles}>
                              Categorical
                            </MenuItem>
                            <MenuItem value="mixed" sx={menuItemStyles}>
                              Mixed
                            </MenuItem>
                            <MenuItem value="date" sx={menuItemStyles}>
                              Date
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#D9D9D9",
                          borderBottom: "none",
                          padding: "4px 10px",
                          width: "45%",
                        }}
                      >
                        <div className="role-btn-container">
                          <div
                            className="role-btn"
                            style={{
                              background:
                                row.role === "Input" ? "#69c2d2" : undefined,
                              border:
                                row.role === "Input"
                                  ? "1px solid #69c2d2"
                                  : undefined,
                            }}
                            onClick={() =>
                              handleFeatureRoleChange("Input", row)
                            }
                          >
                            Input
                          </div>
                          <div
                            className="role-btn"
                            style={{
                              background:
                                row.role === "Reject" ? "#69c2d2" : undefined,
                              border:
                                row.role === "Reject"
                                  ? "1px solid #69c2d2"
                                  : undefined,
                            }}
                            onClick={() =>
                              handleFeatureRoleChange("Reject", row)
                            }
                          >
                            Reject
                          </div>
                          <div
                            className="role-btn"
                            style={{
                              background:
                                row.role === "UID" ? "#69c2d2" : undefined,
                              border:
                                row.role === "UID"
                                  ? "1px solid #69c2d2"
                                  : undefined,
                            }}
                            onClick={() => handleFeatureRoleChange("UID", row)}
                          >
                            UID
                          </div>
                          <div
                            className="role-btn"
                            style={{
                              background:
                                row.role === "Date" ? "#69c2d2" : undefined,
                              border:
                                row.role === "Date"
                                  ? "1px solid #69c2d2"
                                  : undefined,
                            }}
                            onClick={() => handleFeatureRoleChange("Date", row)}
                          >
                            Date
                          </div>
                          {row.role === "Date" && (
                            <FormControl
                              size="small"
                              sx={{
                                width: "150px",
                              }}
                            >
                              <Autocomplete
                                freeSolo
                                disableClearable
                                options={dateOptions}
                                getOptionLabel={(option) => option}
                                value={dateFormat}
                                ListboxProps={{
                                  style: { maxHeight: 300, overflow: "auto" },
                                }}
                                onChange={(e, value) =>
                                  handleDateFormatChange(value, row.featureName)
                                }
                                componentsProps={{
                                  paper: {
                                    sx: {
                                      backgroundColor: "#212b35",
                                      color: "#d9d9d9",
                                      borderRadius: "4px",
                                      boxShadow:
                                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                                      "li:hover": { color: "#69c2d2" },
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    label={"Date format"}
                                    sx={textFieldStyles}
                                    autoComplete="off"
                                    onChange={(e) =>
                                      handleDateFormatChange(
                                        e.target.value,
                                        row.featureName
                                      )
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                          <InputAdornment position="end">
                                            <IconButton
                                              sx={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              onClick={() =>
                                                handleDateFormatChange(
                                                  "",
                                                  row.featureName
                                                )
                                              }
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Tooltip title={option} placement="right">
                                      <Typography noWrap>{option}</Typography>
                                    </Tooltip>
                                  </li>
                                )}
                              />
                            </FormControl>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={sortedTableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
              color: "#D9D9D9",
              "& .MuiToolbar-root ": {
                minHeight: "40px",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#D9D9D9",
              },
              "& .MuiTablePagination-select": {
                color: "#D9D9D9",
              },
              "& .MuiTablePagination-actions": {
                color: "#D9D9D9",
              },
              "& .MuiTablePagination-displayedRows": {
                color: "#D9D9D9",
              },
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default CalculateStatsDialog;
