const clusterResourceDataReducer = (
  state = { clusterEngine: false, order: [], workersInfo: {} },
  action
) => {
  if (action.type === "updateClusterResourceData") {
    state = action.payload;
    return state;
  } else {
    return state;
  }
};
export default clusterResourceDataReducer;
