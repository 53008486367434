import React from "react";
import "./catalogExplorer.scss";
import { useNavigate } from "react-router-dom";

const CatalogExplorer = ({ allProjects }) => {
  const navigate = useNavigate();

  const exploreFeature = (project) => {
    navigate(`/feature-tracing-table/${project.projectKey}`);
  };

  return (
    <div className="catalog-explorer-container">
      {allProjects.map((project, index) => (
        <div
          onClick={() => exploreFeature(project)}
          className="project-name-container"
          key={index}
        >
          <p className="project-name">{project.pname}</p>
          <p className="project-name">{project.state}</p>
          <p className="project-name">{project.projectKey}</p>
        </div>
      ))}
    </div>
  );
};

export default CatalogExplorer;
