import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
import Navbar from "./components/navbar/navbar";
import "./index.scss";
import { Provider } from "react-redux";
import store from "./state/store";
import DnDFlow from "./components/dndFlow/dndFlow";
import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "components/dashboard/dashboard";
import JobScheduler from "components/jobScheduler/jobScheduler";
import UserManagement from "components/userManagement/userManagement";
import TabularSidebar from "components/tabularSidebar/tabularSidebar";
import FeatureTracingTable from "components/featureTracingTable/featureTracingTable";
import { ReactComponent as LeftArrowIcon } from "./assets/icons/leftArrowIcon.svg";
import { ReactComponent as RightArrowIcon } from "./assets/icons/rightArrowIcon.svg";
import api from "apiInterceptor";
import FeatureTrace from "components/featureTrace/featureTrace";

const App = () => {
  let USER_MANAGEMENT_ENABLED = localStorage.getItem("USER_MANAGEMENT_ENABLED");
  USER_MANAGEMENT_ENABLED =
    USER_MANAGEMENT_ENABLED === "true" || USER_MANAGEMENT_ENABLED === true
      ? true
      : false;
  const [configs, setConfigs] = useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu from appearing
  };
  const [isLoading, setIsLoading] = useState(store.getState().isLoading);
  const [showPollingLoader, setShowPollingLoader] = useState(
    store.getState().showPollingLoader
  );
  const [isPlaygroundLoading, setIsPlaygroundLoading] = useState(
    store.getState().isPlaygroundLoading
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  useEffect(() => {
    api.updateShowPollingLoader = setShowPollingLoader;
    setConfigurableVariables();
    checkErrorMonitoringStatus();
    const unsubscribe = store.subscribe(() => {
      setIsLoading(store.getState().isLoading);
      setIsPlaygroundLoading(store.getState().isPlaygroundLoading);
      setShowPollingLoader(store.getState().showPollingLoader);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (showPollingLoader) {
      setIsPlaygroundLoading(false);
    }
  }, [showPollingLoader]);

  const checkErrorMonitoringStatus = () => {
    let errorMonitoringEnabled = localStorage.getItem(
      "ERROR_MONITORING_ENABLED"
    );
    errorMonitoringEnabled =
      errorMonitoringEnabled === "true" || errorMonitoringEnabled === true
        ? true
        : false;
    if (errorMonitoringEnabled) {
      initializeErrorMonitoring();
    }
  };

  const fetchClientConfigurations = async () => {
    try {
      const timestamp = new Date().toISOString();
      const response = await fetch(
        `/client-configuration.json?timestamp=${timestamp}`
      ); // Path is relative to the public folder
      const clientCfg = await response.json();
      return clientCfg;
    } catch (error) {
      console.log("Error fetching data:", error);
      return null;
    }
  };

  const setItemsInLocalStorage = (
    BASE_API_URL,
    EDA_API_URL,
    ERROR_API_URL,
    PROGRESS_POLL_TIME,
    PROCESSOR_USAGE_POLL_TIME,
    USER_MANAGEMENT_ENABLED,
    ERROR_MONITORING_ENABLED,
    ENABLE_STOP_RESUME_EXECUTION,
    IS_LIST_PROJECTS_GET_TYPE,
    ENABLE_PARTIAL_EXECUTION,
    USING_TEST_DATA,
    GLOBAL_COLLAPSE_ENABLED,
    NODE_LEVEL_COLLAPSE_ENABLED,
    COMMON_API_URL,
    EDA_REDIRECT_URL
  ) => {
    localStorage.setItem("BASE_API_URL", BASE_API_URL);
    localStorage.setItem("EDA_API_URL", EDA_API_URL);
    localStorage.setItem("COMMON_API_URL", COMMON_API_URL);
    localStorage.setItem("ERROR_API_URL", ERROR_API_URL);
    localStorage.setItem("PROGRESS_POLL_TIME", PROGRESS_POLL_TIME);
    localStorage.setItem("EDA_REDIRECT_URL", EDA_REDIRECT_URL);
    localStorage.setItem(
      "PROCESSOR_USAGE_POLL_TIME",
      PROCESSOR_USAGE_POLL_TIME
    );
    localStorage.setItem(
      "USER_MANAGEMENT_ENABLED",
      Boolean(USER_MANAGEMENT_ENABLED)
    );
    localStorage.setItem(
      "ERROR_MONITORING_ENABLED",
      Boolean(ERROR_MONITORING_ENABLED)
    );
    localStorage.setItem(
      "ENABLE_STOP_RESUME_EXECUTION",
      Boolean(ENABLE_STOP_RESUME_EXECUTION)
    );
    localStorage.setItem(
      "IS_LIST_PROJECTS_GET_TYPE",
      IS_LIST_PROJECTS_GET_TYPE
    );
    localStorage.setItem("ENABLE_PARTIAL_EXECUTION", ENABLE_PARTIAL_EXECUTION);
    localStorage.setItem("USING_TEST_DATA", USING_TEST_DATA);
    localStorage.setItem("GLOBAL_COLLAPSE_ENABLED", GLOBAL_COLLAPSE_ENABLED);
    localStorage.setItem(
      "NODE_LEVEL_COLLAPSE_ENABLED",
      NODE_LEVEL_COLLAPSE_ENABLED
    );
  };

  const setConfigurableVariables = async () => {
    const clientCfg = await fetchClientConfigurations();
    setConfigs(clientCfg);
    const apiConfigs = clientCfg.network.apiServer;
    const BASE_API_URL = apiConfigs.overrideIP
      ? `${apiConfigs.protocol}//${apiConfigs.ipAddress}:${apiConfigs.portNo}${apiConfigs.basePath}`
      : `${window.location.protocol}//${window.location.hostname}:${apiConfigs.portNo}${apiConfigs.basePath}`;
    const EDA_API_URL = apiConfigs.overrideIP
      ? `${apiConfigs.protocol}//${apiConfigs.ipAddress}:${apiConfigs.portNo}${apiConfigs.edaPath}`
      : `${window.location.protocol}//${window.location.hostname}:${apiConfigs.portNo}${apiConfigs.edaPath}`;
    const COMMON_API_URL = apiConfigs.overrideIP
      ? `${apiConfigs.protocol}//${apiConfigs.ipAddress}:${apiConfigs.portNo}${apiConfigs.commonPath}`
      : `${window.location.protocol}//${window.location.hostname}:${apiConfigs.portNo}${apiConfigs.commonPath}`;
    const ERROR_API_URL = apiConfigs.overrideIP
      ? `${apiConfigs.protocol}//${apiConfigs.ipAddress}:${apiConfigs.portNo}${apiConfigs.commonErrorPath}`
      : `${window.location.protocol}//${window.location.hostname}:${apiConfigs.portNo}${apiConfigs.commonErrorPath}`;
    const PROGRESS_POLL_TIME = clientCfg.uiConfigs.progressPollInterval;
    const EDA_REDIRECT_URL = clientCfg.edaURL;
    const PROCESSOR_USAGE_POLL_TIME = clientCfg.uiConfigs
      .processorUsagePollInterval
      ? clientCfg.uiConfigs.processorUsagePollInterval
      : 5000;
    const USER_MANAGEMENT_ENABLED = clientCfg.userManagement.enabled;
    const ERROR_MONITORING_ENABLED = clientCfg.errorMonitoring.enabled;
    const ENABLE_STOP_RESUME_EXECUTION =
      clientCfg.uiConfigs.enableStopAndResumeExecution;
    const IS_LIST_PROJECTS_GET_TYPE = clientCfg.uiConfigs.isListProjectGetType;
    const ENABLE_PARTIAL_EXECUTION = clientCfg.uiConfigs.enablePartialExecution;
    const GLOBAL_COLLAPSE_ENABLED = clientCfg.uiConfigs.globalCollapseEnabled;
    const NODE_LEVEL_COLLAPSE_ENABLED =
      clientCfg.uiConfigs.nodeLevelCollapseEnabled;
    const USING_TEST_DATA = clientCfg.testData.enabled;
    setItemsInLocalStorage(
      BASE_API_URL,
      EDA_API_URL,
      ERROR_API_URL,
      PROGRESS_POLL_TIME,
      PROCESSOR_USAGE_POLL_TIME,
      USER_MANAGEMENT_ENABLED,
      ERROR_MONITORING_ENABLED,
      ENABLE_STOP_RESUME_EXECUTION,
      IS_LIST_PROJECTS_GET_TYPE,
      ENABLE_PARTIAL_EXECUTION,
      USING_TEST_DATA,
      GLOBAL_COLLAPSE_ENABLED,
      NODE_LEVEL_COLLAPSE_ENABLED,
      COMMON_API_URL,
      EDA_REDIRECT_URL
    );
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const submitError = async (payload) => {
    try {
      let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
      USING_TEST_DATA =
        USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
      if (!USING_TEST_DATA) {
        const apiUrl = localStorage.getItem("ERROR_API_URL");
        const headers = {
          "Content-type": "application/json",
          Accept: "text/plain",
        };
        const response = await api.post(apiUrl, payload, { headers: headers });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initializeErrorMonitoring = () => {
    console.error = (msg, ...args) => {
      const error = new Error(msg);
      const stackTrace = error.stack;
      const timestamp = new Date().toISOString();
      let formattedError = `${stackTrace}`;
      args.forEach((arg) => {
        formattedError = formattedError.replace("%s", arg);
      });
      formattedError = formattedError.replace(/\n/g, "\\n");
      let errorObj = {
        error: {
          timestamp: timestamp,
          error_log: formattedError,
        },
        productType: "oda",
      };
      submitError(errorObj);
    };

    window.onerror = (message, source, lineno, colno, error) => {
      const timestamp = new Date().toISOString();
      let formattedError = `Unhandled Error: ${message} at ${source}: ${lineno}: ${colno} ${error}`;
      formattedError = formattedError.replace(/\n/g, "\\n");
      let errorObj = {
        error: {
          timestamp: timestamp,
          error_log: formattedError,
        },
        productType: "oda",
      };
      submitError(errorObj);
      return true;
    };
  };

  return (
    <div>
      {configs && (
        <Provider store={store}>
          <div className="main-container">
            <BrowserRouter>
              <Navbar />
              <Routes>
                {USER_MANAGEMENT_ENABLED && (
                  <Route
                    exact
                    path="/user-management"
                    element={<UserManagement />}
                  />
                )}
                <Route exact path="/" element={<Dashboard />} />
                <Route
                  exact
                  path="/feature-tracing-table/:projectKey"
                  element={<FeatureTracingTable />}
                />
                <Route
                  exact
                  path="/feature-trace/:previousProjectKey"
                  element={<FeatureTrace />}
                />
                <Route
                  exact
                  path="/job-scheduler/:projectKey/:projVersion/:projFg"
                  element={<JobScheduler />}
                />
                <Route
                  exact
                  path="/playground/:projectKey/:projVersion/:projFg"
                  element={
                    <div className="dndflow" onContextMenu={handleContextMenu}>
                      <ReactFlowProvider>
                        <DnDFlow />
                        <div
                          className={`main-sidebar-container ${
                            isSidebarOpen ? "" : "collapsed"
                          }`}
                        >
                          <TabularSidebar />
                        </div>
                        <div
                          className={`toggle-button ${
                            isSidebarOpen ? "open" : "close"
                          } ${isPlaygroundLoading && "high-z-index"}`}
                          onClick={handleSidebarToggle}
                        >
                          <div className="toggle-button-title">
                            {isSidebarOpen
                              ? "Collapse sidebar"
                              : "Expand sidebar"}
                          </div>
                          {isSidebarOpen ? (
                            <RightArrowIcon></RightArrowIcon>
                          ) : (
                            <LeftArrowIcon></LeftArrowIcon>
                          )}
                        </div>{" "}
                      </ReactFlowProvider>
                    </div>
                  }
                />
              </Routes>
            </BrowserRouter>
          </div>
          <Backdrop
            sx={{
              color: "#69c2d2",
              opacity: 1,
              zIndex: 10001,
              position: "fixed",
              top: 60,
            }}
            open={isLoading || showPollingLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Backdrop
            sx={{
              color: "#69c2d2",
              opacity: 1,
              zIndex: 10001,
              position: "fixed",
              top: 60,
              right: isSidebarOpen ? 360 : 0,
            }}
            open={isPlaygroundLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Provider>
      )}
    </div>
  );
};
export default App;
