import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import "./stopExecutionDialog.scss";

const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "28px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
};

const StopExecutionDialog = ({
  showStopExecutionDialog,
  hideStopExecutionDialog,
  stopPipelineExecution,
}) => {
  const stopExecution = (forceKill) => {
    stopPipelineExecution(forceKill);
    handleClose();
  };

  const handleClose = () => {
    hideStopExecutionDialog();
  };

  return (
    <Dialog
      fullWidth
      open={showStopExecutionDialog}
      aria-labelledby="Delete"
      className="stop-execution-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="title">
        <div>{"Stop Execution"}</div>
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="white-text">
          If you terminate execution forcefully, data may not be saved properly.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={() => stopExecution(true)}
          autoFocus
        >
          Force Kill
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopExecutionDialog;
