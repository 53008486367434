import { React } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./showinfoDialog.scss";
import { IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";

const InfoDialog = ({ infoMessage }) => {
  const isInfoDialogOpen = useSelector((state) => state.isInfoDialogOpen);
  const dispatch = useDispatch();
  const { updateIsOpenInfoDialog } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
  };

  const handleClose = () => {
    updateIsOpenInfoDialog(false);
  };

  return (
    <Dialog
      fullWidth
      open={isInfoDialogOpen}
      aria-labelledby="Delete"
      className="show-info-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="dlg-title">
        {"Information"}
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={() => handleClose()}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="white-text">
          {infoMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
