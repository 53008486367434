import React from "react";
import { Handle, Position } from "reactflow";
import "./featureTraceNode.scss";
import { Tooltip } from "@mui/material";

const FeatureTraceNode = ({ data, isConnectable, id, selectedNodeId }) => {
  return (
    <Tooltip title={data.label} placement="right-end">
      <div
        className={`feature-trace-node ${
          selectedNodeId === id ? "highlighted-node" : ""
        }`}
      >
        <p>{data.label}</p>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: "#555" }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: "#555" }}
          isConnectable={isConnectable}
        />
      </div>
    </Tooltip>
  );
};

export default FeatureTraceNode;
