import React from "react";
import { useSelector } from "react-redux";
import "./logicTrace.scss";
import { Tooltip } from "@mui/material";

const LogicTrace = ({ steps, selectedStepIndex }) => {
  const alertMessage = useSelector((state) => state.alertMessage);

  return (
    <div
      style={{
        height: alertMessage ? "calc(100vh - 160px)" : "calc(100vh - 110px)",
      }}
      className="logic-trace"
    >
      {steps.map((step, index) => {
        const tooltipContent = step.description.split("\n").map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ));
        const hasNewLines = step.description.includes("\n");
        let truncatedDescription = step.description;
        if (hasNewLines) {
          truncatedDescription = step.description
            .split("\n")
            .slice(0, 2)
            .join("\n");
        }
        return (
          <Tooltip
            title={<span>{tooltipContent}</span>}
            placement="left-start"
            key={index}
          >
            <div
              className={`step ${
                selectedStepIndex >= index ? "highlighted-row" : ""
              }`}
            >
              {truncatedDescription}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default LogicTrace;
