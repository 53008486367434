import {
  Button,
  Checkbox,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileUploadIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { actionCreators } from "../../state";
import "./customForm.scss";
import {
  fetchFeatures,
  handleDependentInputs,
  getAutoMlProjectsData,
  getAutoMlProjectsList,
  getAutoMlVersionsList,
} from "utils/utils";
import {
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
  chipTextFieldStyles,
  buttonStyles,
  toggleButtonStyles,
} from "./customFormStyle.jsx";

const CustomForm = ({
  selectedNode,
  connectedDataSources,
  connectedNodes,
  allColumns,
  numColumns,
  catColumns,
  dateColumns,
  mixedColumns,
  commonColumns,
  leftColumns,
  rightColumns,
  isSaveDisabled,
  wordCountMismatch,
  modelSourceOptions,
  updateFileDetails,
  maxColsAllowed,
  featureCategorizationData,
  dbTypeOptions,
  statOptionsData,
  mathOptionsData,
  dateOptionsData,
  aggOptionsData,
  featureCountMismatch,
}) => {
  const [leftDataColumns, setLeftDataColumns] = useState([]);
  const [rightDataColumns, setRightDataColumns] = useState([]);
  const [splitDataSources, setSplitDataSources] = useState([]);
  const [allDataSources, setAllDataSources] = useState([]);
  const [autoMlProjectsData, setAutoMlProjectsData] = useState([]);
  const [autoMlProjectsList, setAutoMlProjectsList] = useState([]);
  const [autoMlVersionsList, setAutoMlVersionsList] = useState([]);
  const inputRef = useRef(null);
  const filteredOptions = useRef({});
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState({});
  let autoCompleteRef = {};
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const modalUI = useSelector((state) => state.modalUI);
  const pipeline = useSelector((state) => state.pipeline);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const pivotStatCfg = useSelector((state) => state.pivotStatCfg);
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateModalUI,
    updateIsLoading,
    updateSnackbarMsg,
    updatePivotStatCfg,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  const pvtStatOptions = [
    {
      id: 1,
      value: "avg",
    },
    {
      id: 2,
      value: "count",
    },
    {
      id: 3,
      value: "first",
    },
    {
      id: 4,
      value: "grouping_id",
    },
    {
      id: 5,
      value: "kurtosis",
    },
    {
      id: 6,
      value: "last",
    },
    {
      id: 7,
      value: "max",
    },
    {
      id: 8,
      value: "min",
    },
    {
      id: 9,
      value: "product",
    },
    {
      id: 10,
      value: "skewness",
    },
    {
      id: 11,
      value: "stddev",
    },
    {
      id: 12,
      value: "stddev_pop",
    },
    {
      id: 13,
      value: "stddev_samp",
    },
    {
      id: 14,
      value: "sum",
    },
    {
      id: 15,
      value: "sum_distinct",
    },
    {
      id: 16,
      value: "var_pop",
    },
    {
      id: 17,
      value: "var_samp",
    },
    {
      id: 18,
      value: "variance",
    },
    {
      id: 19,
      value: "frequency_count",
    },
  ];

  useEffect(() => {
    if (selectedNode.data.nodeType === "pivot") {
      initializeStatCfgForPivot();
    } else if (selectedNode.data.nodeType === "split") {
      const allDataSources = Object.keys(pipeline.clientMetaData.dsMap);
      const prevNodeIndex = allDataSources.indexOf(connectedDataSources[0]);
      if (prevNodeIndex > -1) {
        allDataSources.splice(prevNodeIndex, 1);
      }
      const currentNodeIndex = allDataSources.indexOf(selectedNode.id);
      if (currentNodeIndex > -1) {
        allDataSources.splice(currentNodeIndex, 1);
      }
      setSplitDataSources(allDataSources);
    } else if (selectedNode.data.nodeType === "modelScoring") {
      const allDataSources = Object.keys(pipeline.clientMetaData.dsMap);
      setAllDataSources(allDataSources);
      getAutoMlData();
    }
    initializeFilteredOptionsAndSelectAllCheckboxes();
  }, []);

  const initializeFilteredOptionsAndSelectAllCheckboxes = () => {
    let filteredOpts = {};
    const clonedSelectAllCheckboxes = {};
    modalUI.input.forEach((input) => {
      if (input.type === "chipsDropdown") {
        filteredOpts[input.identifier] = [];
        clonedSelectAllCheckboxes[input.identifier] = false;
      }
    });
    filteredOptions.current = filteredOpts;
    setSelectAllCheckboxes(clonedSelectAllCheckboxes);
  };

  const getPreviousStagedNodeID = (dSource) => {
    const prevNode = connectedNodes.find((node) => node.dSource === dSource);
    if (prevNode?.stageId) {
      return prevNode.stageId;
    }
    return null;
  };

  const createLeftAndRightDataColumns = async (leftDataSource) => {
    const leftArray = [];
    const rightArray = [];
    if (connectedDataSources.length >= 2) {
      const prevStageNode = getPreviousStagedNodeID(leftDataSource);
      const leftFeatures = await fetchFeatures(
        leftDataSource,
        prevStageNode,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!leftFeatures) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      ["derivedFeatures", "rowFeatures"].forEach((obj) => {
        const featureObj = leftFeatures[obj];
        if (featureObj) {
          leftArray.push(...featureObj.categorical);
          leftArray.push(...featureObj.date);
          leftArray.push(...featureObj.mixed);
          leftArray.push(...featureObj.numerical);
        }
      });
      setLeftDataColumns(leftArray);
    } else {
      setLeftDataColumns([]);
    }
    if (connectedDataSources.length === 2) {
      const rightDataSource =
        connectedDataSources[0] === leftDataSource
          ? connectedDataSources[1]
          : connectedDataSources[0];
      const prevStageNode = getPreviousStagedNodeID(rightDataSource);
      const rightFeatures = await fetchFeatures(
        rightDataSource,
        prevStageNode,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!rightFeatures) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      ["derivedFeatures", "rowFeatures"].forEach((obj) => {
        const featureObj = rightFeatures[obj];
        if (featureObj) {
          rightArray.push(...featureObj.categorical);
          rightArray.push(...featureObj.date);
          rightArray.push(...featureObj.mixed);
          rightArray.push(...featureObj.numerical);
        }
      });
      setRightDataColumns(rightArray);
    } else {
      setRightDataColumns([]);
    }
  };

  const initializeStatCfgForPivot = () => {
    if (modalForm.statCfg) {
      updatePivotStatCfg(modalForm.statCfg);
    } else {
      const newPivotStatCfg = structuredClone(pivotStatCfg);
      newPivotStatCfg.push({
        statFuncs: [],
        cols: [],
      });
      if (modalForm.statFunc) {
        newPivotStatCfg[0].statFuncs = Array.isArray(modalForm.statFunc)
          ? modalForm.statFunc
          : [modalForm.statFunc];
      }
      if (modalForm.pivotValueCol) {
        newPivotStatCfg[0].cols = Array.isArray(modalForm.pivotValueCol)
          ? modalForm.pivotValueCol
          : [modalForm.pivotValueCol];
      }
      updatePivotStatCfg(newPivotStatCfg);
    }
  };

  const getAutoMlData = async () => {
    const projectsData = await getAutoMlProjectsData(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    setAutoMlProjectsData(projectsData);
    const projectsList = getAutoMlProjectsList(projectsData);
    setAutoMlProjectsList(projectsList);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" ||
        e.target.name === "fileName" ||
        e.target.name === "opColName" ||
        e.target.name === "featureName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "constDenominator") {
      const regex = /^[0-9\-,.]*$/;
      if (!regex.test(value)) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "padValue") {
      if (!(value.length <= 1 && /^[A-Za-z0-9]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "number" && !/^[0-9]+$/.test(value)) {
      return;
    }
    if (e?.target?.name === "precisionVal") {
      if (value.length > 0 && !/^[0-9,]+$/.test(value)) {
        return;
      }
    }
    if (e?.target?.name === "secCustom") {
      if (value.length > 0 && !/^[0-9\-,.]*$/.test(value)) {
        return;
      }
    }
    const newFormData = structuredClone(modalForm);
    if (e?.target?.type === "number") {
      newFormData[e.target.name] = Number(value);
    } else {
      newFormData[e.target.name] = value;
    }
    updateModalForm(newFormData);
  };

  const handleWordCountChange = (evt) => {
    const value = evt.target.value.split(",");
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = value;
    updateModalForm(newFormData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileRemove = (inputId) => {
    updateFileDetails(null);
    const newFormData = structuredClone(modalForm);
    newFormData[inputId] = "";
    updateModalForm(newFormData);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleFileChange = async (e, inputId, fileDropped = false) => {
    let selectedFile = null;
    if (fileDropped) {
      e.preventDefault();
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }
    updateFileDetails(selectedFile);
    const newFormData = structuredClone(modalForm);
    const newfileDetails = {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
    };
    newFormData[inputId] = newfileDetails;
    updateModalForm(newFormData);
  };

  const handleParentInputChange = (evt, mappedInput) => {
    let newValue;
    if (evt.target.type === "checkbox") {
      if (evt.target.name === "sourceType") {
        newValue = evt.target.checked ? "url" : "file";
      } else {
        newValue = evt.target.checked;
      }
    } else {
      newValue = evt.target.value;
    }
    if (mappedInput && mappedInput.length > 0 && modalUI) {
      const inputs = handleDependentInputs(
        modalUI,
        mappedInput,
        newValue,
        modalForm
      );
      updateModalUI({ ...modalUI, input: inputs });
    }
    const newFormData = structuredClone(modalForm);
    newFormData[evt.target.name] = newValue;
    if (
      selectedNode.data.nodeType === "statisticalTransformation" &&
      evt.target.name === "multCol"
    ) {
      newFormData["statsInfo"] = [];
    }
    updateModalForm(newFormData);
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const handleSortColumn = (newFormData, value) => {
    let i = 0;
    if (selectedNode.data.nodeType === "sorting") {
      while (i < modalForm["orderByCol"].length && i < value.length) {
        if (modalForm["orderByCol"][i] !== value[i]) {
          break;
        }
        i++;
      }
    } else if (
      selectedNode.data.nodeType === "performanceWindow" ||
      selectedNode.data.nodeType === "fill"
    ) {
      while (i < modalForm["sortCol"].length && i < value.length) {
        if (modalForm["sortCol"][i] !== value[i]) {
          break;
        }
        i++;
      }
    }
    newFormData["sortOrder"].splice(i, 1);
    return newFormData;
  };

  const handleSortOrder = (elementAdded, newFormData, value) => {
    if (!newFormData.sortOrder) {
      newFormData["sortOrder"] = [];
    }
    if (elementAdded) {
      newFormData["sortOrder"].push("ascending");
    } else {
      newFormData = handleSortColumn(newFormData, value);
    }
    return newFormData;
  };

  const handleChipsChange = (e, value, input) => {
    let inputId = input.identifier;
    let newFormData = structuredClone(modalForm);
    if (Array.isArray(value)) {
      if (checkIfMaxColsFilled(newFormData, input, inputId, value)) {
        return;
      }
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
      if (inputId !== "typeFormat" && inputId !== "sortOrder") {
        for (let index = 0; index < value.length - 1; index++) {
          if (value[index] === value[value.length - 1]) return;
        }
      }
    }
    if (
      selectedNode.data.nodeType === "sorting" ||
      ((selectedNode.data.nodeType === "fill" ||
        selectedNode.data.nodeType === "performanceWindow") &&
        inputId === "sortCol")
    ) {
      newFormData = handleSortOrder(e.target.outerText, newFormData, value);
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else if (value?.name) {
        value = value?.name;
      } else {
        value = value?.value;
      }
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const checkIfMaxColsFilled = (newFormData, input, inputId, value) => {
    if (
      !selectedNode.data.isStage ||
      !maxColsAllowed ||
      !input.showColumns ||
      value.length <= maxColsAllowed
    ) {
      return false;
    }
    if (
      Array.isArray(newFormData[inputId]) &&
      newFormData[inputId].length > value.length
    ) {
      return false;
    }
    return true;
  };

  const handlePivotStatCfgChange = (e, value, inputId, index) => {
    const newPivotStatCfg = structuredClone(pivotStatCfg);
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    newPivotStatCfg[index][inputId] = value;
    updatePivotStatCfg(newPivotStatCfg);
  };

  const handleSelectChange = (e, value, inputId, mappedInput = null) => {
    const newFormData = structuredClone(modalForm);
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    if (
      inputId === "dbType" &&
      value === "bigquery" &&
      !newFormData["authType"]
    ) {
      newFormData["authType"] = "none";
    }
    if (mappedInput && mappedInput.length > 0 && modalUI) {
      const inputs = handleDependentInputs(
        modalUI,
        mappedInput,
        value,
        newFormData
      );
      updateModalUI({ ...modalUI, input: inputs });
    }
    if (inputId === "leftData") {
      createLeftAndRightDataColumns(value);
      newFormData.leftDataCols = [];
      newFormData.rightDataCols = [];
    }
    if (inputId === "autoMlProjectKey") {
      handleAutoMlProjectChange(value);
    }
    if (inputId === "statsInfo") {
      value = [value];
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const handleAutoMlProjectChange = (autoMlProjectKey) => {
    const versionsList = getAutoMlVersionsList(
      autoMlProjectKey,
      autoMlProjectsData
    );
    setAutoMlVersionsList(versionsList);
    setAutoMlVersionToEmptyIfNotFound(versionsList);
  };

  const setAutoMlVersionToEmptyIfNotFound = (verisons) => {
    for (let i = 0; i < verisons.length; i++) {
      if (verisons[i].vname === modalForm?.autoMlProjVersion) {
        return;
      }
    }
    const newFormData = structuredClone(modalForm);
    newFormData["autoMlProjVersion"] = "";
    updateModalForm(newFormData);
  };

  const handleInputInSelectChange = (
    e,
    value,
    inputId,
    isInputEvent = false
  ) => {
    const newFormData = structuredClone(modalForm);
    if (isInputEvent) {
      value = e.target.value;
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const isOptionEqualToValue = (option, value, options, input = {}) => {
    if (
      input.identifier === "leftData" ||
      input.identifier === "perWinDs" ||
      input.identifier === "excluDs" ||
      (input.identifier === "dSource" &&
        selectedNode.data.nodeType === "modelScoring")
    ) {
      return pipeline.clientMetaData.dsMap[option] === value || value === "";
    }
    let nameOptions = [];
    let valueOptions = [];
    if (options[0]?.name) {
      nameOptions = options.map((opt) => opt.name);
    }
    if (options[0]?.value) {
      valueOptions = options.map((opt) => opt.value);
    }
    if (
      nameOptions.includes(value) ||
      valueOptions.includes(value) ||
      options.includes(value)
    ) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const groupedChipOptions = (columns) => {
    let groupedOptions = [];
    const num = columns.filter((col) => numColumns.includes(col));
    const cat = columns.filter((col) => catColumns.includes(col));
    const dat = columns.filter((col) => dateColumns.includes(col));
    const mixed = columns.filter((col) => mixedColumns.includes(col));

    num.forEach((col) => {
      let obj = { item: col, title: "Numerical" };
      groupedOptions.push(obj);
    });
    cat.forEach((col) => {
      let obj = { item: col, title: "Categorical" };
      groupedOptions.push(obj);
    });
    dat.forEach((col) => {
      let obj = { item: col, title: "Date" };
      groupedOptions.push(obj);
    });
    mixed.forEach((col) => {
      let obj = { item: col, title: "Mixed" };
      groupedOptions.push(obj);
    });
    return groupedOptions;
  };

  const getOptions = (input) => {
    try {
      if (input.identifier === "leftData" || input.identifier === "perWinDs") {
        return connectedDataSources;
      } else if (input.identifier === "excluDs") {
        return splitDataSources;
      } else if (
        input.identifier === "dSource" &&
        selectedNode.data.nodeType === "modelScoring"
      ) {
        return allDataSources;
      } else if (input.identifier === "modelSource") {
        return modelSourceOptions;
      } else if (input.identifier === "category") {
        return featureCategorizationData;
      } else if (input.identifier === "autoMlProjectKey") {
        return autoMlProjectsList;
      } else if (input.identifier === "autoMlProjVersion") {
        return autoMlVersionsList;
      } else if (input.showColumns && input.showAllCols) {
        return groupedChipOptions(allColumns);
      } else if (input.showColumns && input.showCommonCols) {
        return groupedChipOptions(commonColumns);
      } else if (input.showColumns && input.showNumCols) {
        return numColumns;
      } else if (input.showColumns && input.showCatCols) {
        return catColumns;
      } else if (input.showColumns && input.showDateCols) {
        return dateColumns;
      } else if (input.identifier === "leftDataCols") {
        if (leftDataColumns.length) {
          return groupedChipOptions(leftDataColumns);
        } else {
          return groupedChipOptions(leftColumns);
        }
      } else if (input.identifier === "rightDataCols") {
        if (rightDataColumns.length) {
          return groupedChipOptions(rightDataColumns);
        } else {
          return groupedChipOptions(rightColumns);
        }
      } else if (
        input.identifier === "dbType" &&
        (selectedNode.data.nodeType === "database" ||
          selectedNode.data.nodeType === "output") &&
        dbTypeOptions.length > 0
      ) {
        return dbTypeOptions;
      } else if (
        input.identifier === "statsInfo" &&
        selectedNode.data.nodeType === "statisticalTransformation"
      ) {
        if (
          modalForm.multCol === "yes" &&
          statOptionsData?.statsInfoYes?.options?.length > 0
        ) {
          return statOptionsData.statsInfoYes.options;
        } else if (
          modalForm.multCol === "no" &&
          statOptionsData?.statsInfoNo?.options?.length > 0
        ) {
          return statOptionsData.statsInfoNo.options;
        } else {
          return input.options;
        }
      } else if (
        selectedNode.data.nodeType === "mathematicalFunctions" &&
        input.identifier === "operators" &&
        mathOptionsData?.operators?.options?.length > 0
      ) {
        return mathOptionsData.operators.options;
      } else if (
        selectedNode.data.nodeType === "aggregation" &&
        input.identifier === "stats" &&
        aggOptionsData?.stats?.options?.length > 0
      ) {
        return aggOptionsData.stats.options;
      } else if (selectedNode.data.nodeType === "dateOperations") {
        if (
          input.identifier === "diffOp" &&
          dateOptionsData?.diffOp?.options?.length > 0
        ) {
          return dateOptionsData.diffOp.options;
        } else if (
          input.identifier === "addOp" &&
          dateOptionsData?.addOp?.options?.length > 0
        ) {
          return dateOptionsData.addOp.options;
        } else if (
          input.identifier === "dateFunctions" &&
          dateOptionsData?.dateFunctions?.options?.length > 0
        ) {
          return dateOptionsData?.dateFunctions?.options;
        } else {
          return input.options;
        }
      } else {
        return input.options;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOptionLabel = (input, option) => {
    try {
      if (input.identifier === "leftData" || input.identifier === "perWinDs") {
        if (connectedDataSources.includes(option)) {
          return pipeline.clientMetaData.dsMap[option];
        } else {
          return option;
        }
      } else if (input.identifier === "excluDs") {
        if (splitDataSources.includes(option)) {
          return pipeline.clientMetaData.dsMap[option];
        } else {
          return option;
        }
      } else if (
        input.identifier === "dSource" &&
        selectedNode.data.nodeType === "modelScoring"
      ) {
        if (allDataSources.includes(option)) {
          return pipeline.clientMetaData.dsMap[option];
        } else {
          return option;
        }
      }
      if (
        input.identifier === "leftDataCols" ||
        input.identifier === "rightDataCols" ||
        input.showAllCols ||
        input.showCommonCols
      ) {
        if (option.item) {
          return option.item;
        } else {
          return option;
        }
      } else if (option.length === 0) {
        return "";
      } else if (typeof option === "object") {
        if (option.name) {
          return option.name;
        } else if (option.item) {
          return option.item;
        }
        return option.value;
      }
      return option;
    } catch (error) {
      console.error(error);
    }
  };

  const getSelectedValueLabel = (input) => {
    try {
      if (!modalForm || !modalForm[input.identifier]) {
        return "";
      }
      const value = modalForm[input.identifier];
      if (
        selectedNode.data.nodeType === "statisticalTransformation" &&
        modalForm.multCol === "yes" &&
        statOptionsData?.statsInfoYes?.options?.length > 0
      ) {
        return getNameFromValue(value, statOptionsData.statsInfoYes.options);
      } else if (
        selectedNode.data.nodeType === "statisticalTransformation" &&
        modalForm.multCol === "no" &&
        statOptionsData?.statsInfoNo?.options?.length > 0
      ) {
        return getNameFromValue(value, statOptionsData.statsInfoNo.options);
      } else if (
        selectedNode.data.nodeType === "mathematicalFunctions" &&
        mathOptionsData?.operators?.options?.length > 0
      ) {
        return getNameFromValue(value, mathOptionsData.operators.options);
      } else if (
        selectedNode.data.nodeType === "aggregation" &&
        aggOptionsData?.stats?.options?.length > 0
      ) {
        return getNameFromValue(value, aggOptionsData.stats.options);
      } else if (
        selectedNode.data.nodeType === "dateOperations" &&
        input.identifier === "diffOp" &&
        dateOptionsData?.diffOp?.options?.length > 0
      ) {
        return getNameFromValue(value, dateOptionsData.diffOp.options);
      } else if (
        selectedNode.data.nodeType === "dateOperations" &&
        input.identifier === "addOp" &&
        dateOptionsData?.addOp?.options?.length > 0
      ) {
        return getNameFromValue(value, dateOptionsData.addOp.options);
      } else if (
        selectedNode.data.nodeType === "dateOperations" &&
        input.identifier === "dateFunctions" &&
        dateOptionsData?.dateFunctions?.options?.length > 0
      ) {
        return getNameFromValue(value, dateOptionsData.dateFunctions.options);
      } else if (
        input.identifier === "leftDataCols" ||
        input.identifier === "rightDataCols" ||
        input.showAllCols ||
        input.showCommonCols
      ) {
        return value;
      } else if (input.identifier === "statsInfo" && input.type === "select") {
        return Array.isArray(value) ? value[0] : value;
      } else if (
        input.identifier === "leftData" ||
        input.identifier === "perWinDs" ||
        input.identifier === "excluDs" ||
        (input.identifier === "dSource" &&
          selectedNode.data.nodeType === "modelScoring")
      ) {
        return pipeline.clientMetaData.dsMap[value];
      } else if (input.identifier === "modelSource") {
        return getNameFromValue(value, modelSourceOptions);
      } else if (input.identifier === "autoMlProjectKey") {
        return getNameFromValue(value, autoMlProjectsList);
      } else if (
        input.identifier === "dbType" &&
        (selectedNode.data.nodeType === "database" ||
          selectedNode.data.nodeType === "output") &&
        dbTypeOptions.length > 0
      ) {
        return getNameFromValue(value, dbTypeOptions);
      } else if (input.identifier === "autoMlProjVersion") {
        return getNameFromValue(value, autoMlVersionsList);
      } else {
        const options = input.options;
        if (!options || !Array.isArray(options)) {
          return value;
        } else if (typeof options[0] !== "object") {
          return value;
        } else {
          return getNameFromValue(value, options);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNameFromValue = (value, options) => {
    const option = options.find(
      (option) => option.value === value || option.value === value[0]
    );
    return option ? option.name : value;
  };

  const isEmptyDivRequired = (input) => {
    if (
      selectedNode.data.nodeType === "join" &&
      connectedDataSources.length > 2 &&
      (input.identifier === "rightDataColKind" ||
        input.identifier === "rightDataCols")
    ) {
      return true;
    } else if (
      selectedNode.data.nodeType === "multiStack" &&
      (input.identifier === "colSelInfo" || input.identifier === "colList") &&
      !(
        (modalForm?.sourceType === "db" &&
          (modalForm?.rowSelInfo === "all" ||
            modalForm?.rowSelInfo === "duration")) ||
        (input.identifier === "colList" && modalForm?.colSelInfo !== "partial")
      )
    ) {
      return true;
    } else if (
      selectedNode.data.nodeType === "multiStack" &&
      input.identifier === "sourceType"
    ) {
      return true;
    } else if (
      selectedNode.data.nodeType === "modelScoring" &&
      ((input.identifier === "modelSource" &&
        pipeline?.mlNodes.includes(connectedDataSources[0])) ||
        (input.identifier === "dSource" &&
          !pipeline?.mlNodes.includes(connectedDataSources[0])))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showGroupBy = (input) => {
    return (
      (input.showColumns && input.showAllCols) ||
      (input.showColumns && input.showCommonCols) ||
      input.identifier === "leftDataCols" ||
      input.identifier === "rightDataCols"
    );
  };

  const getChipDropdownValue = (inputId) => {
    if (modalForm && modalForm[inputId]) {
      if (typeof modalForm[inputId] === "string") {
        return [modalForm[inputId]];
      } else {
        return modalForm[inputId];
      }
    } else {
      return [];
    }
  };

  const handleAddStatFunc = () => {
    const newPivotStatCfg = structuredClone(pivotStatCfg);
    newPivotStatCfg.push({
      statFuncs: [],
      cols: [],
    });
    updatePivotStatCfg(newPivotStatCfg);
  };

  const handleRemovePivotStatCfg = (index) => {
    const newPivotStatCfg = structuredClone(pivotStatCfg);
    newPivotStatCfg.splice(index, 1);
    updatePivotStatCfg(newPivotStatCfg);
  };

  const getColumns = (input) => {
    if (
      selectedNode.data.nodeType === "statisticalTransformation" &&
      modalForm.multCol === "no" &&
      input.identifier === "statsInfo" &&
      statOptionsData?.statsInfoNo?.options?.length > 0
    ) {
      return statOptionsData?.statsInfoNo?.options;
    } else if (
      selectedNode.data.nodeType === "aggregation" &&
      input.identifier === "stats" &&
      aggOptionsData?.stats?.options?.length > 0
    ) {
      return aggOptionsData?.stats?.options;
    } else if (
      selectedNode.data.nodeType === "dateOperations" &&
      input.identifier === "dateFunctions" &&
      dateOptionsData?.dateFunctions?.options?.length > 0
    ) {
      return dateOptionsData?.dateFunctions?.options;
    } else if (input.showColumns && input.showAllCols) {
      return allColumns;
    } else if (input.showColumns && input.showCommonCols) {
      return commonColumns;
    } else if (input.showColumns && input.showNumCols) {
      return numColumns;
    } else if (input.showColumns && input.showCatCols) {
      return catColumns;
    } else if (input.showColumns && input.showDateCols) {
      return dateColumns;
    } else if (input.identifier === "category") {
      return featureCategorizationData;
    } else if (input.identifier === "leftDataCols") {
      if (leftDataColumns.length) {
        return leftDataColumns;
      } else {
        return leftColumns;
      }
    } else if (input.identifier === "rightDataCols") {
      if (rightDataColumns.length) {
        return rightDataColumns;
      } else {
        return rightColumns;
      }
    } else {
      return input.options;
    }
  };

  const updateMultiSelectValue = (opts, newModalForm, inputId) => {
    if (Array.isArray(newModalForm[inputId]) && newModalForm[inputId].length) {
      opts.forEach((opt) => {
        if (!newModalForm[inputId].includes(opt)) {
          newModalForm[inputId].push(opt);
          if (
            (selectedNode.data.nodeType === "sorting" &&
              inputId === "orderByCol") ||
            ((selectedNode.data.nodeType === "performanceWindow" ||
              selectedNode.data.nodeType === "fill") &&
              inputId === "sortCol")
          ) {
            if (!newModalForm.sortOrder) {
              newModalForm["sortOrder"] = [];
            }
            newModalForm["sortOrder"].push("ascending");
          }
        }
      });
    } else {
      newModalForm[inputId] = opts;
      if (
        (selectedNode.data.nodeType === "sorting" &&
          inputId === "orderByCol") ||
        ((selectedNode.data.nodeType === "performanceWindow" ||
          selectedNode.data.nodeType === "fill") &&
          inputId === "sortCol")
      ) {
        if (!newModalForm.sortOrder) {
          newModalForm["sortOrder"] = [];
        }
        opts.forEach((opt) => {
          newModalForm["sortOrder"].push("ascending");
        });
      }
    }
    return newModalForm;
  };

  const handleCheckboxChange = (evt, input) => {
    const newModalForm = structuredClone(modalForm);
    if (input.identifier === "backPropagation") {
      newModalForm[evt.target.name] = evt.target.checked ? "yes" : "no";
    } else {
      newModalForm[evt.target.name] = evt.target.checked;
    }
    updateModalForm(newModalForm);
  };

  const handleSelectAllCheckboxChange = (evt, autoCompleteRef, input) => {
    let newModalForm = structuredClone(modalForm);
    if (evt.target.checked) {
      if (filteredOptions.current[input.identifier].length) {
        const filteredOptVal = filteredOptions.current[input.identifier].map(
          (opt) => {
            if (opt.item) {
              return opt.item;
            } else if (opt.value) {
              return opt.value;
            }
            return opt;
          }
        );
        newModalForm = updateMultiSelectValue(
          filteredOptVal,
          newModalForm,
          input.identifier
        );
      } else {
        const allOptions = getColumns(input);
        let optionsVal = [];
        if (allOptions[0]?.value) {
          optionsVal = allOptions.map((opt) => opt.value);
        } else {
          optionsVal = allOptions;
        }
        newModalForm = updateMultiSelectValue(
          optionsVal,
          newModalForm,
          input.identifier
        );
      }
      autoCompleteRef[input.identifier].focus();
    } else {
      newModalForm[input.identifier] = [];
      if (
        (selectedNode.data.nodeType === "sorting" &&
          input.identifier === "orderByCol") ||
        ((selectedNode.data.nodeType === "performanceWindow" ||
          selectedNode.data.nodeType === "fill") &&
          input.identifier === "sortCol")
      ) {
        newModalForm["sortOrder"] = [];
      }
      filteredOptions.current[input.identifier] = [];
    }
    if (
      selectedNode.data.isStage &&
      maxColsAllowed &&
      input.showColumns &&
      newModalForm[input.identifier].length > maxColsAllowed
    ) {
      let colsArray = newModalForm[input.identifier];
      colsArray = colsArray.slice(0, maxColsAllowed);
      newModalForm[input.identifier] = colsArray;
      if (
        (selectedNode.data.nodeType === "sorting" &&
          input.identifier === "orderByCol") ||
        ((selectedNode.data.nodeType === "performanceWindow" ||
          selectedNode.data.nodeType === "fill") &&
          input.identifier === "sortCol")
      ) {
        let sortOrder = newModalForm["sortOrder"];
        sortOrder = sortOrder.slice(0, maxColsAllowed);
        newModalForm["sortOrder"] = sortOrder;
      }
    } else {
      const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
      clonedSelectAllCheckboxes[input.identifier] = evt.target.checked;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
    updateModalForm(newModalForm);
  };

  const updateFilteredOptions = (filteredOpt, inputId) => {
    filteredOptions.current[inputId] = filteredOpt;
    const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
    if (clonedSelectAllCheckboxes[inputId]) {
      clonedSelectAllCheckboxes[inputId] = false;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
  };

  const filterMultiSelectOptions = (options, inputValue, input) => {
    if (options.length) {
      const filteredOpts = options.filter((option) => {
        if (option.item) {
          return option.item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (option.value) {
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return option.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      updateFilteredOptions(filteredOpts, input.identifier);
      return filteredOpts;
    }
    return [];
  };

  const getError = (input) => {
    if (!isSaveDisabled) return false;
    if (
      featureCountMismatch &&
      (input.identifier === "secFeature" ||
        input.identifier === "denominator" ||
        input.identifier === "secCustom" ||
        input.identifier === "constDenominator")
    )
      return true;
    if (
      wordCountMismatch &&
      (((selectedNode.data.nodeType === "rename" ||
        selectedNode.data.nodeType === "missingImputation") &&
        input.identifier === "newCols") ||
        (selectedNode.data.nodeType === "replaceValues" &&
          input.identifier === "opList"))
    )
      return true;
  };

  const getHelperText = (input) => {
    if (!isSaveDisabled) return "";
    if (
      featureCountMismatch &&
      (input.identifier === "secFeature" ||
        input.identifier === "denominator" ||
        input.identifier === "secCustom" ||
        input.identifier === "constDenominator")
    )
      return "The length of both the features must be equal";
    if (
      wordCountMismatch &&
      (((selectedNode.data.nodeType === "rename" ||
        selectedNode.data.nodeType === "missingImputation") &&
        input.identifier === "newCols") ||
        (selectedNode.data.nodeType === "replaceValues" &&
          input.identifier === "opList"))
    )
      return "Please write number of comma seprated values equal to above field";
    return "";
  };

  return (
    <form>
      <Stack spacing={4} alignItems="stretch">
        {modalUI.input.map((input) =>
          isEmptyDivRequired(input) ? (
            <div style={{ marginTop: "0px" }} key={input.identifier}></div>
          ) : input.type === "select" && !input.hidden ? (
            <Autocomplete
              key={input.identifier}
              autoHighlight
              style={{ marginTop: "15px" }}
              size="small"
              id="tags-outlined"
              options={getOptions(input)}
              getOptionLabel={(option) => getOptionLabel(input, option)}
              autoComplete
              includeInputInList
              disableClearable={input.identifier !== "authentication"}
              className="select-dropdown"
              ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
              isOptionEqualToValue={(option, value) => {
                const options =
                  input.identifier === "leftData" ||
                  input.identifier === "perWinDs"
                    ? connectedDataSources
                    : input.identifier === "excluDs"
                    ? splitDataSources
                    : input.identifier === "dSource" &&
                      selectedNode.data.nodeType === "modelScoring"
                    ? allDataSources
                    : input.identifier === "modelSource"
                    ? modelSourceOptions
                    : input.identifier === "autoMlProjectKey"
                    ? autoMlProjectsList
                    : input.identifier === "autoMlProjVersion"
                    ? autoMlVersionsList
                    : input.showColumns && input.showAllCols
                    ? allColumns
                    : input.showColumns && input.showCommonCols
                    ? commonColumns
                    : input.showColumns && input.showNumCols
                    ? numColumns
                    : input.showColumns && input.showCatCols
                    ? catColumns
                    : input.showColumns && input.showDateCols
                    ? dateColumns
                    : input.identifier === "dbType" &&
                      (selectedNode.data.nodeType === "database" ||
                        selectedNode.data.nodeType === "output") &&
                      dbTypeOptions.length > 0
                    ? dbTypeOptions
                    : selectedNode.data.nodeType ===
                        "statisticalTransformation" &&
                      modalForm.multCol === "yes" &&
                      statOptionsData?.statsInfoYes?.options?.length > 0
                    ? statOptionsData.statsInfoYes.options
                    : selectedNode.data.nodeType === "mathematicalFunctions" &&
                      mathOptionsData?.operators?.options?.length > 0
                    ? mathOptionsData.operators.options
                    : selectedNode.data.nodeType === "dateOperations" &&
                      input.identifier === "diffOp" &&
                      dateOptionsData?.diffOp?.options?.length > 0
                    ? dateOptionsData.diffOp.options
                    : selectedNode.data.nodeType === "dateOperations" &&
                      input.identifier === "addOp" &&
                      dateOptionsData?.addOp?.options?.length > 0
                    ? dateOptionsData.addOp.options
                    : selectedNode.data.nodeType === "dateOperations" &&
                      input.identifier === "dateFunctions" &&
                      dateOptionsData?.dateFunctions?.options?.length > 0
                    ? dateOptionsData.dateFunctions.options
                    : input.options;
                return isOptionEqualToValue(option, value, options, input);
              }}
              value={getSelectedValueLabel(input)}
              onChange={(e, value) => {
                handleSelectChange(
                  e,
                  value,
                  input.identifier,
                  input.mappedWith
                );
              }}
              renderTags={renderTags}
              groupBy={(option) => option.title}
              renderGroup={(params) => {
                return (
                  <li key={params.key}>
                    <GroupHeader
                      padding={showGroupBy(input) ? "4px 10px" : "0px"}
                    >
                      {params.group}
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                );
              }}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <Tooltip title={getSelectedValueLabel(input)} placement="right">
                  <TextField
                    {...params}
                    size="small"
                    label={input.name}
                    placeholder="Select option"
                    autoComplete="off"
                    sx={textFieldStyles}
                  />
                </Tooltip>
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Tooltip
                    title={getOptionLabel(input, option)}
                    placement="right"
                  >
                    <Typography noWrap>
                      {getOptionLabel(input, option)}
                    </Typography>
                  </Tooltip>
                </li>
              )}
            />
          ) : input.type === "radio" &&
            !input.hidden &&
            !(
              input.identifier === "rightDataColKind" &&
              connectedDataSources.length > 2 &&
              selectedNode.data.nodeType === "join"
            ) ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              sx={{ marginTop: "15px !important" }}
            >
              <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
                {input.name}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name={input.identifier}
                value={modalForm[input.identifier]}
                onChange={(e) => handleParentInputChange(e, input.mappedWith)}
              >
                {input.radios.map((radio, radioIndex) => (
                  <FormControlLabel
                    key={input.identifier + "radio-" + radioIndex}
                    value={radio.value}
                    control={<Radio style={{ color: "#69C2D2" }} />}
                    label={radio.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : input.type === "inputWithSelect" && !input.hidden ? (
            <Stack
              direction="row"
              gap="10px"
              alignItems="center"
              key={input.identifier}
              className="select-container"
            >
              {input.input.map((inputWithSelect) =>
                inputWithSelect.type !== "select" ? (
                  <FormControl
                    key={inputWithSelect.identifier}
                    id={inputWithSelect.identifier}
                    size="small"
                  >
                    <TextField
                      type={inputWithSelect.type}
                      name={inputWithSelect.identifier}
                      label={inputWithSelect.name}
                      size="small"
                      autoComplete="off"
                      value={modalForm[inputWithSelect.identifier]}
                      onChange={handleChange}
                      variant="outlined"
                      sx={textFieldStyles}
                      required
                    />
                  </FormControl>
                ) : (
                  <FormControl
                    key={inputWithSelect.identifier}
                    id={inputWithSelect.identifier}
                    sx={formControlStyles}
                    className="w-100"
                    size="small"
                  >
                    <InputLabel sx={labelStyles}>
                      {inputWithSelect.name}
                    </InputLabel>
                    <Select
                      placeholder="Select option"
                      name={inputWithSelect.identifier}
                      label={inputWithSelect.name}
                      size="small"
                      value={modalForm[inputWithSelect.identifier]}
                      onChange={handleChange}
                      required
                      sx={selectStyles}
                      inputProps={{ sx: selectStyles }}
                      MenuProps={{ sx: selectStyles }}
                      autoWidth={true}
                    >
                      {inputWithSelect.options.map((option, optionIndex) => (
                        <MenuItem
                          key={
                            inputWithSelect.identifier + "option-" + optionIndex
                          }
                          value={option.value}
                          sx={menuItemStyles}
                        >
                          <Tooltip title={option.name} placement="right">
                            <Typography noWrap>{option.name}</Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              )}
            </Stack>
          ) : input.type === "chipsDropdown" &&
            !input.hidden &&
            (input.identifier === "sortOrder" ||
              input.identifier === "typeFormat") ? (
            <Autocomplete
              multiple
              id="multiple-selection-autocomplete"
              className="chips-dropdown"
              options={input.options}
              value={getChipDropdownValue(input.identifier)}
              onChange={(e, value) => {
                handleChipsChange(e, value, input);
              }}
              getOptionLabel={(option) => getOptionLabel(input, option)}
              renderTags={renderTags}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={input.name}
                  placeholder={input.name}
                  autoComplete="off"
                  sx={chipTextFieldStyles}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Tooltip
                    title={getOptionLabel(input, option)}
                    placement="right"
                  >
                    <Typography noWrap>
                      {getOptionLabel(input, option)}
                    </Typography>
                  </Tooltip>
                </li>
              )}
            />
          ) : input.type === "chipsDropdown" && !input.hidden ? (
            <Stack direction="row" gap="20px">
              <Autocomplete
                key={input.identifier}
                multiple
                autoHighlight
                size="small"
                id={input.identifier}
                options={getOptions(input)}
                getOptionLabel={(option) => getOptionLabel(input, option)}
                filterSelectedOptions
                disableCloseOnSelect
                className="chips-dropdown"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  const options = getColumns(input);
                  return isOptionEqualToValue(option, value, options);
                }}
                value={getChipDropdownValue(input.identifier)}
                onChange={(e, value) => {
                  handleChipsChange(e, value, input);
                }}
                renderTags={renderTags}
                groupBy={(option) => option.title}
                renderGroup={(params) => {
                  return (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  );
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={input.name}
                    placeholder={input.name}
                    autoComplete="off"
                    sx={chipTextFieldStyles}
                    error={getError(input)}
                    helperText={getHelperText(input)}
                    inputRef={(input) => {
                      if (input) {
                        autoCompleteRef[input.id] = input;
                      }
                    }}
                  />
                )}
                filterOptions={(options, { inputValue }) =>
                  filterMultiSelectOptions(options, inputValue, input)
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip
                      title={getOptionLabel(input, option)}
                      placement="right"
                    >
                      <Typography noWrap>
                        {getOptionLabel(input, option)}
                      </Typography>
                    </Tooltip>
                  </li>
                )}
              />
              {input.identifier !== "category" && (
                <FormControlLabel
                  className="checkbox-label"
                  key={"select-all-" + input.identifier}
                  control={
                    <Checkbox
                      sx={{
                        color: "#7ec0d0",
                        "&.Mui-checked": {
                          color: "#7ec0d0",
                        },
                      }}
                      checked={
                        selectAllCheckboxes &&
                        selectAllCheckboxes[input.identifier]
                          ? true
                          : false
                      }
                      onChange={(evt) =>
                        handleSelectAllCheckboxChange(
                          evt,
                          autoCompleteRef,
                          input
                        )
                      }
                    />
                  }
                  sx={{
                    height: "40px",
                  }}
                  label="Select all"
                />
              )}
            </Stack>
          ) : input.type === "inputInSelect" && !input.hidden ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="text-field"
              size="small"
            >
              <Autocomplete
                freeSolo
                options={input.options}
                getOptionLabel={(option) => option}
                value={modalForm[input.identifier]}
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                onChange={(e, value) =>
                  handleInputInSelectChange(e, value, input.identifier)
                }
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={input.name}
                    placeholder={input.name}
                    sx={textFieldStyles}
                    error={getError(input)}
                    helperText={getHelperText(input)}
                    autoComplete="off"
                    onChange={(e) =>
                      handleInputInSelectChange(e, "", input.identifier, true)
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={option} placement="right">
                      <Typography noWrap>{option}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            </FormControl>
          ) : input.type === "countedWordsInput" && !input.hidden ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="text-field"
              size="small"
            >
              <TextField
                type={input.type}
                name={input.identifier}
                size="small"
                label={input.name}
                value={modalForm[input.identifier]}
                onChange={(evt) => handleWordCountChange(evt)}
                error={getError(input)}
                helperText={getHelperText(input)}
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyles}
                required
              />
            </FormControl>
          ) : input.type === "checkbox" && !input.hidden ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="text-field checkbox-select"
              size="small"
            >
              <Stack sx={labelStyles}>{input.name}</Stack>
              <Checkbox
                sx={{
                  color: "#7ec0d0",
                  "&.Mui-checked": {
                    color: "#7ec0d0",
                  },
                }}
                name={input.identifier}
                checked={modalForm[input.identifier] === "yes"}
                onChange={(evt) => handleCheckboxChange(evt, input)}
              />
            </FormControl>
          ) : input.type === "file" && !input.hidden ? (
            <Box sx={{ marginTop: "0px !important" }} key={input.identifier}>
              <input
                type="file"
                onChange={(evt) => handleFileChange(evt, input.identifier)}
                onClick={onInputClick}
                className="file-upload-input"
                ref={inputRef}
              />
              <div
                className="select-file-button"
                onClick={() => inputRef.current.click()}
              >
                <FileUploadIcon className="file-upload-icon"></FileUploadIcon>
                <span>
                  {selectedNode.data.nodeType === "database"
                    ? "Upload Credentials File"
                    : "Select File"}
                </span>
              </div>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="file-upload-area"
                onDragOver={handleDragOver}
                onDrop={(evt) => handleFileChange(evt, input.identifier, true)}
              >
                <Stack gap="10px">
                  <Stack direction="row" gap="5px">
                    <p>
                      {modalForm[input.identifier]
                        ? modalForm[input.identifier].name
                        : "Drop your files here"}
                    </p>
                    <Tooltip title="Remove file" placement="top">
                      {modalForm[input.identifier] ? (
                        <Box
                          className="remove-file-icon"
                          onClick={(evt) => handleFileRemove(input.identifier)}
                        />
                      ) : (
                        <Box />
                      )}
                    </Tooltip>
                  </Stack>
                </Stack>
                {modalForm[input.identifier] && (
                  <p>{modalForm[input.identifier].size} bytes</p>
                )}
              </Stack>
            </Box>
          ) : input.type === "addMoreStatCfg" && !input.hidden ? (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className="add-filter-btn-container"
              sx={{ marginTop: "1rem" }}
            >
              <Button
                variant="contained"
                onClick={handleAddStatFunc}
                aria-labelledby="row-toggle-button-label"
                name="filter-toggle"
                sx={buttonStyles}
              >
                Add more
              </Button>
            </Stack>
          ) : !input.hidden && input.type === "toggle" ? (
            <FormControl
              key={input.identifier}
              id={input.identifier}
              className="toggle-input"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch
                  onChange={(e) => handleParentInputChange(e, input.mappedWith)}
                  aria-labelledby="row-toggle-button-label"
                  name={input.identifier}
                  sx={toggleButtonStyles}
                  checked={
                    modalForm &&
                    modalForm[input.identifier] &&
                    (modalForm[input?.identifier] !== "" ||
                      modalForm[input?.identifier] !== false)
                      ? true
                      : false
                  }
                />
                <Typography>{input.name}</Typography>
              </Stack>
            </FormControl>
          ) : (
            !input.hidden && (
              <FormControl
                key={input.identifier}
                id={input.identifier}
                className="text-field"
                size="small"
              >
                <TextField
                  type={input.type}
                  name={input.identifier}
                  size="small"
                  label={input.name}
                  value={modalForm[input.identifier]}
                  onChange={handleChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  error={getError(input)}
                  helperText={getHelperText(input)}
                  autoComplete={
                    input.type === "password" ? "new-password" : "off"
                  }
                  required={
                    !(
                      (input.identifier === "dbTable" &&
                        modalForm.rowSelInfo === "query") ||
                      input.identifier === "description" ||
                      (input.identifier === "name" && selectedNode.data.isStage)
                    )
                  }
                />
              </FormControl>
            )
          )
        )}
        {selectedNode.data.nodeType === "pivot" &&
          pivotStatCfg.map((pvtStat, index) => (
            <Stack
              direction="row"
              gap="10px"
              alignItems="center"
              className="multi-stat"
            >
              <Stack gap="20px" sx={{ width: "100%" }}>
                <Autocomplete
                  key={"pvtStatCfg-statFunc" + index}
                  multiple
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={pvtStatOptions}
                  getOptionLabel={(option) => option.value}
                  filterSelectedOptions
                  disableCloseOnSelect
                  className="chips-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    const options = pvtStatOptions;
                    return isOptionEqualToValue(option, value, options);
                  }}
                  value={pivotStatCfg[index].statFuncs}
                  onChange={(e, value) => {
                    handlePivotStatCfgChange(e, value, "statFuncs", index);
                  }}
                  renderTags={renderTags}
                  groupBy={(option) => option.title}
                  renderGroup={(params) => {
                    return (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Statistical Function"
                      placeholder="Statistical Function"
                      autoComplete="off"
                      sx={chipTextFieldStyles}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip title={option.value} placement="right">
                        <Typography noWrap>{option.value}</Typography>
                      </Tooltip>
                    </li>
                  )}
                />
                <Autocomplete
                  key={"pvtStatCfg-col" + index}
                  multiple
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={groupedChipOptions(allColumns)}
                  getOptionLabel={(option) =>
                    option.item ? option.item : option
                  }
                  filterSelectedOptions
                  disableCloseOnSelect
                  className="chips-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    const options = allColumns;
                    return isOptionEqualToValue(option, value, options);
                  }}
                  value={pivotStatCfg[index].cols}
                  onChange={(e, value) => {
                    handlePivotStatCfgChange(e, value, "cols", index);
                  }}
                  renderTags={renderTags}
                  groupBy={(option) => option.title}
                  renderGroup={(params) => {
                    return (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    );
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Column to fill values"
                      placeholder="Column to fill values"
                      autoComplete="off"
                      sx={chipTextFieldStyles}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip
                        title={option.item ? option.item : option}
                        placement="right"
                      >
                        <Typography noWrap>
                          {option.item ? option.item : option}
                        </Typography>
                      </Tooltip>
                    </li>
                  )}
                />
              </Stack>
              <Tooltip title="Remove" placement="top">
                <IconButton
                  aria-label="remove"
                  className="remove-icon"
                  onClick={() => handleRemovePivotStatCfg(index)}
                >
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Tooltip>
            </Stack>
          ))}
      </Stack>
    </form>
  );
};

export default CustomForm;
