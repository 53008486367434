import { React } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import "./featureTraceToolbar.scss";

const FeatureTraceToolbar = ({ toolbarPosition, featureNodeData }) => {
  const navigate = useNavigate();

  const handleExplore = () => {
    const nodeData = featureNodeData.data;
    localStorage.setItem(
      "highlightNodeId",
      nodeData.stageId ? nodeData.stageId : nodeData.dSource
    );
    navigate(
      `/playground/${nodeData.projectKey}/${nodeData.projVersion}/${nodeData.projFg}`
    );
  };

  return (
    <div
      className="feature-trace-toolbar"
      style={{
        position: "absolute",
        top: toolbarPosition?.y,
        left: toolbarPosition?.x,
      }}
    >
      {toolbarPosition && featureNodeData && featureNodeData.data ? (
        <Stack className="button-bar" direction="row">
          <Tooltip title="Explore" placement="top">
            <button
              className="btn explore-btn"
              onClick={() => handleExplore()}
            ></button>
          </Tooltip>
        </Stack>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default FeatureTraceToolbar;
