import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import CpuUsageGraph from "graphs/cpuUsageGraph/cpuUsageGraph";
import MemoryUsageGraph from "graphs/memoryUsageGraph/memoryUsageGraph";
import DiskUsageGraph from "graphs/diskUsageGraph/diskUsageGraph";
import NetworkBandwidthGraph from "graphs/networkBandwidthGraph/networkBandwidthGraph";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { useSelector } from "react-redux";
import "./clusteredGraphs.scss";

const ClusteredGraphs = () => {
  const clusterResourceData = useSelector((state) => state.clusterResourceData);

  return (
    <>
      {clusterResourceData.order.map((worker, index) => {
        const workerData = clusterResourceData.workersInfo[worker];
        return (
          workerData && (
            <Accordion
              key={index}
              className="sidebar-graph-accordion"
              sx={{
                background: "#151c24;",
                color: "rgb(239, 241, 241) !important",
                marginTop: "10px",
              }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ChevronDown className="icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordion-header"
              >
                <Typography>{workerData.hostname}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#1e262f",
                  padding: "8px",
                }}
              >
                <Stack
                  display="flex"
                  flexDirection="row"
                  className="clustered-graph-container"
                >
                  <Stack width="50%">
                    <Box className="graph-header">Cpu Usage (%)</Box>
                    <CpuUsageGraph
                      graphHeight={150}
                      data={workerData.cpu_usage_percent}
                      clusterdData={true}
                    />
                  </Stack>
                  <Stack width="50%">
                    <Box className="graph-header">Memory Usage (%)</Box>
                    <MemoryUsageGraph
                      graphHeight={150}
                      data={workerData.ram_usage_percent}
                      clusterdData={true}
                    />
                  </Stack>
                </Stack>
                <Stack
                  display="flex"
                  flexDirection="row"
                  className="clustered-graph-container"
                >
                  <Stack width="50%">
                    <Box className="graph-header">Disk Usage (%)</Box>
                    <DiskUsageGraph
                      graphHeight={150}
                      data={workerData.disk_usage}
                    />
                  </Stack>
                  <Stack width="50%">
                    <Box className="graph-header">Net Bandwidth (MB)</Box>
                    <NetworkBandwidthGraph
                      graphHeight={150}
                      data={workerData.network_bandwidth_mb}
                    />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          )
        );
      })}
    </>
  );
};

export default ClusteredGraphs;
