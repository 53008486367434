const paginationStyles = {
  "& .MuiPaginationItem-root": {
    color: "#FFFFFF",
  },
  "& .MuiButtonBase-root": {
    cursor: "pointer",
    background: "transparent",
    border: "1px solid #72C4D3",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "6px",
    padding: "4px 8px",
    ":hover": {
      color: "#72C4D3",
    },
  },
  "& .MuiPaginationItem-ellipsis": {
    color: "#FFFFFF",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "#72C4D3",
    color: "#FFFFFF",
  },
};

const tableStyles = {
  tableContainer: {
    maxWidth: "100%",
    borderRadius: "6px",
  },
  tableHeader: {
    "& th": {
      fontSize: "13px",
      color: "#ffffff",
      padding: "7px",
      paddingY: "4px",
      height: "35px",
      backgroundColor: "#4b5362",
      textAlign: "left",
      borderBottom: "0",
      borderRight: "1px solid #272727",
      zIndex: 100,
      lineHeight: "35px",
      verticalAlign: "top",
    },
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#181A1C",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#0e0f10",
    },
  },
  tableCell: {
    color: "#ffffff",
    fontSize: "12px",
    lineHeight: "26px",
    paddingX: "7px",
    paddingY: "1px",
    textAlign: "left",
    borderBottom: "0",
    borderRight: "1px solid #272727",
    height: "40px",
    maxHeight: "40px",
  },
};

const formControlStyles = {
  "& .MuiInputLabel-root, & .MuiOutlinedInput-root": {
    color: "#D9D9D9",
    height: 30,
  },
};

const textFieldStyles = {
  width: "150px",
  "& .MuiOutlinedInput-root": {
    padding: "0 8px",
    "& .MuiInputBase-input": {
      padding: "0 !important",
      marginTop: "-2px",
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
    top: "-6px",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
    height: "37px",
    width: "180px",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  "& .Mui-disabled ": {
    "-webkit-text-fill-color": "#D9D9D9 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D9 !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
};

const autocompleteStyles = {
  width: "150px",
  "& .MuiOutlinedInput-root": {
    padding: "0 8px",
    "& .MuiInputBase-input": {
      padding: "0 !important",
    },
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
    top: "-6px",
  },
  "& .MuiAutocomplete-endAdornment": {
    right: "0 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
    minHeight: "30px",
    width: "180px",
    maxHeight: "80px",
    overflowX: "hidden",
    overflowY: "auto",
    border: "1px solid #D9D9D9",
    "&:hover": {
      border: "1px solid #69C2D2",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  svg: {
    fill: "#D9D9D9",
  },
};

const selectStyles = {
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#69C2D2",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    paddingY: "0px !important",
    maxWidth: "300px !important",
    maxHeight: "600px",
    backgroundColor: "#212B35",
    li: {
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
      maxWidth: "300px !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "1px solid white",
    "&:hover": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused": {
      borderColor: "#69C2D2",
    },
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  backgroundColor: "#212B35",
  "&.Mui-selected": {
    color: "#69C2D2",
    backgroundColor: "#323C47",
  },
  "&:hover": {
    color: "#D9D9D9",
    backgroundColor: "#323C47",
  },
};

export {
  paginationStyles,
  textFieldStyles,
  tableStyles,
  formControlStyles,
  selectStyles,
  menuItemStyles,
  autocompleteStyles,
};
