import React, { useEffect, useState } from "react";
import ELK from "elkjs";
import "reactflow/dist/style.css";
import "./featureTrace.scss";
import LogicTrace from "components/logicTrace/logicTrace";
import { ReactFlow, Controls, Background } from "reactflow";
import { useNavigate, useParams } from "react-router-dom";
import FeatureTraceNode from "components/featureTraceNode/featureTraceNode";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { ReactComponent as BackIcon } from "../../assets/icons/backIconWhite.svg";
import api from "../../apiInterceptor";
import { isEmpty } from "lodash";
import FeatureTraceToolbar from "components/featureTraceToolbar/featureTraceToolbar";
const tracefeatAPITestData = require("../../assets/apiTestData/trace-feat-test-data.json");

const FeatureTrace = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const navigate = useNavigate();
  const param = useParams();
  const alertMessage = useSelector((state) => state.alertMessage);
  const previousProjectKey = param.previousProjectKey;
  const [toolbarNode, setToolbarNode] = useState(null);
  const [toolbarPosition, setToolbarPosition] = useState({ x: 0, y: 0 });
  const [featureTraceData, setFeatureTraceData] = useState({});
  const [stepsData, setStepsData] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedStepIndex, setselectedStepIndex] = useState(-1);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const nodeTypes = {
    featureTraceNode: (props) => (
      <FeatureTraceNode {...props} selectedNodeId={selectedNodeId} />
    ),
  };
  const dispatch = useDispatch();
  const { updateAlertMessage, updateIsLoading } = bindActionCreators(
    actionCreators,
    dispatch
  );

  useEffect(() => {
    getFeatureTraceData();
  }, []);

  const getFeatureTraceData = async () => {
    const apiUrl = BASE_API_URL + "trace-feat";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const tracedFeatureInfo = JSON.parse(
      localStorage.getItem("tracedFeatureInfo") || "{}"
    );
    const payload = {
      projectKey: tracedFeatureInfo.projectKey,
      projVersion: tracedFeatureInfo.projVersion,
      projFg: tracedFeatureInfo.projFg,
      dSource: tracedFeatureInfo.dSource,
      feature: tracedFeatureInfo.feature,
    };
    if (tracedFeatureInfo.stageId) {
      payload["stageId"] = tracedFeatureInfo.stageId;
    }
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: tracefeatAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        if (!isEmpty(result)) {
          setFeatureTraceData(result.featureTrace);
          setStepsData(result.steps);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        goBack();
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
      goBack();
    }
  };

  useEffect(() => {
    calculateLayout();
  }, [featureTraceData]);

  const calculateLayout = async () => {
    const elk = new ELK();
    const { nodes: rawNodes, edges: rawEdges } = processTree(featureTraceData);
    try {
      const layout = await elk.layout({
        id: "root",
        children: rawNodes.map((n) => ({
          width: 140,
          height: 80,
          ...n,
        })),
        edges: rawEdges.map((e) => ({
          id: e.id,
          sources: [e.source],
          targets: [e.target],
        })),
        layoutOptions: {
          "elk.direction": "DOWN",
          "elk.algorithm": "layered",
          "elk.layered.spacing.nodeNodeBetweenLayers": "100",
          "elk.spacing.nodeNode": "80",
          "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
        },
      });
      const positionedNodes = layout.children.map((n) => ({
        id: n.id,
        type: "featureTraceNode",
        data: n.data,
        position: { x: n.x, y: n.y },
      }));
      setNodes(positionedNodes);
      setEdges(
        rawEdges.map((e) => ({
          ...e,
          type: "step",
          style: {
            stroke: "#4C5362",
            strokeWidth: 2,
          },
        }))
      );
    } catch (error) {
      console.error("Error during Elk layout:", error);
    }
  };

  // Convert featureTraceData into nodes and edges
  const processTree = (data, parent = null) => {
    let nodes = [];
    let edges = [];
    let idCounter = 1;
    const traverse = (nodeData, nodeName, parentNodeId) => {
      const nodeId = `${nodeName}-${idCounter++}`;
      nodes.push({
        id: nodeId,
        data: {
          label: nodeName,
          projectKey: nodeData.projectKey,
          projVersion: nodeData.projVersion,
          projFg: nodeData.projFg,
          dSource: nodeData.dSource,
          stageId: nodeData.stageId,
        },
        position: { x: 0, y: 0 },
      });
      if (parentNodeId) {
        edges.push({
          id: `e-${parentNodeId}-${nodeId}`,
          source: parentNodeId,
          target: nodeId,
        });
      }
      if (nodeData && nodeData.dependencies) {
        for (const [key, data] of Object.entries(nodeData.dependencies)) {
          traverse(data, key, nodeId);
        }
      }
    };
    if (Object.keys(data).length > 0) {
      const name = Object.keys(data)[0];
      traverse(data[name], name, parent);
    }
    return { nodes, edges };
  };

  const onNodeClick = (event, node) => {
    const toolbarX = event.clientX + 50;
    const toolbarY = event.clientY - 50;
    if (toolbarNode?.id === node.id) {
      setToolbarNode(null);
    } else {
      setToolbarNode(node);
      setToolbarPosition({ x: toolbarX, y: toolbarY });
    }
    setSelectedNodeId(node.id);
    const nodeData = node.data;
    const stepIndex = stepsData.findIndex(
      (step) =>
        step.dSource === nodeData.dSource &&
        step.projFg === nodeData.projFg &&
        step.projVersion === nodeData.projVersion &&
        step.projectKey === nodeData.projectKey &&
        step.stageId === nodeData.stageId
    );
    setselectedStepIndex(stepIndex);
  };

  const goBack = () => {
    navigate(`/feature-tracing-table/${previousProjectKey}`);
  };

  return (
    <div
      style={{
        height: alertMessage ? "calc(100% - 115px)" : "calc(100% - 65px)",
      }}
      className="feature-trace-container"
    >
      <div className="feature-trace">
        <div className="feature-trace-header">
          <div className="back-btn" onClick={() => goBack()}>
            <IconButton aria-label="back-button">
              <BackIcon />
            </IconButton>
          </div>
          <div className="header-text">Feature Trace</div>
        </div>
        <div
          style={{
            height: alertMessage
              ? "calc(100vh - 155px)"
              : "calc(100vh - 105px)",
            width: "100%",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            minZoom={0.1}
            fitView={true}
            onNodeClick={onNodeClick}
          >
            <Controls />
            <Background />
          </ReactFlow>
        </div>
        {toolbarNode && (
          <FeatureTraceToolbar
            toolbarPosition={toolbarPosition}
            featureNodeData={toolbarNode}
          />
        )}
      </div>
      <div className="gap"></div>
      <div className="logic-trace-container">
        <div className="logic-trace-header">Logic trace</div>
        <LogicTrace selectedStepIndex={selectedStepIndex} steps={stepsData} />
      </div>
    </div>
  );
};

export default FeatureTrace;
